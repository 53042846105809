export const transactionsClientAreaRoutes = [
  {
    path: "transactions_client_areas",
    meta: {
      title: "transactions_client_areas",
      permission: "addresses_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.areas",
          link: true,
          exact: true,
          to: "/areas"
        },
        {
          text: "dashboard.sidebar.transactions_client_areas",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/areas/transactions_client_areas/record")
  }
];
