export default {
  cards: {
    exceptionalPedigreeRecord: "سجل النسب الاستثنائية",
    jackReceivablesRatio: "نسبة مستحقات رصيد جاك",
    fixedRatioSettings: "رسوم جاك الثابتة",
    settings: "إعدادات",
    registerMarketersSettings: "سجل اعدادات المسوقين",
    serviceFeeSettings: "اعدادات رسوم خدمات",
    setTheValueAddedPercentage: "اعدادت نسبة القيمة المضافة ",
    paymentServicesPercentageSettings: "اعدادت نسبة خدمات الدفع ",
    paymentServicesRecord: "سجل خدمات الدفع",
    intermediaryBankReceivablesSettings: "اعدادات مستحقات البنك الوسيط",
    Receivables_transfer_settings_for_the_intermediary_bank:
      "اعدادات مستحقات تحويل للبنك الوسيط",
    data_recording: "تسجيل البيانات",
    deductions: "المستقطعات",
    Jack_balance_dues: "مستحقات رصيد جاك",
    Jack_balance_fee: "رسوم رصيد جاك",
    added_tax: "الضريبة المضافة",
    Payment_services_dues: "مستحقات خدمات الدفع",
    Intermediary_bank_receivables_record: "سجل مستحقات البنك الوسيط",
  },
};
