export default {
  departments: {
    heading: {
      departments: "سجل الأقسام",
      add_department: "إضافة قسم",
      view_department: "عرض قسم",
      edit_department: "تعديل قسم",
      historical_movment: "الحركة التاريخية"
    },
    fields: {
      department_name: "اسم القسم",
      description: "الوصف"
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
      max: "{field}   لا يجب ان يزيد عن {max}",
      min: "{field}   يجيب ان يكون علي الاقل {min}  حروف"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete_department: "هل تريد حذف قسم",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        undelete_job_1: "لا يمكنك حذف قسم ",
        undelete_job_2: "لوجود موظفين به",
        deactivate_department_with_jobs: "لا يمكن تعطيل قسم له موظفين",
        cannot_delete_department_with_employees: "لا يمكن حذف قسم له موظفين",
        cannot_delete_department_with_jobs: "لا يمكن حذف قسم له وظائف"
      }
    },
    table: {
      "#": "#",
      status: "الحالة",
      Date_created: "تاريخ الإنشاء",
      main_department: "القسم الرئيسيي",
      department_name: "اسم القسم",
      actions: "العمليات",
      noDepartment: "بدون قسم",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      department: "القسم",
      employee_job: "وظيفة الموظف",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة قسم",
      edit: "تعديل",
      close: "غلق"
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل"
    },
    labels: {
      status: "الحالة",
      department_name: "اسم القسم",
      description: "الوصف"
    },
    placeholder: {
      enter_department_name: "ادخل اسم القسم",
      select_status: "اختر الحالة",
      enter_description: "ادخل الوصف"
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      deactive: " معطل"
    },
    chips: {
      active: "مفعل",
      deactive: " معطل"
    }
  }
};
