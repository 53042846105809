import common from "./common";
import jobs from "./jobs";
import employees from "./employees";
import departments from "./departments";

export default {
  ...common,
  ...jobs,
  ...employees,
  ...departments,
};
