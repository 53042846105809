export default {
  suspectedAccountsRequest: {
    heading: {
      dangerTransactions: "معاملات تمثل خطورة (إضافة/ اعتماد تقرير)",
      suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
      view_suspectedAccountsRequest: "عرض",
      add_suspectedAccountsRequest: "إضافة تقرير"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },

    table: {
      "#": "#",
      vendor_name: "اسم العميل",
      facility_name: "اسم المنشأة",
      commercial_number: "السجل التجاري/ الوثيقة",
      phone: "رقم الجوال",
      identity_number: "رقم الهوية",
      date: "التاريخ",
      status: "الحالة",
      transaction_number: "رقم المعاملة",
      actual_value: "القيمة الفعلية",
      actions: "العمليات",
      Suspicion_type: "نوع الاشتباه",
      facility_profession_name: "اسم المنشأة/المسمي المهني",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    tooltip: {
      view_details: "عرض",
      add_report: "إضافة تقرير"
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق"
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_number: "السجل التجاري / رقم الوثيقة",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      status: "الحالة",
      tax_number: "الرقم الضريبي",
      phone: "رقم الجوال",
      danger_degree: "درجة الخطورة",
      responsible_opinion: "رأي المسئول",
      daily_expected_transactions_value: "قيمة المبيعات المتوقعة للعميل",
      identity_number: "رقم الهوية",
      facility_name: "اسم المنشأة",
      date: "التاريخ",
      reason: "سبب الإشتباه",
      Suspicion_type: "نوع الاشتباه",
      professional_name: "المسمي المهني",
      request_code: "كود الطلب",
      request_number: "رقم الطلب"
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_number: "ادخل السجل التجاري او رقم الوثيقة",
      choose_date_from: "اختر الفترة من",
      choose_date_to: "اختر الفترة إلي",
      choose_status: "اختر الحالة",
      enter_tax_number: "ادخل الرقم الضريبي",
      enter_phone: "ادخل رقم جوال العميل (5xxxxxxxx)",
      select_danger_degree: "اختر درجة الخطورة",
      enter_identity_number: "أدخل رقم الهوية",
      enter_facility_name: "ادخل اسم المنشأة",
      enter_Suspicion_type: "ادخل نوع الاشتباه"
    },
    statusOptions: {
      all: "الجميع",
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة",
      blacklist: "قائمة سوداء",
      excess_limit: "مبيعات العميل تعدت الحد الأقصي",
      both: "الاثنين معا"
    },
    chips: {
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة"
    }
  }
};
