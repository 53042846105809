export default {
  suspiciousSndicatorSettings: {
    heading: {
      suspicious_indicator_settings: "اعدادات مؤشرات الاشتباه",
      suspicious_indicator_settings_add: "إضافة مؤشر",
      suspicious_indicator_settings_edit: "تعديل مؤشر",
      suspicious_indicator_settings_view: "عرض مؤشر"
    },
    labels: {
      pointer_type: "نوع المؤشر",
      activity: "النشاط",
      customer_rating: "تصنيف العميل",
      number_repetitions_allowed: "عدد التكرار المسموح به",
      During: "خلال",
      Zero_coefficients: "المعاملات الصفرية",
      Transactions_same_value: "معاملات لها نفس القيمة",
      Fixed_value: "قيمة ثابته",
      Card: "بطاقة",
      amount: "قيمه",
      card_number: "رقم البطاقة",
      days: "يوم"
    },
    placeholder: {
      enter_pointer_type: "اختر نوع المؤشر",
      enter_activity: "اختر النشاط",
      enter_customer_rating: "اختر تصنيف العميل",
      enter_number_repetitions_allowed: "عدد التكرار المسموح به",
      enter_During: "خلال",
      enter_Card: " ادخل رقم البطاقه",
      enter_amount: "أدخل القيمة",
      choose_classification: "اختر التصنيف",
      choose_activity: "اختر النشاط"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        delete_indicator: "هل متأكد من حف مؤشر "
      }
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة",
      edit: "تعديل",
      close: "غلق",
      add_indicator: "إضافة مؤشر"
    },
    table: {
      "#": "#",
      pointer_type: "نوع المؤشر",
      activity: "النشاط",
      customer_rating: "تصنيف العميل",
      fixed_value: "القيمة الثابتة",
      number_repetitions_allowed: "عدد التكرار المسموح به",
      Matching_time: "المدة الزمنية للمطابقة",
      actions: "العمليات",
      total: "الإجمالي",
      Date: "التاريخ",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    chips: {
      high_risk: "مرتفع الخطورة",
      Medium_risk: "متوسط الخطورة",
      low_risk: "ضعيف الخطورة"
    },
    fields: {
      category: "هذا التصنيف تم اضافته مسبقا"
    },
    validation: {
      required: "{field} مطلوب"
    },
    types: {
      zero_transactions: "المعاملات الصفرية",
      same_amount_transactions: "معاملات لها نفس القيمة",
      fixed_amount: "قيمة ثابته",
      card: "بطاقة"
    },
    classification: {
      one_branch: "فرع واحد",
      more_than_branch: "أكثر من فرع",
      all: "كل"
    }
  }
};
