var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',_vm._g(_vm._b({ref:"fileRef",attrs:{"name":_vm.name,"rules":(_vm.rules && typeof _vm.imageUrl !== 'string') || (_vm.rules && _vm.imageUrl == '')
      ? _vm.rules
      : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{ref:"fileInput",attrs:{"type":"file","hidden":""},on:{"change":function($event){return _vm.onFileChange($event, 'target')}}}),_c('div',{class:{
      'drag-area': _vm.drag ? 'dragenter' : 'dragleave',
      'validation-error':
        errors.length > 0 || (_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName]),
    },on:{"dragenter":_vm.onDragEnter,"dragleave":_vm.onDragLeave,"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDragLeave.apply(null, arguments)}}},[_c('div',{on:{"drop":function($event){return _vm.onFileChange($event, 'dataTransfer')}}},[_c('v-btn',{staticClass:"upload-btn",attrs:{"block":"","height":"40px","type":"button","icon":"","ripple":false},on:{"click":function($event){return _vm.openFile()}}},[[_c('div',{staticClass:"d-flex align-center image-add",class:{ active: _vm.isActive }},[_c('div',{staticClass:"pa-5"},[_c('v-icon',{staticClass:"ml-3",attrs:{"left":"","color":"#45A9D5","large":""}},[_vm._v(" mdi-cloud-upload ")]),_c('span',{staticClass:"file-name"},[_vm._v(" "+_vm._s(_vm.uploadText ? _vm.uploadText : _vm.$t(`dashboard.${_vm.$attrs.label}`))+" ")])],1)])]],2),(_vm.image || _vm.imageUrl)?_c('div',{staticClass:"image-show d-flex align-center"},[_c('v-btn',{staticClass:"error close-btn",attrs:{"icon":"","color":"white","x-small":""},on:{"click":function($event){return _vm.resetImg()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(
            !_vm.uploadText.includes('.pdf') ||
            (typeof _vm.imageUrl == 'string' && !_vm.imageUrl.includes('.pdf'))
          )?_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","color":"info","small":""},on:{"click":function($event){return _vm.showImg()}}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e()],1):_vm._e()],1)]),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[_c('v-row',{staticClass:"my-0",staticStyle:{"position":"absolute"}},[(errors.length > 0)?_c('v-col',{staticClass:"error--text upload_file_error",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(`${errors[0]}`))+" ")]):_vm._e(),(_vm.serverErrors)?_c('v-col',{staticClass:"error--text upload_file_error",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName])+" ")]):_vm._e()],1)],1),(_vm.image || _vm.imageUrl)?_c('PreviewImage',{attrs:{"dialog":_vm.ShowDialog,"imgFile":_vm.image || _vm.imageUrl},on:{"update:dialog":function($event){_vm.ShowDialog=$event}}}):_vm._e()]}}])},'ValidationProvider',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }