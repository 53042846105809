export const followUpRoutes = [
  {
    path: "/follow-up",
    to: "/follow-up",
    icon: "mdi-chart-line",
    meta: {
      title: "follow_up",
      permission: "follow_up_department"
    },
    component: () => import("@/views/followUp"),
    allow_children: false,
    children: [
      {
        path: "/",
        meta: {
          title: "follow_up",
          permission: "follow_up_department",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.follow_up"
            }
          ]
        },
        component: () => import("@/views/followUp/record")
      }
    ]
  }
];
