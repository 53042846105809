export const failedTransactionsRoutes = [
  {
    path: "failed-transactions",
    to: "/transactions/failed-transactions",
    meta: {
      title: "failedTransactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.failedTransactions.heading.failedTransactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/transactions/failedTransactions/record"),
    allow_children: false
  },
  {
    path: "failed-transactions/:id/view",
    meta: {
      title: "failed_transactions_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.failedTransactions.heading.failedTransactions",
          to: "/transactions/failed-transactions",
          link: true,
          exact: true
        },
        {
          text: "dashboard.failedTransactions.heading.failed_transactions_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/transactions/failedTransactions/view"),
    allow_children: false
  }
];
