export default {
  PaymentServicesDues: {
    heading: {
      Payment_services_dues: "مستحقات خدمات الدفع",
    },
    table: {
      "#": "#",
      client_name: "اسم العميل",
      Commercial_register: "السجل التجارى",
      taxNumber: "الرقم الضريبى",
      transaction_number: "رقم المعاملة",
      value_added_percentage: "نسبة القيمة المضافة",
      transaction_value: "قيمة المعاملة",
      transaction: "المعاملة",
      value_added: "القيمة المضافة",
      Service_fee_rate: "نسبة رسوم الخدمة",
      Service_fee: "رسوم الخدمة",
      service_type:"نوع الخدمة",
      Tax: "الضريبة",
      Date: "التاريخ",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      add_rate: "إضافه نسبه",
    },
    labels: {
      client_name: "اسم العميل",
      Commercial_Register: "السجل التجارى",
      transaction_number: "رقم المعاملة",
      taxNumber: "الرقم الضريبى",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      rate: "النسبه",
      service_type:"نوع الخدمة",
    },
    placeholder: {
      enter_CommercialRegister: "ادخل السجل التجارى",
      date_from: " ادخل الفترة من",
      date_to: "ادخل الفترة الى",
      enter_Commercial_Register: "ادخل السجل التجارى",
      taxNumber: "ادخل الرقم الضريبى",
      enter_client_name: "ادخل اسم العميل",
      Commercial_Register: "السجل التجارى",
      enter_transaction_number: "ادخل رقم المعاملة",
      enter_service_type:"اختر نوع الخدمة",
      enter_service_types:"ادخل نوع الخدمة",
    },
  },
};
