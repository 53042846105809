export default {
  severitySettings: {
    heading: {
      severity_settings: "إعدادات درجات الخطورة",
      severity_settings_add: "إضافة",
      severity_settings_edit: "تعديل",
      severity_settings_view: "عرض",
      historical_movment: "الحركة التاريخية",
    },
    labels: {
      degree_severity: "درجة الخطورة",
      Severity_Priority: "أولوية درجة الخطورة",
      risk_degree_color: "لون درجة الخطورة",
      risk_percent_from: "مؤشر الخطورة من",
      risk_percent_to: "مؤشر الخطورة الي",
      color: "لون درجة الخطورة",
      is_risk_vendor: "اضافة درجة لعملاء المخاطر",
      show_risk_vendor: "تظهر فى عملاء المخاطر",
    },
    placeholder: {
      enter_degree_severity: "ادخل درجة الخطورة",
      enter_Severity_Priority: "ادخل أولوية درجة الخطورة",
      enter_risk_percent_from: "ادخل مؤشر الخطورة من",
      enter_risk_percent_to: "ادخل مؤشر الخطورة الي",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        delete_category:
          "قد يؤدي حذف هذا التصنيف إلي إلغاء التصنيفات التي تم إضافتها من قبلكم",
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        degree_has_vendors:
          "لا يمكن حذف درجة لها عملاء تم تصنيفهم سابقا علي هذه الدرجة",
      },
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة",
      edit: "تعديل",
      close: "غلق",
      add_severity: "إضافة درجة خطورة",
    },
    tooltip: {
      delete: "حذف",
      edit: "تعديل",
      view_details: "عرض",
    },
    table: {
      "#": "#",
      degree_severity: "درجة الخطورة",
      Severity_Priority: "أولوية درجة الخطورة",
      risk_percent_from: "مؤشر الخطورة من",
      risk_percent_to: "مؤشر الخطورة الي",
      actions: "العمليات",
      total: "الإجمالي",
      Date: "التاريخ",
      added_by: "تم بواسطة",
      department: "اسم القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    chips: {
      high_risk: "مرتفع الخطورة",
      Medium_risk: "متوسط الخطورة",
      low_risk: "ضعيف الخطورة",
      no_danger: "لا يوجد خطورة",
    },
    fields: {
      Severity: "هذا التصنيف تم اضافته مسبقا",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
  },
};
