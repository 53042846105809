<template>
  <v-row>
    <v-col cols="12">
      <v-label>
        <span class="font-bold form-label mb-4 primary--text">
          {{ $t(`dashboard.${label}`) }}</span
        >
      </v-label>
      <v-autocomplete
        v-if="is_search"
        v-on="$listeners"
        eager
        persistent-placeholder
        hide-details
        :items="items"
        outlined
        v-bind="$attrs"
        v-model="form[filterName]"
        @change="handleFilter"
        @click:clear="handleRemoveFilter"
        prepend-inner-icon="mdi-magnify"
        :no-data-text="$t('dashboard.public.no_data')"
      >
      </v-autocomplete>
      <v-select
        v-else
        v-on="$listeners"
        eager
        hide-details
        :items="items"
        :menu-props="{ bottom: true, offsetY: true }"
        outlined
        v-bind="$attrs"
        v-model="form[filterName]"
        @change="handleFilter"
        @click:clear="handleRemoveFilter"
        :no-data-text="$t('dashboard.public.no_data')"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import GenericComponent from "../GenericComponent";

export default {
  name: "SelectFilter",
  inheritAttrs: false,
  extends: GenericComponent,
  props: {
    items: {
      type: Array,
      default: () => []
    },
    is_search: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  }
};
</script>
