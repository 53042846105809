export default {
  serviceFeeSettings: {
    heading: {
      add: "اضافة",
      edit: "تعديل",
      historical_movment: "الحركة التاريخية",
    },
    fields: {
      servCommercial: "خدمة اشتراك انشاء حساب لعميل سجل تجاري",
      servDocument: "خدمة اشتراك انشاء حساب لعميل وثيقة",
      servEdit: "خدمة تعديل بيانات لعميل سجل تجاري",
      servReactivationCommercial: "خدمة إعادة تنشيط حساب لعميل سجل تجاري",
      servReactivationDocument: "خدمة إعادة تنشيط حساب لعميل وثيقة",
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        unarchive_job: "لا يمكنك تعطيل قسم ' اسم القسم ' لوجود موظفين به",
      },
    },
    table: {
      "#": "#",
      added_by: "تم بواسطة",
      category: "القسم",
      created_at: "تاريخ النشاط",
      desc: "تفاصيل النشاط",
    },
    buttons: {
      export: "تصدير",
      add: "إضافة",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    labels: {
      servCommercial: "خدمة اشتراك انشاء حساب لعميل سجل تجاري",
      servDocument: "خدمة اشتراك انشاء حساب لعميل وثيقة",
      servEdit: "خدمة تعديل بيانات لعميل سجل تجاري",
      servReactivationCommercial: "خدمة إعادة تنشيط حساب لعميل سجل تجاري",
      servReactivationDocument: "خدمة إعادة تنشيط حساب لعميل وثيقة",
    },
    placeholder: {
      servCommercial: "ادخل خدمة اشتراك انشاء حساب لعميل سجل تجاري",
      servDocument: "ادخل خدمة اشتراك انشاء حساب لعميل وثيقة",
      servEdit: "ادخل خدمة تعديل بيانات لعميل سجل تجاري",
      servReactivationCommercial: "ادخل خدمة إعادة تنشيط حساب لعميل سجل تجاري",
      servReactivationDocument: "ادخل خدمة إعادة تنشيط حساب لعميل وثيقة",
    },
    statusOptions: {},
    chips: {
      active: "مفعل",
      deactive: " معطل",
    },
    msgDialog: {
      makesure_activation:
        "هل متأكد من تفعيل الحساب الذي قام بعمل محاولة مشبوه ؟ ",
      delet_sure: "هل تريد حذف صلاحية ' تعديل موظف ' ؟",
    },
  },
};
  