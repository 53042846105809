export default {
  vendors: {
    heading: {
      account_request: "طلبات فتح حساب",
      vendors_record: "سجل بيانات العملاء",
      employees_record: "سجل الموظفين",
      view_employees_record: "عرض سجل الموظف",
      protection_systems: "أنظمة الحماية",
      edit_commercial_record: "تعديل",
      view_commercial_record: "عرض",
      viewRevisionRequest: "عرض",
      changeCustomerData: "طلبات تغير بيانات عميل",
      vendorData: "بيانات العملاء",
      view: "عرض",
      branches: "سجل الفروع",
      editDelegation: "تعديل نموذج تفويض",
      viewDelegation: "عرض نموذج تفويض",
      delegation_num: "رقم التفويض",
      followOrderStatus: "متابعة حالة الطلب",
      pendingRequest: "طلبات في انتظار التفويض",
      recordingRequest: "طلبات مسجلة",
      historical_movement: "الحركة التاريخية",
      vendors_settings: "إعدادات العملاء",
    },
    cards: {
      register_vendor: "تسجيل عميل جديد",
      followUp_request: "متابعة حالة الطلب",
      document_types: "سجل مهن الوثيقه",
      document_types_card: "مهن الوثيقة",
      employees_record: "سجل الموظفين",
      employees: "الموظفين",
      vip_vendor: "إعدادات تصنيف كبار العملاء",
      authorizations: "التفويضات",
      revision_request: "طلبات قيد المراجعة",
      refused_request: "الطلبات المرفوضة",
      refused_canceled_request: "طلبات مرفوضة / ملغاه للتكرار",
      approved_refused_requests: "طلبات معتمدة/ مرفوضة",
      pending_request: "طلبات في انتظار التفويض",
      recording_request: "تفويضات مسجلة",
      electronic_request: "تفويضات إلكترونية",
      cancelled_request: "طلبات ملغاه",
      commercial_record: "سجل تجاري",
      document: "وثيقة",
      approved_devices: "أجهزة العملاء المعتمدة",
      suspicious_devices: "أجهزة العملاء المشتبه بها",
      blackList: "القائمة السوداء",
      vendors: "العملاء",
      listSuspects: "قوائم المشتبه بهم",
      vendors_record: "سجل العملاء",
      legal_accreditation: "الاعتماد القانونيه",
      legal_accreditation_record: "سجل الاعتماد القانونيه",
      money_laundering_approval: "اعتماد إدارة المخاطر",
      moneyLaunderingApproval_record: "سجل اعتماد إدارة المخاطر",
      System_administrator_approval: "اعتماد مدير النظام",
      System_administrator_approval_record: "سجل اعتماد مدير النظام",
      Approved_requests: "طلبات معتمده",
      Delegated_requests: "طلبات مرفوضه",
      branches: "الفروع",
      account_registration_request: "طلبات إنشاء حساب وثيقة قيد مراجعة",
      account_cancelled_request: "طلبات إنشاء حساب وثيقة لطلبات ملغاه لتكرارها",
      delegation_cancelled_request: "طلبات ملغاه لتكرارها",
    },
    tooltip: {
      refuse: "رفض",
      delete: "حذف",
      view_details: "عرض",
      confirm: "اعتماد",
      view_auth: "عرض تفويض",
      refuse_auth: "إلغاء تفويض",
      edit: "تعديل",
      add_cat: "إضافة تصنيف",
      confirm_account: "تأكيد الحساب",
      suspens_account: "تعليق",
      iban_confirm: "تأكيد الأيبان",
      branch_approval: "اعتماد الفرع",
      activated_phone: "تفعيل الجوال",
      reReview: "طلب إعادة المراجعة",
    },
    buttons: {
      cancel: "إلغاء",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      search: "بحث",
      reset: "عرض الكل",
      edit: "تعديل",
      Approval: "اعتماد",
      verification: "تحقق",
      next: "التالى",
      qr_code: "qr code",
      share: "مشاركة",
      print: "طباعة",
      refused: "رفض",
    },
    placeholder: {
      refusalReaon: "ادخل سبب الرفض",
      choose_account_type: "اختر نوع الحساب",
      enter_professional_record: "أدخل السجل المهنى",
      enter_number: "أدخل رقم الطلب",
      select_delegation_type: "اختر نوع التفويض",
      select_job: "اختر الوظيفة",
      select_vendor_name: "اختر اسم العميل",
      enter_ip: "أدخل الرقم",
      add_value: "أضف القيمة",
      enter_commercial_record: "أدخل السجل التجاري",
      enter_doc_number: "أدخل رقم الوثيقة",
      enter_commercial_record_doc: "ادخل رقم السجل التجاري / الوثيقة",
      select_date: "يوم/شهر/سنة",
      auth_status: "حالة التفويض",
      enter_auth_request_num: "أدخل رقم طلب التفويض",
      enter_auth_num: "أدخل رقم التفويض",
      enter_auth_identity_to: "أدخل هوية المفوض له",
      enter_identity_num: "أدخل رقم الهوية",
      enter_job: "أدخل اسم الوظيفة",
      enter_request_num: "أدخل رقم الطلب",
      enter_facility_name: "أدخل اسم المنشأة",
      facility_job_name: "ادخل المنشأة/المسمى المهنى",
      enter_bank_name: "أدخل اسم البنك",
      enter_phone: "أدخل رقم الجوال (5xxxxxxxx)",
      choose_activity: "اختر النشاط",
      enter_iban: "أدخل رقم الآيبان",
      choose_bank: "إختر اسم البنك",
      choose_vendor_type: "إختر نوع العميل",
      enter_vendor_name: "أدخل اسم العميل",
      enter_tax_number: "أدخل الرقم الضريبي",
      enter_degree_danger: "ادخل درجة الخطورة",
      enter_refusalDecision: "ادخل قرار الرفض",
      enter_employee_name: "أدخل اسم الموظف",
      choose_employee_status: "اختر درجة خطورة الموظف",
      choose_employee_type: "اختر نوع العميل",
      enter_commercial_number: "أدخل السجل التجاري",
      enter_identity_number: "أدخل رقم الهوية",
      enter_email: "أدخل البريد الإلكتروني",
      choose_nationalities: "اختر الجنسية",
      choose_doc_type: "اختر نوع الوثيقة",
      choose_job_name: "اختر نوع المهنة",
      enter_job_number: "أدخل الرقم المهني",
      choose_bank_name: "اختر اسم البنك",
      choose_activity_type: "اختر نوع االنشاط",
      enter_auth_name: "ادخل اسم المفوض",
      enter_auth_name_to: "ادخل اسم المفوض له",
      enter_auth_content: "أدخل مضمون التفويض",
      enter_max_expected_value: "أدخل الحد الأقصي",
      enter_company_capital: "أدخل رأس مال الشركة",
      enter_distinct_values: "أدخل النسبة",
      choose_region: "اختر المنطقة",
      choose_city: "اختر المدينة",
      choose_district: "اختر الحي",
      enter_street: "أدخل الشارع",
      enter_doc_number_order: "أدخل رقم طلب الوثيقة",
      enter_delegatin_doc: "أدخل رقم وثيقة التفويض",
      enter_auth_identity: "أدخل هوية المفوض",
      enter_commercial_recordNum: "أدخل رقم السجل التجاري",
      enter_auth_phone_to: "أدخل جوال المفوض له",
      enter_code: "ادخل الكود",
      enter_accreditation_date_from: "تاريخ الاعتماد من",
      enter_accreditation_date_to: "تاريخ الاعتمادالى",

      enter_accreditation_authorization_date_from: "تاريخ الرفض / الاعتماد من",
      enter_accreditation_authorization_date_to: "تاريخ الرفض / الاعتماد الي",
      enter_rejection_stage: "اختر مرحلة الرفض",
      select_status: "اختر الحالة",
    },

    labels: {
      commercial_record_end_date: "تاريخ انتهاء السجل",
      rasid_jack_fixed_rate: "نسبة رصيد جاك الثابتة",
      facility_nationality_commerial: "جنسية الشركة / الموئسسة",
      facility_nationality_document: "جنسية الوثيقة",
      date_of_birth: "تاريخ الميلاد العميل",
      creation_date_for_company: "تاريخ إنشاء الشركة / المؤسسة",
      creation_date_for_document: "تاريخ إصدار الوثيقة",
      delegate_to_phone: "رقم جوال المفوض له",
      reject_reason: "سبب الرفض",
      reject_state: "مرحلة الرفض",
      refused_date: "تاريخ رفض الطلب",
      approved_date: "تاريخ قبول الطلب",
      bank_account_pdf: "الحساب  البنكي ( PDF )",
      old_bank_account_pdf: "الحساب  البنكي القديم (PDF)",
      new_bank_account_pdf: "الحساب  البنكي الجديد (PDF)",
      delegator_birthdate: "تاريخ ميلاد المفوض",
      delegator_name: "اسم المفوض",
      professional_record: "السجل المهنى",
      new_phone: "رقم الجوال الجديد",
      old_phone: "رقم الجوال القديم",
      new_email: "البريد الالكتروني الجديد",
      old_email: "البريد الالكتروني القديم",
      new_facility_name: "اسم المنشأة الجديد",
      old_facility_name: "اسم المنشأة القديم",
      delegator_to_birth_date: "تاريخ ميلاد المفوض له",
      delegator_to_identity: "هوية المفوض له",
      delegator_to_name: "اسم المفوض له",
      delegator_to_phone: "رقم جوال المفوض له",
      delegator_phone: "رقم جوال المفوض",
      delegator_identity_number: "هوية المفوض",
      delegate_to_identity_number: "رقم هوية المفوض له",
      delegation_doc_num: "رقم وثيقة التفويض",
      facility_membership_number: "رقم عضوية المنشأة",
      delegation_num: "رقم التفويض",
      branch: "الفرع",
      ip: "الجهاز (IP)",
      you_dont_have_permission: "ليس لديك صلاحية لفتح حساب مدفوعات",
      enter_confirm_code: "ادخل كود التحقق المرسل علي الجوال المنتهي برقم",
      daily: "يوميا",
      monthly: "شهريا",
      yearly: "سنويا",
      city: "المدينة",
      region: "المنطقة",
      district: "الحي",
      code: "الكود",
      street: "الشارع",
      distinct_values: "نسبة رصيد جاك الاستثنائية",
      jack_values: "نسبة رصيد جاك",
      company_capital: "رأس مال الشركة",
      sar: "ر.س",
      max_expected_value: "الحد الأقصي المتوقع لقيمة المعاملة الواحدة",
      emp_created_at: "تاريخ انشاء الموظف",
      expected_sales_value: "قيمة المبيعات المتوقعة ",
      choose_nationalities: "اختر الجنسية",
      last_3months: "مبيعات 3 أشهر",
      last_6months: "مبيعات 6 أشهر",
      commercial_record: "السجل التجاري",
      job: "الوظيفة",
      doc_number: "رقم الوثيقة",
      start_date_from: "تاريخ الإنشاء من",
      start_date_to: "تاريخ الإنشاء إلي",
      end_date_from: "تاريخ الإنتهاء من",
      end_date_to: "تاريخ الإنتهاء الي",
      auth_status: "حالة التفويض",
      auth_request_num: "رقم طلب التفويض",
      auth_num: "رقم الطلب",
      auth_identity_to: "هوية المفوض له",
      commercial_record_doc: "السجل التجاري /رقم الوثيقة",
      identity_num: "رقم الهوية",
      period_to: "فترة  زمنية إلي",
      period_from: "فترة زمنية من",
      permissions: "الصلاحيات",
      request_num: "رقم الطلب",
      request_date: "تاريخ الطلب",
      the_date: " التاريخ",
      status: "الحالة",
      facility_name: "اسم المنشأة",
      facility_job_name: "المنشاة/المسمى المهنى",
      bank_name: "اسم البنك",
      old_bank: "اسم البنك القديم",
      new_bank: "اسم البنك الجديد",
      bankAccount_status: "حالة الحساب البنكي",
      account_status: "حالة الحساب",
      activity: "النشاط",
      acceptable: "مقبول",
      refused: "مرفوض",
      showDoc: "عرض صورة المستند الدال علي صحة الحساب",
      actual_transactions: "المعاملات الفعلية",
      identity: "الهوية",
      vendor_name: "اسم العميل",
      employee_name: "اسم الموظف",
      employee_status: "درجة خطورة الموظف",
      phone: "الجوال",
      iban: "الآيبان",
      old_iban: "الآيبان القديم",
      new_iban: "الآيبان الجديد",
      vendor_type: "نوع العميل",
      expected_transactions_value: "قيمة المعاملات المتوقعة",
      maak_profit_percentage: "نسبة أرباح رصيد معاك",
      pay_discount_percentage: "نسبة خصم رصيد باي",
      doc_type: "نوع الوثيقة",
      tax_number: "الرقم الضريبي",
      vendor_phone: "رقم جوال العميل",
      register_date: "تاريخ التسجيل",
      auth_end: "إنتهاء التفويض",
      request_code: "كود الطلب",
      manager_name: "اسم المدير المعتمد",
      device: "تعريف الجهاز",
      manager_identity: "هوية المدير المعتمد ",
      requests_num: "عدد الطلبات",
      register_new_account: "إنشاء حساب جديد",
      delegation: "تفويضات",
      complete_data: "إستكمال بيانات",
      confirm_account: "تحقق",
      employee_type: "نوع العميل",
      commercial_number: "السجل التجاري",
      // commercial_end_date: "تاريخ انتهاء التفويض ",
      commercial_end_date: "تاريخ انتهاء السجل ",
      doc_end_date: "تاريخ انتهاء الوثيقة ",
      delegation_end_date: "تاريخ انتهاء التفويض ",
      client_logo: "شعار العميل",
      client_name: "اسم العميل",
      identity_number: "رقم الهوية",
      email: "البريد الإلكتروني",
      nationality: "الجنسية",
      job_name: "مسمي المهنة",
      job_number: "الرقم المهني",
      document_end_date: "تاريخ انتهاء الوثيقة",
      bank_account: "الحساب البنكي المعتمد",
      drag_image: "ارفاق ملف",
      activity_type: "نوع النشاط",
      auth_name: "اسم المفوض",
      auth_phone: "رقم جوال المفوض",
      auth_name_to: " اسم المفوض له",
      auth_content: "مضمون التفويض",
      auth_identity: "هوية المفوض",
      auth_phone_to: "جوال المفوض له",
      doc_number_order: "رقم طلب الوثيقة",
      delegatin_doc_num: "رقم وثيقة التفويض",
      commercial_record_num: "رقم السجل التجاري",
      degree_danger: "درجة خطورة العميل",
      doc_num: "رقم الوثيقة",
      creating_date: "تاريخ الإنشاء",
      end_date: "تاريخ انتهاء التفويض",
      document_type: "نوع الوثيقة",
      job_title: "مسمي المهني",
      document_number: "رقم الوثيقة",
      register_end_date: "تاريخ انتهاء التسجيل",
      vendor_logo: "شعار العميل",
      model_num: "رقم النموذج",
      model_code: "كود النموذج",
      qr_code: "QR code",
      Required_name_facility: "اسم المنشأه المطلوب",
      responsible_name: "اسم المسئول المعتمد",
      responsible_identity: "هوية المسئول المعتمد",
      new_responsible_name: "اسم المسئول الجديد",
      new_responsible_identity: "هوية المسئول الجديد",
      new_responsible_official: "جوال المسئول الجديد",
      refusalDecision: "قرار الرفض",
      refusalReaon: "سبب الرفض",
      accreditation_date: "تاريخ الاعتماد",
      accreditation_date_from: "تاريخ الاعتماد من",
      accreditation_date_to: "تاريخ الاعتمادالى",
      accreditation_authorization_date_from: "تاريخ الرفض / الاعتماد من",
      accreditation_authorization_date_to: "تاريخ الرفض / الاعتماد الي",
      image_commercial: "صورة السجل التجارى",
      Reason_requesting_review: "سبب طلب إعادة المراجعة",
      customer_severity: "درجة خطورة العميل",
      rejection_stage: "مرحلة الرفض",
      rejection_date: "تاريخ الرفض",
      expected_daily_sales: "قيمة المبيعات اليومية",
      expected_monthly_sales: "قيمة المبيعات الشهرية",
      expected_yearly_sales: "قيمة المبيعات السنوية",
      delegation_type: "نوع التفويض",
      account_type: "نوع الحساب",
    },
    options: {
      commercial_record: "سجل تجاري",
      profession_practice_document: "وثيقة",
    },
    documentTypes: {
      profession_practice_document: "وثيقة ممارسة مهنة",
      free_work_document: "وثيقة ممارسة عمل حر",
      responsible_name: "اسم المسئول المعتمد",
      responsible_identity: "هوية المسئول المعتمد",
      new_responsible_name: "اسم المسئول الجديد",
      new_responsible_identity: "هوية المسئول الجديد",
      new_responsible_official: "جوال المسئول الجديد",
      refusalDecision: "قرار الرفض",
      refusalReaon: "سبب الرفض",
      nationality: "الجنسية",
      email: "البريد الكترونى",
      image_commercial: "صورة السجل التجارى",
      degree_danger: "درجة خطورة العميل",
      Required_name_facility: "اسم المنشأه المطلوب",
      register_new_account: "إنشاء حساب جديد",
      delegation: "تفويضات",
      complete_data: "إستكمال بيانات",
      confirm_account: "تحقق",
      employee_type: "نوع العميل",
      commercial_number: "السجل التجاري",
      commercial_end_date: "تاريخ انتهاء السجل ",
      client_logo: "شعار العميل",
      client_name: "اسم العميل",
      identity_number: "رقم الهوية",
      auth_identity: "هوية المفوض",
      auth_phone_to: "جوال المفوض له",
    },
    chips: {
      valid: "ساري",
      cancel: "ملغي",
      processed: "تمت المعالجة",
      being_processed: "يتم المعالجة",
      suspended: "معلق",
      continues: "جاري",
      confirmed: "تم التأكيد",
      pendingConfirmed: "انتظار التأكيد",
      refused: "مرفوض",
      banned: "معلق حسابهم",
      has_job: "علي قوة العمل",
      no_danger: "لا يوجد خطورة",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
        save: "حفظ",
        cancel: "إلغاء",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        refuseTitle: "إضافة تقرير",
        refuseTitle2: "رفض تغير بيانات عميل",
        RequestReview: "طلب إعادة المراجعة",
        approvalTitle: "هل متأكد من اعتماد حساب العميل",
        approvalCustomerTitle: "اعتماد تغير بيانات عميل",
        addCarTitle: "إضافة تصنيف كبار العملاء",
        addCarContent: "سيتم إلغاء تصنيف المنشأه كارفور",
        addCarContent2: "السجل التجاري / رقم الوثيقة",
        addCarContent3: " من تصنيف",
        acceptApprovalContent: "هل متأكد من قبول اعتماد تغير البيانات ؟",
        suspensAccountTitle: "تعليق حساب عميل",
        branch_name: "اسم الفرع",
        address: "العنوان",
        activatePhoneContent: "هل تريد تفعيل الجهاز IP",
        activatePhoneContent2: "للعميل",
        codeTitle: "كود التحقق",
        codeBody: "ادخل كود التحقق المرسل علي الجوال المنتهي برقم",
        Codenumber: "********78",
        rejectValidation: "رفض تغير بيانات عميل",
      },
      reasonLabel: "سبب الرفض",
      reasonValidation: "السبب",
      refuseAuthLabel: "سبب الإلغاء",
      refuseAuthValidation: "سبب الإلغاء",
      revisionReasonLabel: "طلب إعادة مراجعة",
      revisionReasonValidation: "إعادة مراجعة",
      suspensAccountLabel: "السبب",
      suspensAccountValidation: "السبب",
    },
    fields: {
      employee_type: "نوع العميل",
      request_num: "رقم الطلب",
      code: "الكود",
      commercial_number: "السجل التجاري",
    },
    validation: {
      required: " هذا الحقل مطلوب",
      max: "هذا الحقل   لا يجب ان يزيد عن {max}",
      min: "هذا الحقل   يجب ان يكون علي الاقل {min} من الحروف",
      email: "ادخل البريد الإلكتروني بطريقة صحيحة",
      startWithSA: "هذا الحقل يجب ان يبدأ SA",
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة"
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      deactive: "معطل",
      acceptedFollowUp: "تم إنشاء التفويض بنجاح",
      rejectedFollowUp: "تم رفض التفويض المقدم من قبل المفوض",
      reviewing: "للمتابعة",
      underReviewing: "جاري المراجعة",
      reviewingTime: "يتم مراجعة الطلب خلال 24 إلي 72 ساعة",
      in_active: "معلق",
      waiting: "قيد الانتظار",
      commercial_record: "سجل تجاري",
      banned: "معلق حسابهم",
      has_job: "علي قوة العمل",
      ongoing: "ساري",
      expired: "منتهي",
      inactive: "ملغي",
      refused: "مرفوض",
      accepted: "مقبول",
    },
    riskRateOptions: {
      normal: "طبيعي",
      suspicion: "مشتبه به",
      risk_person: "شخصبات مخاطر",
      low_risk: "منخفض الخطوره",
      medium_risk: "متوسط الخطوره",
      high_risk: "عالي الخطوره",
    },
    table: {
      "#": "#",
      device_ip: "تعريف الجهاز ",
      vendor_name_auth_name_to: "اسم العميل/ المفوض له",
      delegation_type: "نوع التفويض",
      date: "التاريخ",
      start_date: "تاريخ البدء",
      end_date: "تاريخ الانتهاء",
      create_date: "تاريخ الإنشاء",
      vendor_name: "اسم العميل",
      employee_name: "اسم الموظف",
      employee_status: "درجة خطورة الموظف",
      commercial_record: "السجل التجاري / الوثيقة",
      auth_number: "رقم الطلب",
      auth_identity_to: "هوية المفوض له",
      auth_phone_to: "جوال المفوض له",
      auth_status: "حالة التفويض",
      actions: "العمليات",
      total: "الإجمالي",
      register_date: "تاريخ التسجيل",
      auth_end: "إنتهاء التفويض",
      request_num: "رقم الطلب",
      request_code: "كود الطلب",
      request_date: "تاريخ الطلب",
      rejection_date: "تاريخ الرفض",
      accreditation_date: "تاريخ الرفض / الاعتماد",
      authorization_date: "تاريخ التفويض",
      identity_num: "رقم الهوية",
      manager_name: "اسم المدير المعتمد",
      manager_identity: "رقم هوية المدير",
      device: "تعريف الجهاز",
      job: "الوظيفة",
      requests_num: "عدد الطلبات",
      refuse_date: "تاريخ الرفض",
      reason: "السبب",
      model_num: "رقم النموذج",
      code: "الكود",
      vendor_type: "نوع العميل",
      auth_identity: "هوية المفوض",
      auth_name_to: "اسم المفوض له",
      auth_content: "مضمون التفويض",
      qr_code: "QR code",
      auth_phone: "جوال المفوض",
      auth_birthday_to: "تاريخ ميلاد المفوض له",
      auth_end_data: "تاريخ إنتهاء التفويض",
      status: "الحالة",
      auth_name: "اسم المفوض",
      phone: "الجوال",
      code_num: "رقم الكود",
      facility_name: "اسم المنشأة",
      facility_job_name: "المنشاة/المسمى المهنى",
      facility_code: "كود المنشأة",
      Commerce_record: "السجل التجاري",
      license_expiry_date: "تاريخ انتهاء الترخيص",
      certified_phone: "الجوال المعتمد",
      certified_ID: "الهوية المعتمدة",
      bank_name: "اسم البنك",
      iban: "الآيبان",
      branches_count: "عدد الفروع",
      sellers_count: "عدد البائعين",
      bankAccount_status: "حالة الحساب البنكي",
      activity_type: "نوع النشاط",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      maak_profit_percentage: "نسبة أرباح معاك",
      pay_discount_percentage: "نسبة خصم باي",
      vendor_cat: "تصنيف العميل",
      account_status: "حالة الحساب",
      account_create_date: "تاريخ انشاء الحساب",
      doc_num: "رقم الوثيقة",
      doc_code: "كود الوثيقة",
      tax_number: "الرقم الضريبي",
      vendor_phone: "رقم جوال العميل",
      confirmed_device_ip: "IP الجهاز المعتمد",
      refused_device_ip: "IP الجهاز المرفوض",
      no_data: "لا توجد نتائج متاحة",
      responsible_name: "اسم المسئول المعتمد",
      responsible_identity: "هوية المسئول المعتمد",
      degree_danger: "درجة خطورة العميل",
      delegation_start_date: "تاريخ تقديم التفويض ",
      delegation_created_at: "تاريخ إنشاء التفويض",
      delegation_end_date: "تاريخ انتهاء التفويض ",
      doc_end_date: "تاريخ انتهاء الوثيقة ",
      refuse_reason: "سبب الرفض",
      model_code: "كود  النموذج",
      delegation_doc_num: "رقم وثيقة التفويض",
      delegation_code: "كود الطلب",
      account_type: "نوع الحساب",
      auth_doc_end_data: "تاريخ انتهاء التفويض /الوثيقة",
      added_by: "تم بواسطة",
      employee_job: "الوظيفة",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      request_type: "نوع الطلب",
      // auth_phone_to: "رقم المفوض",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
  },
};
