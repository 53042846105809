export const vendorsRecordRoutes = [
  {
    path: "vendorsRecord",
    to: "/vendors/vendorsRecord",
    meta: {
      title: "vendors_record",
      permission: "facilities_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendorsRecord.heading.vendors_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/vendorsRecord/record"),
    allow_children: false
  },
  {
    path: "vendorsRecord/:id/view",
    meta: {
      title: "view_vendorsRecord",
      permission: "facilities_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.vendorsRecord.heading.vendors_record",
          exact: true,
          link: true,
          to: "/vendors/vendorsRecord"
        },
        {
          text: "dashboard.vendorsRecord.heading.view_vendorsRecord",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/vendorsRecord/view"),
    allow_children: false
  },

  {
    path: "vendorsRecord/:id/update_bank_account_status",
    meta: {
      title: "update_bank_account_status",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.vendorsRecord.heading.vendors_record",
          exact: true,
          link: true,
          to: "/vendors/vendorsRecord"
        },
        {
          text: "dashboard.vendorsRecord.heading.update_bank_account_status",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/vendorsRecord/updateBankAccountStatus"),
    allow_children: false
  },

  {
    path: "vendorsRecord/:id/edit",
    meta: {
      title: "edit_vendor",
      permission: "facilities_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.vendorsRecord.heading.vendors_record",
          exact: true,
          link: true,
          to: "/vendors/vendorsRecord"
        },
        {
          text: "dashboard.vendorsRecord.heading.edit_vendor",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/vendorsRecord/edit"),
    allow_children: false
  }
];
