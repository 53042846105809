export const suspectsListRoutes = [
  {
    path: "suspects-list",
    to: "/vendors/blackList/suspects-list",
    meta: {
      title: "suspects_list",
      permission: "blacklists_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.suspectsList.heading.blackList",
          exact: true,
          link: true,
          to: "/vendors/blackList"
        },
        {
          text: "dashboard.suspectsList.heading.suspects_list",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/blackList/suspectsList/record"),
    allow_children: false
  },

  {
    path: "suspects-list/add",
    meta: {
      title: "add_banned_hiring",
      permission: "blacklists_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.suspectsList.heading.blackList",
          exact: true,
          link: true,
          to: "/vendors/blackList"
        },
        {
          text: "dashboard.suspectsList.heading.suspects_list",
          exact: true,
          link: true,
          to: "/vendors/blackList/suspects-list"
        },
        {
          text: "dashboard.suspectsList.heading.add",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/blackList/suspectsList/crud"),
    allow_children: false
  }
];
