export default {
  severitySettingsNationalities: {
    heading: {
      severity_settings_nationalities: "إعدادات درجات الخطورة للجنسيات",
      severity_settings_nationalities_record: "سجل درجة خطورة للجنسيات",
      severity_settings_nationalities_add: "إضافة",
      severity_settings_nationalities_edit: "تعديل",
      severity_settings_nationalities_view: "عرض",
      historical_movement: "الحركة التاريخية",
    },
    labels: {
      risk_ratio: "مؤشر الخطورة",
      nationalities: "الجنسية",
      color: "لون درجة الخطورة",
      risk_degree: "درجة الخطورة",
    },
    placeholder: {
      enter_risk_ratio: "ادخل مؤشر الخطورة",
      enter_nationalities: "الجنسية",
      enter_risk_degree: "ادخل درجة الخطورة",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
      },
    },
    buttons: {
      Add_nationality: "إضافة درجة خطورة لجنسية",
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      send: "ارسال",
    },
    table: {
      "#": "#",
      degree_severity: "مؤشر الخطورة",
      risk_degree: "درجة الخطورة",
      nationalities: "الجنسية",
      actions: "العمليات",
      total: "الإجمالي",
      Date: "التاريخ",
      added_by: "تم بواسطة",
      department: "اسم القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      employee_job: "وظيفة الموظف",

      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      delete: "حذف",
      edit: "تعديل",
      view_details: "عرض",
    },
    fields: {
      category: "هذا التصنيف تم اضافته مسبقا",
    },
    validation: {
      required: "{field} مطلوب",
    },
    chips: {
      no_danger: "لا يوجد خطورة",
    },
  },
};
