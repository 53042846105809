<template>
  <div class="text-xs-center">
    <!--       v-if="pagination.total > 0 && pagination.last_page > 1" -->
    <v-pagination
      :value="pagination.current_page"
      :length="pagination.last_page"
      :total-visible="10"
      @input="handlePagination"
    ></v-pagination>
  </div>
</template>

<script>
import { observer } from "@/helpers/observer";
import { isEqual } from "lodash";
import { mapGetters } from "vuex";
export default {
  name: "Pagination",
  props: {
    reqName: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters({
      items: "getsTableItems",
    }),
  },
  watch: {
    items: {
      handler(val) {
        const { current_page, last_page } = this.pagination;
        if (val.length === 0 && current_page >= last_page && last_page > 1) {
          this.$store.dispatch("setQuery", {
            ...this.getQuery,
            page: current_page - 1,
          });
        }
        if (
          val.length > this.pagination.per_page &&
          this.getQuery.page >= last_page
        ) {
          this.$store.dispatch("setQuery", {
            ...this.getQuery,
            page: current_page + 1,
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handlePagination(page) {
      const query = { ...this.getQuery, page };
      if (!isEqual(query, this.getQuery)) {
        this.$store.dispatch("setQuery", query);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      if (observer.isExit("getTableData") && this.reqName) {
        observer.fire("getTableData");
      }
      if (observer.isExit("onPaginate")) {
        observer.fire("onPaginate");
      }
    },
  },
};
</script>
