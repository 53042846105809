export const translationsRoutes = [
  {
    path: "translations",
    to: "/settings/translations",
    meta: {
      title: "translations",
      permission: "localizations_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.translations.heading.translations",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/translations"),
    allow_children: false
  }
];
