<template>
  <ValidationProvider
    :name="name"
    :rules="
      (rules && typeof imageUrl !== 'string') || (rules && imageUrl == '')
        ? rules
        : ''
    "
    ref="fileRef"
    v-bind="$attrs"
    v-on="$listeners"
    v-slot="{ errors }"
  >
    <input
      type="file"
      @change="onFileChange($event, 'target')"
      ref="fileInput"
      hidden
    />
    <div
      @dragenter="onDragEnter"
      @dragleave="onDragLeave"
      @dragover.prevent
      @drop.prevent="onDragLeave"
      :class="{
        'drag-area': drag ? 'dragenter' : 'dragleave',
        'validation-error':
          errors.length > 0 || (serverErrors && serverErrors[$attrs.errorName]),
      }"
    >
      <div @drop="onFileChange($event, 'dataTransfer')">
        <v-btn
          block
          height="40px"
          type="button"
          icon
          :ripple="false"
          @click="openFile()"
          class="upload-btn"
        >
          <template>
            <div
              class="d-flex align-center image-add"
              :class="{ active: isActive }"
            >
              <div class="pa-5">
                <v-icon left color="#45A9D5" large class="ml-3">
                  mdi-cloud-upload
                </v-icon>
                <span class="file-name">
                  {{
                    uploadText ? uploadText : $t(`dashboard.${$attrs.label}`)
                  }}
                </span>
              </div>
            </div>
          </template>
        </v-btn>
        <div class="image-show d-flex align-center" v-if="image || imageUrl">
          <v-btn
            icon
            color="white"
            x-small
            class="error close-btn"
            @click="resetImg()"
            ><v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn
            v-if="
              !uploadText.includes('.pdf') ||
              (typeof imageUrl == 'string' && !imageUrl.includes('.pdf'))
            "
            icon
            color="info"
            small
            class="close-btn"
            @click="showImg()"
            ><v-icon>mdi-eye</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <transition name="fade" mode="in-out">
      <v-row class="my-0" style="position: absolute">
        <v-col
          cols="12"
          class="error--text upload_file_error"
          v-if="errors.length > 0"
        >
          {{ $t(`${errors[0]}`) }}
        </v-col>
        <v-col
          cols="12"
          class="error--text upload_file_error"
          v-if="serverErrors"
        >
          {{ serverErrors && serverErrors[$attrs.errorName] }}
        </v-col>
      </v-row>
    </transition>

    <!-- Image Preview -->
    <PreviewImage
      v-if="image || imageUrl"
      :dialog.sync="ShowDialog"
      :imgFile="image || imageUrl"
    />
  </ValidationProvider>
</template>

<script>
import PreviewImage from "./imagePreviewer";
export default {
  name: "DragFile",
  inheritAttrs: false,
  components: { PreviewImage },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    imageUrl: {
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
      drag: false,
      isActive: false,
      ShowDialog: false,
      uploadText: "",
    };
  },

  methods: {
    openFile() {
      this.$refs.fileInput.click();
    },

    onDragEnter() {
      this.drag = true;
    },
    onDragLeave() {
      this.drag = false;
    },

    async onFileChange(e, fileType) {
      this.form.deleted_image = 0;

      const file = e[fileType]["files"][0];
      const { valid } = await this.$refs.fileRef.validate(file);
      if (valid) {
        this.isActive = true;
        this.uploadText = file.name;
        const fileUrl = URL.createObjectURL(file);
        this.image = fileUrl;
        this.$emit("update:imageUrl", file);
      }
    },
    resetImg() {
      this.isActive = false;
      this.uploadText = "";
      this.image = "";
      this.$emit("update:imageUrl", "");
      this.form.deleted_image = 1;
      this.$nextTick(() => {
        this.$refs.fileRef.value = "";
        this.$refs.fileInput.value = "";
        this.$refs.fileRef.reset();
      });
    },
    showImg() {
      this.ShowDialog = true;
    },
  },
};
</script>

<style lang="scss">
.upload-btn {
  border-radius: 0;
  .v-btn__content {
    width: 100%;
    display: block;
  }
}
.image-add {
  width: 100%;
  overflow: hidden;
  &.active {
    width: calc(100% - 70px);
  }
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.file-name {
  max-width: calc(100% - 300px);
}
.drag-area {
  border: 1px solid #e4e4e4;
  border-radius: 10px;
  position: relative;
}
.dragenter {
  border-color: #ef1e41;
  .img-preview {
    z-index: -1;
  }
}

.drageleave {
  color: #c0c0c0;
  .img-preview {
    z-index: 1;
  }
}
.img-preview {
  position: absolute;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  right: 1px;
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.image-show {
  z-index: 2;
  left: 3px;
  top: 0;
  height: 100%;
  border-right: 1px solid #9e9e9e;
  position: absolute;
  .v-btn {
    margin-right: 5px;
  }
}
.upload_file_error {
  font-size: 13px;
  padding: 5px 20px;
}
.validation-error {
  border: 2px solid #ef1e41;
}
</style>
