export const vendorTransactionsRoutes = [
  {
    path: "vendor-transactions-record",
    meta: {
      title: "vendor_transactions_record",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.vendorTransactions.heading.vendorTransactionsRecord",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/vendorTransactions/record"),
    allow_children: false
  }
];
