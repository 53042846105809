import blueTheme from "@/assets/scss/themes/blue_theme.module.scss";
import greenTheme from "@/assets/scss/themes/green_theme.module.scss";
import redTheme from "@/assets/scss/themes/red_theme.module.scss";
import purpleTheme from "@/assets/scss/themes/purple_theme.module.scss";
import greyTheme from "@/assets/scss/themes/grey_theme.module.scss";
import pinkTheme from "@/assets/scss/themes/pink_theme.module.scss";
export default {
  state: () => ({
    themes: {
      blue: blueTheme,
      green: greenTheme,
      red: redTheme,
      purple: purpleTheme,
      grey: greyTheme,
      pink: pinkTheme
    },
    current_theme: {},
    theme_mode: "",
    theme_name: ""
  }),

  mutations: {
    SET_THEME(state, payload) {
      state.current_theme = state.themes[payload];
      state.theme_name = payload;
    },
    SET_THEME_MODE(state, payload) {
      state.theme_mode = payload;
    }
  },
  actions: {
    SetTheme({ commit }, payload) {
      commit("SET_THEME", payload);
    },
    SetThemeMode({ commit }, payload) {
      commit("SET_THEME_MODE", payload);
    }
  },

  getters: {
    getTheme(state) {
      return state.current_theme;
    },
    getThemes(state) {
      return state.themes;
    },
    getThemeMode(state) {
      return state.theme_mode;
    },
    getThemeName(state) {
      return state.theme_name;
    }
  }
};
