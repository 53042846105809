export const messagesBoxRoutes = [
  {
    path: "messagesBox",
    to: "/support/messagesBox",
    meta: {
      title: "messagesBox",
      permission: "messages_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          exact: true,
          link: true,
          to: "/support"
        },
        {
          text: "dashboard.messagesBox.heading.messagesBox",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesBox/record"),
    allow_children: false
  },

  {
    path: "messagesBox/:id/view",
    meta: {
      title: "view_messagesBox",
      permission: "messages_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          exact: true,
          link: true,
          to: "/support"
        },

        {
          text: "dashboard.messagesBox.heading.messagesBox",
          to: "/support/messagesBox",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesBox.heading.view_messagesBox",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesBox/view"),
    allow_children: false
  },
  {
    path: "messagesBox/:id/reply",
    meta: {
      title: "reply_messagesBox",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          exact: true,
          link: true,
          to: "/support"
        },

        {
          text: "dashboard.messagesBox.heading.messagesBox",
          to: "/support/messagesBox",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesBox.heading.reply_messagesBox",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesBox/crud"),
    allow_children: false
  }
];
