<template>
  <v-col cols="12">
    <v-row class="justify-end">
      <export-files
        v-if="$attrs.export !== false"
        :reqName="$attrs.exportReqName"
        :reqQuery="$attrs.reqQuery"
        :btnText="$t(`${$attrs.exportBtnText}`)"
        :pdf_permission="pdf_permission"
        :excel_permission="excel_permission"
      />
      <v-col v-if="hasFilter" class="d-flex justify-end" cols="12" sm="9">
        <Button @click="handleFilter" class="mx-3 primary" small>
          <v-icon color="white" class="shake">mdi-magnify</v-icon>
        </Button>
        <Button @click="resetFilter" outlined color="primary">
          <v-icon small class="spin">mdi-restore</v-icon>
          <span class="px-1">
            {{ $t(`dashboard.public.buttons.reset`) }}
          </span>
        </Button>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import FilterController from "@/helpers/FilterController";
import { observer } from "@/helpers/observer";

export default {
  name: "FilterActions",
  inheritAttrs: false,
  props: {
    hasFilter: {
      type: Boolean,
      default: true,
    },
    pdf_permission: {
      type: String,
      default: "",
    },
    excel_permission: {
      type: String,
      default: "",
    },
  },
  components: {
    ExportFiles: () => import("@/components/ExportFiles"),
  },
  data() {
    return {
      filterHandler: {},
      query: {},
    };
  },

  methods: {
    handleFilter() {
      this.filterHandler.filter();
    },
    resetFilter() {
      this.filterHandler.resetFilter();
      observer.fire("onResetFilter");
    },
  },
  created() {
    this.filterHandler = new FilterController(this);
  },
};
</script>
