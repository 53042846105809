export default {
  financialSettlementTransferSettings: {
    cards: {
      financialSettlementTransferSettings: "اعدادات تحويل التسويات المالية",
    },
    heading: {
    },
    labels: {
      automatic: "تلقائي",
      manual: "يدوي",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_settlement_number: "ادخل رقم التسوية",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_status: "اختر الحالة",
      enter_duration: "أدخل المدة الزمنية",
      select_period: "اختر الفترة الزمنية",
    },
  },
};
