export default {
  paymentServicesRecord: {
    heading: {
      add: "اضافة",
      edit: "تعديل",
    },
    fields: {
      payment_name: "اسم خدمة الدفع",
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        unarchive_job: "لا يمكنك تعطيل قسم ' اسم القسم ' لوجود موظفين به",
      },
    },
    table: {
      "#": "#",
      payment_name: "اسم خدمة الدفع",
      created_at: "التاريخ",
      actions: "العمليات",
    },
    buttons: {
      export: "تصدير",
      add: "اضافة",
      add_rate: "إضافة نسبة",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    labels: {
      payment_name: "اسم خدمة الدفع",
    },
    placeholder: {
      payment_name: "ادخل اسم خدمة الدفع",
    },
    statusOptions: {
    },
    chips: {
      active: "مفعل",
      deactive: " معطل",
    },
    msgDialog: {
      makesure_activation:
        "هل متأكد من تفعيل الحساب الذي قام بعمل محاولة مشبوه ؟ ",
      delet_sure: "هل تريد حذف صلاحية ' تعديل موظف ' ؟",
    },
  },
};
