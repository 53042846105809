export default {
    JackBalanceFee: {
        heading: {
          Jack_balance_fee:"رسوم رصيد جاك",
        },
        table: {
          "#": "#",
          client_name:"اسم العميل",
          Commercial_register:"السجل التجارى",
          taxNumber:"الرقم الضريبى",
          transaction_number:"رقم المعاملة",
          transaction_value:"قيمة المعاملة",
          Customer_receivables:"مستحقات العميل",
          Jack_fee:"رسوم جاك",
          Jack_per:"نسبة جاك",
          Date:"التاريخ",
          actions: "العمليات",
          total: "الإجمالي",
          pagination: {
            show: "عرض",
            to: "إلى",
            of: "من",
            result: "الإجمالي",
          },
        },
        buttons: {
          export: "تصدير",
          add_rate:"إضافه نسبه"
        },
        labels: {
          client_name:"اسم العميل",
          Commercial_Register:"السجل التجارى",
          transaction_number:"رقم المعاملة",
          taxNumber:"الرقم الضريبى",
          period_from: "الفترة من",
          period_to: "الفترة إلي",
          rate:"النسبه",Total_customer_dues:"اجمالي مستحقات العميل"
  
        },
        placeholder: {
          enter_CommercialRegister:"ادخل السجل التجارى",
          date_from: " ادخل الفترة من",
          date_to: "ادخل الفترة الى",
          enter_Commercial_Register:"ادخل السجل التجارى",
          taxNumber:"ادخل الرقم الضريبى",
          enter_client_name:"ادخل اسم العميل",
          Commercial_Register:"السجل التجارى",
          enter_transaction_number:"ادخل رقم المعاملة",
        },
        statusOptions: {
          all: "الجميع",
          vacant: "شاغرة",
          occupied: "مشغولة",
          active: "مفعل",
          deactive: " معطل",
        },
      },
    };
    