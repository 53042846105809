import router from "./router";
import store from "./store";
import { getToken } from "@/utils/auth"; // getToken from cookie

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css";
import { $http } from "@/api";
router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const queries = store.getters["getQuery"];
  if (getToken()) {
    if (Object.keys(queries).length > 0) {
      store.dispatch("removeQuery");
    }
    store.commit("RESET_TABLE_ITEMS");
    if (to.meta && to.meta.permission) {
      let reqName = "";
      if (to.meta["is_parent"]) {
        reqName = `has_permissions/${to.meta.permission}/program`;
      } else {
        reqName = `has_permissions/${to.meta.permission}`;
      }
      $http.get({ reqName });
    }
  }

  next();
});

router.afterEach(() => {
  NProgress.done();
  window.scrollTo({ top: 0, behavior: "smooth" });
});
