import { blackListRoutes } from "../vendors/blackList";
import { staticPagesRoutes } from "./staticPages";
import { linksRoutes } from "./links";
import { translationsRoutes } from "./translations";
import { countriesRoutes } from "./countries";
import { commissionsTaxesRoutes } from "./commissionsTaxes";
import { rasid_jack_programs_routes } from "./rasid_jack_programs";
import { rasid_jack_vendors_programs_routes } from "./rasid_jack_vendors_programs";

export const settingsRoutes = [
  {
    path: "/settings",
    to: "/settings",
    icon: "mdi-cog-outline",
    meta: {
      title: "settings",
      permission: "settings",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/settings/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",

        meta: {
          title: "settings",
          permission: "settings",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.settings",
              link: true,
              exact: true,
              to: "/settings"
            }
          ]
        },
        component: () => import("@/views/settings")
      },
      ...blackListRoutes,
      ...staticPagesRoutes,
      ...linksRoutes,
      ...translationsRoutes,
      ...commissionsTaxesRoutes,
      ...countriesRoutes,
      ...commissionsTaxesRoutes,
      ...rasid_jack_programs_routes,
      ...rasid_jack_vendors_programs_routes
    ]
  }
];
