const base = function () {
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test"
  ) {
    return "https://pospi.mt-org.com/api/dashboard";
  } else {
    return "https://pospi.mt-org.com/api/dashboard";
    // return "https://pospi.mt-org.com/api/dashboard";
  }
};
export const baseURL = base();
