export default {
  authorizeReport: {
    heading: {
      authorizations: "سجل التفويضات",
    },
    buttons: {
      accept: "موافق",
      refuse: "غير موافق",
      share: "مشاركة ",
      print: "طباعة",
    },
    labels: {
      doc_num: "رقم تفويض",
      vendor_type: "نوع العميل",
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري",
      end_date: "تاريخ انتهاء التفويض",
      tax_number: "الرقم الضريبي",
      auth_to_name: "اسم المفوض له",
      auth_name: "اسم المفوض",
      auth_to_identity: "هوية المفوض له",
      auth_identity: "هوية المفوض",
      auth_to_phone: "جوال المفوض له",
      auth_content: "مضمون التفويض",
    },
  },
};
