import vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FormGroup from "@/components/FormGroup";
import ShimmerLoader from "@/components/ShimmerLoader";
import CustomTable from "@/components/CustomTable";
import Pagination from "@/components/Pagination";
import ToolTip from "@/components/ToolTip";
import FiltersTextInput from "@/components/Filters/TextInput";
import FiltersSelect from "@/components/Filters/Select";
import FiltersDatePicker from "@/components/Filters/DatePicker";
import FiltersActions from "@/components/FiltersActions";
import GenericDialog from "@/components/GenericDialog";
import ImagePreviewer from "@/components/ImagePreviewer";
import Button from "@/components/Button";
import Lottie from "@/components/Lottie";
import FormsTextInput from "@/components/Forms/TextInput";
import FormsTextArea from "@/components/Forms/TextArea";
import FormsAutoComplete from "@/components/Forms/AutoComplete";
import DatePicker from "@/components/Forms/DatePicker";
import TruncateTooltip from "@/components/TruncateTooltip";
import CardCategory from "@/components/CardCategory";
import CardHome from "@/components/CardHome";
import FormDialog from "@/components/FormDialog";
import UploadFile from "@/components/UploadFile";
import UploadImage from "@/components/UploadImage";
import GridStatus from "@/components/GridStatus";
import StatusOption from "@/components/StatusOption";
import CustomEditor from "@/components/CustomEditor";
import AddReport from "@/components/AddReport";

// components
vue.component("ValidationProvider", ValidationProvider);
vue.component("ValidationObserver", ValidationObserver);
vue.component("FormGroup", FormGroup);
vue.component("ToolTip", ToolTip);
vue.component("ShimmerLoader", ShimmerLoader);
vue.component("CustomTable", CustomTable);
vue.component("Pagination", Pagination);
vue.component("FiltersTextInput", FiltersTextInput);
vue.component("FiltersSelect", FiltersSelect);
vue.component("FiltersDatePicker", FiltersDatePicker);
vue.component("FiltersActions", FiltersActions);
vue.component("GenericDialog", GenericDialog);
vue.component("ImagePreviewer", ImagePreviewer);
vue.component("Button", Button);
vue.component("Lottie", Lottie);
vue.component("FormsTextInput", FormsTextInput);
vue.component("FormsTextArea", FormsTextArea);
vue.component("FormsAutoComplete", FormsAutoComplete);
vue.component("TruncateTooltip", TruncateTooltip);
vue.component("CardCategory", CardCategory);
vue.component("CardHome", CardHome);
vue.component("FormDialog", FormDialog);
vue.component("UploadFile", UploadFile);
vue.component("UploadImage", UploadImage);
vue.component("GridStatus", GridStatus);
vue.component("StatusOption", StatusOption);
vue.component("DatePicker", DatePicker);
vue.component("CustomEditor", CustomEditor);
vue.component("AddReport", AddReport);
