export const registerVendorRoutes = [
  {
    path: "registerVendor",
    to: "/vendors/registerVendor",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "registerVendor",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendorsRecord.heading.vendors_record",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/vendorsRecord"
        },
        {
          text: "dashboard.vendors.cards.register_vendor",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/registerVendor/crud"),
    allow_children: false
  }
];
