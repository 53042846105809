export default {
  countries: {
    heading: {
      countries: "الدول",
      add_countries: "اضافة دولة",
      edit_countries: "تعديل الدولة",
    },

    table: {
      "#": "#",
      links: "الروابط",
      staticPages: "الصفحات الثابتة",
      actions: "العمليات",
      country_name: "اسم الدولة",
      country_code: "كود الدولة",
      nationality: "الجنسية",
      currency: "العملة",
      currency_code: "كود العملة",
      status: "الحالة",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },

    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        page_has_link: "لا يمكن حذف صفحة مرتبطة برابط",
        edit_page_with_link: "لا يمكن تعطيل صفحة لها رابط",
      },
    },

    labels: {
      country_name: "اسم الدولة",
      country_code: "كود الدولة",
      nationality: "الجنسية",
      currency_code: "كود العملة",
      currency: "العملة",
      status: "الحالة",
      flag: "علم الدولة",
      drag_image: " اسحب أو ارفع علم الدولة",
    },

    fields: {
      country_name: "اسم الدولة",
      country_code: "كود الدولة",
      nationality: "الجنسية",
      currency_code: "كود العملة",
      currency: "العملة",
      status: "الحالة",
      flag: "علم الدولة",
    },

    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      mimes: "{field} يجب ان يكون  {mimes}",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },

    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة دولة",
      edit: "تعديل",
      close: "غلق",
    },

    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعلة",
      deactive: " معطلة",
      in_active: " معطل",
    },

    placeholder: {
      select_staticPage: "اختر الصفحة الثابتة",
      country_name: "ادخل اسم الدولة",
      country_code: "ادخل كود الدولة",
      nationality: "ادخل الجنسية",
      currency: "ادخل العملة",
      currency_code: "ادخل كود العملة",
      status: "اختر الحالة",
      select_country_code: "اختر كود الدولة",
    },

    chips: {
      active: "مفعلة",
      deactive: " معطلة",
    },
  },
};
