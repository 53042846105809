<template>
  <v-tooltip top :color="color">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="noIcon"
        icon
        v-bind="[attrs, $attrs]"
        v-on="{ ...on, ...$listeners }"
      >
        <v-hover v-slot="{ hover }">
          <v-icon :color="hover ? color : $attrs.iconColor">{{ icon }}</v-icon>
        </v-hover>

        <slot />
      </v-btn>

      <div v-else v-bind="[attrs, $attrs]" v-on="{ ...on, ...$listeners }">
        <slot />
      </div>
    </template>
    <span>{{ noIcon ? $t(`dashboard.${text}`) : text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Tooltip",
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
    noIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
