export const vipVendorsRoutes = [
  {
    path: "vipVendors",
    to: "/vendors/vipVendors",
    meta: {
      title: "vipVendors",
      permission: "vip_vendors_settings_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.settings.cards.vipVendors",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/vipVendors/crud"),
    allow_children: false
  }
];
