export const serviceFeesRecordRoutes = [
  {
    path: "service-fees-record",
    to: "/transactions/service-fees-record",
    meta: {
      title: "serviceFeesRecord",
      permission: "service_fees_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.serviceFeesRecord.heading.serviceFeesRecord",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/serviceFeesRecord/record"
      ),
    allow_children: false
  }
];
