import { messagesTypesRoutes } from "./messagesTypes";
import { messagesBoxRoutes } from "./messagesBox";
export const supportRoutes = [
  {
    path: "/support",
    to: "/support",
    icon: "mdi-phone-incoming-outgoing-outline",
    meta: {
      title: "support",
      permission: "technical_support_department",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/support/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",

        meta: {
          title: "support",
          permission: "technical_support_department",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.support",
              link: true,
              exact: true,
              to: "/support"
            }
          ]
        },
        component: () => import("@/views/support")
      },
      ...messagesTypesRoutes,
      ...messagesBoxRoutes
    ]
  }
];
