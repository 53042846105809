import exceedTransactions from "./exceedTransactions";
import suspectedTransactions from "./suspectedTransactions";
import suspectedAccountsRequest from "./suspectedAccountsRequest";
import suspectedEmployeesRequest from "./suspectedEmployeesRequest";

export default {
  ...exceedTransactions,
  ...suspectedTransactions,
  ...suspectedAccountsRequest,
  ...suspectedEmployeesRequest,
};
