export default {
  vendorsRecord: {
    fields: {
      name: "الاسم",
      reason: "السبب",
      bank_account_status: "حالة حساب البنك",
      bank: "البنك",
      iban: "الايبان",
      vendor_classification: "تصنيف العميل",
      activity: "النشاط",
      expected_yearly_sales: "قيمة المبيعات المتوقعة سنويا",
      distinct_value: "نسبة رسوم رصيد جاك الاستثنائية",
      status: "الحالة",
      vendor_logo: "شعار العميل",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    heading: {
      add: "إضافة",
      vendors_record: "سجل العملاء",
      view_vendorsRecord: "عرض",
      historical_movment: "الحركة التاريخية",
      update_bank_account_status: "مراجعة الحساب البنكى",
      edit_vendor: "تعديل",
    },
    popup: {
      buttons: {
        save: "حفظ",
        cancel: "إلغاء",
        refuse: "غير موافق",
        accept: "موافق",
      },
      body: {
        reasonLabel: "السبب",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        vipAddTitle: "إضافة تصنيف كبار العملاء",
        vipVendorAddLabel1: "إضافة العميل",
        vipVendorAddLabel2: "إلي تصنيف كبار العملاء",
        vipCancelTitle: "إلغاء تصنيف كبار العملاء",
        vipVendorCancelLabel1: "سيتم إلغاء العميل ",
        vipVendorCancelLabel2: "من تصنيف كبار العملاء",
        hangTitle: "تعليق حساب عميل",
        cant_update_bank_status: "برجاء رفع الحساب البنكي المعتمد اولا",
        un_ban_title: "إعادة تنشيط",
      },
      reasonValidation: "السبب",
    },
    table: {
      "#": "#",
      identity_number: "رقم الهوية",
      vendor_type: "نوع العميل",
      vendor_name: "اسم العميل",
      facility_name: "اسم المنشأة",
      facility_professional_title: "اسم المنشأة/المسمي المهني",
      commercial_number: "السجل التجاري / الوثيقة",
      bank_name: "اسم البنك",
      activity: "النشاط",
      branches: "الفروع",
      employees: "الموظفين",
      bank_status: "حالة الحساب البنكي",
      vendor_account_status: "حالة حساب العميل",
      added_by: "تم بواسطة",
      department: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      actions: "العمليات",
      employee_job: "الوظيفة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      exclusion: "استبعاد",
      vip_vendor: "كبار عملاء",
      delete_vip_vendor: "حذف من كبار العملاء",
      add_vip_vendor: "إضافة إلي كبار العملاء",
      view_details: "عرض",
      edit: "تعديل",
      hang: "تعليق",
      approval: "اعتماد",
      bank_status_revision: "مراجعة حالة البنك",
      completed_register: "استكمال البيانات",
      add_to_blacklist: "إضافة للقائمة السوداء",
      un_ban: "إعادة تنشيط",
    },
    buttons: {
      export: "تصدير",
      add_vendor: "إضافة عميل",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      completed_register: "استكمال البيانات",
    },
    labels: {
      vendor_given_risk_degree: "درجة الخطورة الممنوحة للعميل",
      severity_index: "مؤشر الخطورة",
      company_capital: "رأس مال الشركة",
      old_bank_name: "اسم البنك القديم",
      new_bank_name: "اسم البنك الجديد",
      old_iban: "الأيبان القديم",
      new_iban: "الأيبان الجديد",
      bank_rejected_reason: "سبب رفض الحساب البنكي",
      vendor_type: "نوع العميل",
      facility_name: "اسم المنشأة",
      professional_title: "المسمي المهني",
      admin_comment: "رأي المسؤول",
      commercial_number: "السجل التجاري / الوثيقة",
      bank_name: "اسم البنك",
      activity: "النشاط",
      bank_status: "حالة الحساب البنكي",
      vendor_account_status: "حالة حساب العميل",
      vendor_logo: "شعار العميل",
      branches_count: "عدد الفروع",
      employees_count: "عدد الموظفين",
      facility_code: "كود المنشأة",
      expire_date: "تاريخ انتهاء الترخيص",
      identity_number: "رقم الهوية",
      phone: "رقم الجوال",
      actual_sales: "قيمة المبيعات الفعلية",
      iban: "الايبان",
      vendor_classification: "تصنيف العميل",
      expected_yearly_sales: "قيمة المبيعات المتوقعة سنويا",
      execptional_rates: "نسبة رسوم رصيد جاك الاستثنائية",
      status: "الحالة",
      reason: "السبب",
      bank: "البنك",
      request_type: "نوع الطلب",
      bank_account_status: "حالة الحساب البنكى",
      distinct_value: "نسبة رسوم رصيد جاك الاستثنائية",
      drag_image: "أرفق الشعار",
      expected_daily_sales: "قيمة المبيعات المتوقعة يوميا",
      expected_monthly_sales: "قيمة المبيعات المتوقعة شهريا",
      vendor_name: "اسم العميل",
      email: "البريد الإلكتروني",
      nationality: "الجنسية",
      facility_nationality: "جنسية المنشأة",
      show_pdf: "عرض صورة المستند الدال علي صحة الحساب",
      risk_percent: "نسبة الخطورة",
      risk_degree: "درجة الخطورة",
      tax_number: "الرقم الضريبي",
      request_code: "كود الطلب",
      request_number: "رقم الطلب",
      city: "المدينة",
      region: "المنطقة",
      district: "الحي",
      street: "الشارع",
      facility_professional_title: "اسم المنشأة/المسمي المهني",
      view_delegation_data: "عرض بيانات التفويض",
      max_expected_value: "الحد الاقصي لقيمة المعاملة الواحدة",
      commercial_expire_date: "تاريخ انتهاء السجل",
      document_expire_date: "تاريخ انتهاء الوثيقة",
    },
    placeholder: {
      enter_identity_number: "أدخل رقم الهوية",
      select_vendor_type: "اختر نوع العميل",
      enter_vendor_name: "ادخل اسم العميل",
      enter_facility_name: "ادخل اسم المنشأة",
      enter_commercial_number: "ادخل السجل التجاري / الوثيقة",
      enter_bank_name: "ادخل اسم البنك",
      select_activity: "اختر النشاط",
      select_bank_status: "اختر حالة الحساب البنكي",
      select_vendor_account_status: "اختر الحالة",
      select_status: "اختر الحالة",
      select_request_type: "اختر نوع الطلب",
      enter_reason: "أدخل السبب",
      select_bank: "اختر البنك",
      enter_iban: "أدخل الأيبان",
      select_vendor_classification: "اختر تصنيف العميل",
      enter_expected_yearly_sales: "أدخل القيمة",
      enter_distinct_value: "أدخل النسبة ",
      enter_expected_daily_sales: "أدخل القيمة",
      enter_expected_monthly_sales: "أدخل القيمة",
      enter_facility_professional_title: "ادخل اسم المنشأة/المسمي المهني",
    },
    statusOptions: {
      all: "الجميع",
      commercial_record: "سجل تجاري",
      free_work_document: "وثيقة عمل حر",
      profession_practice_document: "وثيقة مزاولة مهنة",
      confirmed: "تم التأكيد",
      pending: "بانتظار التأكيد",
      declined: "مرفوض",
      active: "مفعل",
      hold: "معلق",
      in_active: "راكد",
      closed: "مغلق",
      is_active: "مفعل",
      un_active: "معلق",
      not_completed: "لم يتم الاستكمال",
      certified: "تفويض معتمد",
      electronic: "تفويض الإلكتروني",
      form: "نموذج التفويض",
      rejected_requests: "طلبات مرفوضة",
      accepted_requests: "طلبات معتمدة",
      canceled_requests_for_repeat: "طلبات ملغاه للتكرار",
      document: "وثيقة",

      commercial_certified: "سجل تجاري (تفويض معتمد)",
      commercial_electronic: "سجل تجاري (تفويض الإلكتروني)",
      commercial_form: "سجل تجاري (نموذج التفويض)",
    },
  },
};
