export const settlementsDurationRecordRoutes = [
  {
    path: "settlements-duration-record",
    to: "/transactions/settlements-duration-record",
    meta: {
      title: "settlements-time-record",
      permission: "settlement_periods_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlementsDurationRecord",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/settlementsDurationRecord/record"
      ),
    allow_children: false
  },
  {
    path: "settlements-duration/:id/view",
    meta: {
      title: "settlements_duration_view",
      permission: "settlement_periods_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlementsDurationRecord",
          to: "/transactions/settlements-duration-record",
          link: true,
          exact: true
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlements_duration_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/settlementsDurationRecord/view"
      ),
    allow_children: false
  },
  {
    path: "settlements-duration/add",
    meta: {
      title: "settlements_duration_add",
      permission: "settlement_periods_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlementsDurationRecord",
          to: "/transactions/settlements-duration-record",
          link: true,
          exact: true
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlements_duration_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/settlementsDurationRecord/crud"
      ),
    allow_children: false
  },
  {
    path: "settlements-duration/:id/edit",
    meta: {
      title: "settlements_duration_edit",
      permission: "settlement_periods_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlementsDurationRecord",
          to: "/transactions/settlements-duration-record",
          link: true,
          exact: true
        },
        {
          text: "dashboard.settlementsDurationRecord.heading.settlements_duration_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/settlementsDurationRecord/crud"
      ),
    allow_children: false
  }
];
