<template>
  <!-- oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9 + ^\u0000-\u0080g]/g, '').replace(/\+/g,'').replace(/\./g,'');" -->
  <!-- oninput="this.value= this.value&&this.value.replace(/[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufbc1]|[\ufbd3-\ufd3f]|[\ufd50-\ufd8f]|[\ufd92-\ufdc7]|[\ufe70-\ufefc]|[\uFDF0-\uFDFD]/, '')" -->

  <!-- @copy.prevent
    @paste.prevent
    @drag.prevent
    @drop.prevent -->
  <v-text-field
    v-if="onlyNumber"
    oninput="this.value= this.value&&this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1').replace(/\+/g,'').replace(/\./g,'');"
    @focus.prevent
    v-on="$listeners"
    @input="clearServerErrors"
    v-bind="$attrs"
  >
    <template #append>
      <slot />
    </template>
  </v-text-field>

  <v-text-field
    v-else-if="normalField"
    v-bind="$attrs"
    @focus.prevent
    v-on="$listeners"
    @input="clearServerErrors"
  ></v-text-field>
  <v-text-field
    v-else-if="!onlyNumber"
    oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9 + \u0621-\u064A\u0660-\u0669g]/g, '').replace(/\+/g,'')"
    @focus.prevent
    v-on="$listeners"
    @input="clearServerErrors"
    v-bind="$attrs"
  >
    <template #append> <slot name="append-icon" /> </template>
  </v-text-field>

  <!-- oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9-_ + \u0621-\u064A\u0660-\u0669g]/g, '').replace(/\+/g,'').replace(/\./g,'');" -->
</template>

<script>
export default {
  name: "TextInput",
  inheritAttrs: false,
  props: {
    onlyNumber: {
      type: Boolean,
      default: false
    },

    normalField: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clearServerErrors() {
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
    }
  }
};
</script>
