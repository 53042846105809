<template>
  <v-autocomplete
    v-bind="$attrs"
    v-on="$listeners"
    prepend-inner-icon="mdi-magnify"
    :no-data-text="$t('dashboard.public.no_data')"
    oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9 + \u0621-\u064A\u0660-\u0669g]/g, '').replace(/\+/g,'')"
    @focus.prevent
    @copy.prevent
    @paste.prevent
    @drag.prevent
    @drop.prevent
  >
    <template #prepend-inner> <slot name="prepend-icon" /> </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "AutoComplete"
};
</script>
