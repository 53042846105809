export const changeCustomerDataRoutes = [
  {
    path: "legalAccreditation",
    to: "/vendors/legalAccreditation",
    meta: {
      title: "legalAccreditation",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.legal_accreditation_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/legalAccreditation/record"),
    allow_children: false
  },
  {
    path: "legalAccreditation/:id/view",
    meta: {
      title: "view_legalAccreditation",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.cards.legal_accreditation",
          to: "/vendors/legalAccreditation",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/legalAccreditation/view"),
    allow_children: false
  }
];
