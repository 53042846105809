export const severitySettingsAreasRoutes = [
  {
    path: "severity_settings_areas",
    to: "/money-laundering/severity_settings_areas",
    meta: {
      title: "severity_settings_areas",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        // {
        //   text:
        //     "dashboard.moneyLaundering.severitySettingsAreas.heading.moneyLaunderingSetting"
        //   ),
        //   disabled: false,
        //   exact: true,
        //   link: true,
        //   to: "/money-laundering",
        // },
        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsAreas/record"),
    allow_children: false
  },
  {
    path: "severity_settings_areas/add",
    meta: {
      title: "severity_settings_areas_add",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas",
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_areas"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsAreas/crud"),
    allow_children: false
  },
  {
    path: "severity_settings_areas/:id/edit",
    meta: {
      title: "severity_settings_areas_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas",
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_areas"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsAreas/crud"),
    allow_children: false
  },
  {
    path: "severity_settings_areas/:id/view",
    meta: {
      title: "severity_settings_areas_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas",
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_areas"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.severity_settings_areas_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsAreas/view"),
    allow_children: false
  }
];
