<template>
  <div>
    <span class="light-success success--text status" v-if="item[statusName]">
      {{ $t("dashboard.public.active") }}
    </span>
    <span class="error-light error--text status" v-else>
      {{ $t("dashboard.public.in_active") }}</span
    >
  </div>
</template>

<script>
export default {
  name: "StatusOption",
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    statusName: {
      type: String,
      default: "is_active"
    }
  }
};
</script>
<style scoped lang="scss">
.status {
  padding: 7px 20px;
  border-radius: 8px;
}
</style>
