export default {
  activities: {
    heading: {
      activities_record: "سجل الأنشطة",
      activities_setting: "اعدادات إدارة المخاطر",
      activities_view: "عرض",
      activities_add: "إضافة",
      activities_edit: "تعديل",
      historical_movment: "الحركة التاريخية",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        activity_has_vendors: "لا يمكن حذف نشاط مرتبط بعملاء",
      },
    },
    table: {
      "#": "#",
      activity_name: "اسم النشاط",
      status: "الحالة",
      activity: "النشاط",
      // department: "القسم",
      employee_job: "الوظيفة",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      edit: "تعديل",
      delete: "حذف",
      view_details: "عرض",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      send: "ارسال",
      add_activity: "إضافة نشاط",
    },
    labels: {
      activity_name: "اسم النشاط",
      status: "الحالة",
    },
    placeholder: {
      enter_activity_name: "أدخل اسم النشاط",
      select_status: "إختر الحالة",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
    },
    chips: {
      active: "مفعل",
      in_active: "معطل",
    },
  },
};
