<template>
  <v-dialog
    persistent
    max-width="500"
    :value="dialog"
    class="radius-8"
    @input="$emit('update:dialog')"
  >
    <v-card max-width="500" class="pb-2 radius-8">
      <v-btn
        class="close-modal radius-8"
        max-height="30"
        max-width="30"
        fab
        color="grey lighten-4"
        @click="$emit('update:dialog')"
      >
        <v-icon
          :color="$attrs.closeIconColor ? $attrs.closeIconColor : 'primary'"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <v-card-title class="primary--text" style="height: 100px">
        <v-avatar size="100" class="form-dialog__card--avatar pa-2">
          <v-img
            contain
            max-height="80"
            :src="require(`@/assets/icons/${icon}`)"
            alt="photo"
          />
        </v-avatar>
      </v-card-title>
      <v-card-subtitle
        class="d-flex justify-center"
        :class="$attrs.titleColor ? $attrs.titleColor : 'primary--text'"
        style="font-size: 16px"
        v-if="$attrs.title"
      >
        <slot name="title"> {{ $t(`dashboard.${$attrs.title}`) }}</slot>
      </v-card-subtitle>
      <v-card-text class="px-5 pt-1 pb-5">
        <p
          class="text-center black--text font-weight-bold"
          v-if="$attrs.content || $attrs.contentType || $slots.content"
        >
          <slot name="content">
            {{
              $attrs.content
                ? $attrs.content
                : $t(`dashboard.${$attrs.contentType}`)
            }}</slot
          >
        </p>
        <ValidationObserver ref="form" v-if="$attrs.reasonBox && withoutReason">
          <v-form>
            <label>
              <span class="font-medium form-label mb-2">
                {{ $t(`dashboard.${$attrs.reasonLabel}`) }}
                <span class="starrisk">*</span>
              </span>
            </label>
            <FormGroup
              :rules="{ required: withoutReason, min: 10, max: 300 }"
              :name="$t(`public`)"
            >
              <template #default="{ attrs }">
                <FormsTextArea
                  label=""
                  v-bind="attrs"
                  v-model="form.reason"
                  no-resize
                  rows="4"
                  outlined
                  oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9_ + \u0621-\u064A\u0660-\u0669g]/g, '')"
                >
                </FormsTextArea>
              </template>
            </FormGroup>
          </v-form>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions class="px-0 justify-center" v-if="showActions">
        <!-- if has reason box-->
        <template>
          <v-btn
            @click="onClickToConfirm"
            :color="color"
            v-if="$attrs.reasonBox"
          >
            <span class="white--text">
              {{ $t(`dashboard.${acceptBtn}`) }}
              <!-- {{
                $attrs.type == "archive"
                  ? $t("dashboard.button.accept")
                  : $t("dashboard.button.confirm")
              }} -->
            </span>
          </v-btn>

          <v-btn
            v-else-if="$attrs.type == 'confirmSubmit'"
            @click="$emit('on-submit')"
            :color="color"
          >
            <span class="white--text"> {{ $t(`dashboard.${acceptBtn}`) }}</span>
          </v-btn>

          <!-- if back-->
          <v-btn v-else @click="handleConfirm" :color="color">
            <span class="white--text">
              {{ $t(`dashboard.${acceptBtn}`) }}
            </span>
          </v-btn>
          <v-btn
            @click="$emit('update:dialog')"
            color="grey lighten-2"
            class=""
          >
            {{ $t(`dashboard.${refuseBtn}`) }}
          </v-btn>
        </template>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GenericDialog",
  inheritAttrs: false,
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    withoutReason: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    redirect: {
      type: String,
      default: "",
    },

    acceptBtn: {
      type: String,
      default: "",
    },
    refuseBtn: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "save.png",
    },
  },
  data() {
    return {
      form: {
        reason: "",
        _method: "delete",
      },
    };
  },
  methods: {
    onClickToConfirm() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$emit("on-confirm", this.form);
        }
      });
    },
    handleConfirm() {
      this.$router.push({ path: this.redirect });

      this.$eventBus.$emit("on-backDialog-confirmed");
      this.$emit("on-confirm");

      // if (this.$route.hash === '#record') {
      //   this.$router.replace({ query: {}, path: this.redirect })
      //   return
      // } else if (this.$route.hash === '#view') {
      //   this.$router.go(-1)
      //   return
      // } else {
      //   if (this.redirect !== '') {
      //     this.$router.replace({ query: {}, path: this.redirect })
      //   } else {
      //     this.$router.replace({ query: {}, path: '/home' })
      //   }
      // }
    },
    resetData() {
      this.form.reason = "";
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
    },
  },

  computed: {
    getIcon() {
      return this.$attrs.icon ? this.$attrs.icon : this.$attrs.type;
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (!value) {
          this.resetData();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.close-modal {
  position: absolute;
  left: 10px;
  top: 10px;
}
.v-dialog__content .v-btn {
  min-width: inherit !important;
}
</style>
