export const jobsRoutes = [
  {
    path: "jobs",
    to: "/employeesManagement/jobs",
    meta: {
      title: "jobs",
      permission: "rasid_jobs_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },
        {
          text: "dashboard.jobs.heading.jobs",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/jobs/record"),
    allow_children: false
  },

  {
    path: "jobs/add",
    meta: {
      title: "add_job",
      permission: "rasid_jobs_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.jobs.heading.jobs",
          to: "/employeesManagement/jobs",
          link: true,
          exact: true
        },
        {
          text: "dashboard.jobs.heading.add_job",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/jobs/crud"),
    allow_children: false
  },

  {
    path: "jobs/:id/view",
    meta: {
      title: "view_job",
      permission: "rasid_jobs_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.jobs.heading.jobs",
          to: "/employeesManagement/jobs",
          link: true,
          exact: true
        },
        {
          text: "dashboard.jobs.heading.view_job",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/jobs/view"),
    allow_children: false
  },
  {
    path: "jobs/:id/edit",
    meta: {
      title: "edit_job",
      permission: "rasid_jobs_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.jobs.heading.jobs",
          to: "/employeesManagement/jobs",
          link: true,
          exact: true
        },
        {
          text: "dashboard.jobs.heading.edit_job",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/jobs/crud"),
    allow_children: false
  }
];
