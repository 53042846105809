export const successfulTransactionsRoutes = [
  {
    path: "successful-transactions",
    to: "/transactions/successful-transactions",
    meta: {
      title: "successfulTransactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.successfulTransactions.heading.successfulTransactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/transactions/successfulTransactions/record"),
    allow_children: false
  },
  {
    path: "successful-transactions/:id/view",
    meta: {
      title: "successful_transactions_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.successfulTransactions.heading.successfulTransactions",
          to: "/transactions/successful-transactions",
          link: true,
          exact: true
        },
        {
          text: "dashboard.successfulTransactions.heading.successful_transactions_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/transactions/successfulTransactions/view"),
    allow_children: false
  }
];
