import { $http } from "@/api";
import Vue from "vue";
const vm = new Vue();
export default {
  state: () => ({
    items: [],
    pagination: {
      current_page: 1,
      last_page: 0,
      per_page: 0,
      total: 0
    }
  }),
  mutations: {
    RESET_TABLE_ITEMS(state) {
      state.items = [];
      state.pagination = {
        current_page: 1,
        last_page: 0,
        per_page: 0,
        total: 0
      };
    },
    SET_TABLE_ITEMS(state, items) {
      state.items = items;
    },
    UPDATE_TABLE_ITEM(state, payload) {
      const index = state.items.map((item) => item.id).indexOf(payload.id);
      vm.$set(state.items, index, payload);
    },
    ADD_TABLE_ITEM(state, item) {
      state.items.push(item);
    },
    SET_TABLE_DEPARTMENTS(state, departments) {
      state.departments = departments;
    },
    SET_TABLE_PAGINATION(state, meta) {
      state.pagination = { ...state.pagination, ...meta };
    },
    DELETE_TABLE_ITEM(state, id) {
      state.items = state.items.filter((item) => item.id !== id);
    },
    RESET_PAGINATION(state) {
      state.pagination = {
        current_page: 1,
        last_page: 0,
        per_page: 0,
        total: 0
      };
    }
  },
  actions: {
    async getTableItems({ commit, rootState }, { reqName }) {
      return $http
        .get({
          reqName,
          query: rootState.query.query
        })
        .then((res) => {
          const { data, meta } = res.data;
          commit("SET_TABLE_ITEMS", data);
          if (meta) {
            commit("SET_TABLE_PAGINATION", meta);
          }
        });
    },
    resetPagination({ commit }) {
      commit("RESET_PAGINATION");
    }
  },
  getters: {
    getsTableItems(state) {
      return state.items;
    },
    getPagination(state) {
      return state.pagination;
    }
  }
};
