import cards from "./cards";
import deductions from "./deductions";
import JackBalanceDues from "./JackBalanceDues";
import JackBalanceFee from "./JackBalanceFee";
import PaymentServicesDues from "./PaymentServicesDues";
import IntermediaryBankReceivablesRecord from "./IntermediaryBankReceivablesRecord";
import addedTax from "./addedTax";
import setTheValueAddedPercentage from "./setTheValueAddedPercentage";
import fixedRatioSettings from "./fixedRatioSettings";
import paymentServicesRecord from "./paymentServicesRecord";
import intermediaryBankReceivablesSettings from "./intermediaryBankReceivablesSettings";
import ReceivablesTransferSettingsForTheIntermediaryBank from "./ReceivablesTransferSettingsForTheIntermediaryBank";
import serviceFeeSettings from "./serviceFeeSettings";
export default {
  ...deductions,
  ...JackBalanceDues,
  ...JackBalanceFee,
  ...addedTax,
  ...PaymentServicesDues,
  ...IntermediaryBankReceivablesRecord,
  ...paymentServicesRecord,
  ...fixedRatioSettings,
  ...setTheValueAddedPercentage,
  ...intermediaryBankReceivablesSettings,
  ...serviceFeeSettings,
  ...ReceivablesTransferSettingsForTheIntermediaryBank,
  commissionsTaxes: {
    ...cards
  }
};
