export default {
  jobs: {
    fields: {
      job_name: "اسم الوظيفة",
      department: "القسم",
      status: "الحالة",
      required_number: "العدد المتاح لشغل الوظيفة",
      permissions: "الصلاحيات"
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field}   لا يجب ان يزيد عن {max}",
      min: "{field}   يجب ان يكون علي الاقل {min} من الحروف",
      min_value: "{field} يجب ان لا يقل عن  {min_value}",
      max_value: "{field} يجب ان لا تزيد عن  {min_value}"
    },
    heading: {
      jobs: "سجل الوظائف",
      add_job: "إضافة وظيفة",
      edit_department: "تعديل قسم",
      edit_job: "تعديل وظيفة",
      view_job: "عرض وظيفة",
      historical_movment: "الحركة التاريخية"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        NodeleteTitle: "لا يمكن حذف وظيفة مرتبطة بموظفين",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        disableJobTitle: "لا يمكن تعطيل وظيفة مرتبطة بموظفين"
      }
    },
    table: {
      "#": "#",
      date: "الوقت والتاريخ",
      status: "الحالة",
      job_name: "اسم الوظيفة",
      Date_created: "تاريخ الإنشاء",
      department: "القسم",
      department_name: "اسم القسم",
      type: "النوع",
      active: "النشاط",
      remain_number: "العدد المتبقي",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      actions: "العمليات",
      total: "الإجمالي",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف"
    },
    buttons: {
      export: "تصدير",
      add_jobs: "إضافة وظيفة",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل"
    },
    labels: {
      status: "الحالة",
      job_name: "اسم الوظيفة",
      reqired_number: "العدد المطلوب",
      type: "النوع",
      is_active: "الحالة",
      department: "القسم",
      remain_number: "العدد المتبقي",
      required_number: "العدد المتاح لشغل الوظيفة",
      vacant: "شاغرة",
      occupied: "مشغولة",
      permissions: "الصلاحيات"
    },
    placeholder: {
      select_type: "اختر النوع",
      enter_job_name: "ادخل اسم الوظيفة",
      enter_reqired_number: "ادخل العدد المطلوب",
      enter_is_active: "ادخل الحالة",
      choose_status: "ادخل ادخل الحاله",
      select_status: "الحاله",
      select_department: "اختر القسم",
      enter_remain_number: "أدخل العدد المتبقي",
      enter_required_number: "أدخل العدد المطلوب",
      select_permissions: "اختر الصلاحيات"
    },
    statusOptions: {
      all: "الجميع",
      vacant: "شاغرة",
      occupied: "مشغولة",
      active: "مفعل",
      in_active: " معطل"
    },
    chips: {
      vacant: "شاغرة",
      occupied: "مشغولة",
      active: "مفعل",
      in_active: "معطل",
      count: "المتاح لشغل وظيفة: ",
      remaining_number: "العدد المتبقي :"
    }
  }
};
