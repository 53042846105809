export default {
  serviceFeesRecord: {
    heading: {
      serviceFeesRecord: "سجل بيانات رسوم خدمات"
    },
    labels: {
      commercial_record: "السجل التجاري / رقم الوثيقة",
      service_name: "اسم الخدمة",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      service_status: "حالة الخدمة"
    },
    placeholder: {
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      select_service_name: "اختر اسم الخدمة",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_service_status: "اختر حالة الخدمة"
    },
    table: {
      "#": "#",
      facility_name: "اسم المنشأة",
      commercial_record: "السجل التجاري/الوثيقة",
      service_name: "اسم الخدمة",
      service_fees_value: "قيمة رسوم الخدمة",
      service_status: "حالة الخدمة",
      date: "التاريخ",
      no_data: "لا توجد نتائج متاحة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    buttons: {
      export: "تصدير",
      client_mechanism_transfer: "تحويل ألية العميل",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة"
    },
    tooltip: {
      view_details: "عرض"
    },
    chips: {
      deducted: "تم استقطاعها",
      un_deducted: "لم تستقطع"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },
    serviceOptions: {
      commercial_account_creation_fees:
        "خدمة اشتراك انشاء حساب لعميل سجل تجاري",
      document_account_creation_fees: "خدمة اشتراك انشاء حساب لعميل وثيقة",
      commercial_account_change_data_fees: "خدمة تعديل بيانات لعميل سجل تجاري",
      commercial_account_reactivate_fees:
        "خدمة إعادة تنشيط حساب لعميل سجل تجاري",
      document_account_reactivate_fees: "خدمة إعادة تنشيط حساب لعميل وثيقة"
    },
    statusOptions: {
      all: "الجميع",
      deducted: "تم استقطاعة",
      un_deducted: "لم تستقطع"
    }
  }
};
