export default {
  profile: {
    buttons: {
      login: "تسجيل دخول",
      back: "عودة",
      next: "التالي",
      save: "حفظ",
      cancel: " إلغاء",
      changePassword: "تغير كلمة المرور",
      confirm: "تأكيد",
    },
    fields: {
      phone: "رقم الجوال",
      identity_number: "رقم الهوية",
      email: "البريد الإلكتروني",
      username: "رقم الهوية",
      image: "الصورة الشخصية",
      new_password: "كلمة المرور الجديدة",
      old_password: "كلمة المرور القديمة",
      confirm_password: "تأكيد كلمة المرور الجديدة",
      code: "كود التحقق",
    },
    popup: {
      body: {
        changePassword: "تغير كلمة المرور",
      },
    },
    labels: {
      phone: "رقم الجوال",
      identity_number: "رقم الهوية",
      email: "البريد الإلكتروني",
      image: "الصورة الشخصية",
      remember: "تذكرني",
      reset_password: "إعادة تعيين كلمة المرور",
      resend_code: "إعادة إرسال كود التحقق",
      new_password: "كلمة المرور الجديدة",
      old_password: "كلمة المرور القديمة",
      confirm_password: "تأكيد كلمة المرور الجديدة",
    },
    heading: {
      title: "تسجيل الدخول",
      sub_title: "فضلا ادخل رقم الهوية الخاص بك ",
      please_enter_code: "فضلا ادخل كود التحقق المرسل علي الجوال",
    },
    placeholder: {
      phone: "ادخل رقم الجوال",
      identity_number: "ادخل رقم الهوية",
      email: "ادخل البريد الإلكتروني",
      image: "ادخل الصورة الشخصية",
      new_password: "ادخل المرور الجديدة",
      old_password: "ادخل المرور القديمة",
      confirm_password: "تأكيد كلمة المرور الجديدة",
    },
    validation: {
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة  ",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      mimes: "{field} يجب ان تكون  {mimes}",
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} لا تقل عن {min} أحرف و أرقام",
      confirmed: "كلمة المرور غير متطابقة",
      email: "البريد الإلكتروني غير صحيح",
    },
  },
};
