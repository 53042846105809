<template>
  <v-tooltip color="transparent" left>
    <template #activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on" />
    </template>
    <v-card max-width="400" min-height="200" class="pa-2">
      <v-img :src="imagePath" contain />
    </v-card>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ImagePreviewer',
  props: {
    imagePath: {
      type: String,
      default: '',
    },
  },
}
</script>
