export const electronicDelegationsRoutes = [
  {
    path: "electronicDelegations",
    to: "/vendors/electronicDelegations",
    meta: {
      title: "electronicDelegations",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.electronic_request",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/electronicDelegations/record"),
    allow_children: false
  },
  {
    path: "/vendors/electronicDelegations/:id/view",
    meta: {
      title: "electronicDelegations_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.electronic_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/electronicDelegations"
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/electronicDelegations/view"),
    allow_children: false
  }
];
