export default {
  login: {
    button: {
      login: "تسجيل دخول",
      back: "عودة",
      next: "التالي",
      save: "حفظ",
      cancel: " إلغاء",
    },
    fields: {
      username: "رقم الهوية",
      password: "كلمة المرور",
      code: "كود التحقق",
    },
    labels: {
      username: "رقم الهوية",
      password: "كلمة المرور",
      remember: "تذكرني",
      reset_password: "إعادة تعيين كلمة المرور",
      resend_code: "إعادة إرسال كود التحقق",
      new_password: "كلمة المرور الجديدة",
      confirm_password: "تأكيد كلمة المرور الجديدة",
    },
    heading: {
      title: "تسجيل الدخول",
      sub_title: "فضلا ادخل رقم الهوية الخاص بك ",
      please_enter_code: "فضلا ادخل كود التحقق المرسل علي الجوال",
    },
    placeholder: {
      title: "تسجيل الدخول",
      sub_title: "من فضلك أدخل رقم الجوال او البريد الإلكتروني وكلمة المرور ",
      username: "أدخل رقم الهوية",
      enter_password: "أدخل كلمة المرور",
      enter_area: "أدخل المنطقة",
      enter_confirm_password:"أدخل تأكيد كلمة المرور"
    },
    validation: {
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة  ",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} يجب ان لا تقل عن {min} أحرف و أرقام",
      confirmed: "كلمة المرور غير متطابقة",
      strongPassword: "كلمة المرور يجب يتكون من ارقام واحرف وعلامات خاصة",
    },
  },
};
