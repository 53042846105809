export default {
  delegation: {
    button: {
      next: "التالي",
      save: "حفظ",
      cancel: " إلغاء",
    },
    fields: {},

    labels: {
      delegation_type: "التفويض",
      delegation_doc_number: "رقم وثيقة التفويض",
      facility_membership_number: "رقم عضوية المنشأة",
      delegator_identity_number: "رقم هوية المفوض",
      delegate_to_identity_number: "رقم هوية المفوض له",
      delegator_name: "اسم المفوض",
      delegator_phone: "جوال المفوض",
      delegate_to_name: "اسم المفوض له",
      delegate_to_phone: "جوال المفوض له",
      delegate_to_birthdate: "تاريخ ميلاد المفوض له",
      delegation_end_date: "تاريخ انتهاء التفويض",
      delegation_form_number: "رقم نموذج التفويض",
      facility_name: "اسم المنشأة",
      document_number: "رقم الوثيقة",
      delegation_content: "مضمون التفويض",
      commercial_number: " رقم السجل التجاري ",
      vendor_name: "اسم العميل",
      account_type: "نوع الحساب",
      vendor_type: "نوع العميل",
    },
    heading: {},
    options: {
      certified: "تفويض معتمد",
      electronic: "تفويض الإلكتروني",
      form: "نموذج التفويض",
      document_account: "حساب وثيقة",
      profession_practice_document: "وثيقة مزاولة مهنة",
      all: "الجميع",
    },
    placeholder: {
      choose_delegation_type: "اختر نوع التفويض",
      enter_delegation_doc_number: "ادخل رقم وثيقة التفويض",
      enter_facility_membership_number: "ادخل رقم عضوية االمنشأة",
      enter_delegator_identity_number: "ادخل رقم هوية المفوض",
      enter_delegate_to_identity_number: "ادخل رقم هوية المفوض له",
      enter_delegator_name: "ادخل اسم المفوض",
      enter_delegator_phone: "أدخل جوال المفوض",
      enter_delegate_to_name: "ادخل اسم المفوض له",
      enter_delegate_to_phone: "ادخل جوال المفوض له (5xxxxxxxx)",
      enter_delegation_form_number: "أدخل رقم نموذج التوفيض",
      enter_facility_name: "ادخل اسم المنشأة",
      enter_document_number: "أدخل رقم الوثيقة",
      enter_delegation_content: "أدخل مضمون التفويض",
      enter_commercial_number: "أدخل رقم السجل",
      enter_vendor_name: "أدخل اسم العميل",
      select_account_type: "اختر نوع الحساب",
      choose_vendor_type: "اختر نوع العميل",
    },
    validation: {
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة  ",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      required: "هذا الحقل مطلوب ",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} لا تقل عن {min} أحرف و أرقام",
      confirmed: "كلمة المرور غير متطابقة",
    },
  },
};
