import Vue from "vue";
import VueI18n from "vue-i18n";

import arLocale from "./ar.js";

// import Cookies from "js-cookie";

Vue.use(VueI18n);
export const messages = {
  // en: {
  //   ...enLocale
  // },
  ar: {
    ...arLocale
  }
};

const i18n = new VueI18n({
  // set locale
  // options: en | ar
  // locale: Cookies.get("language") || "ar",
  locale: "ar",
  sync: true,
  dateTimeFormats: "ar",
  fallbackLocale: "ar",
  locales: ["ar", "en"],
  // set locale messages
  messages
});

export default i18n;
