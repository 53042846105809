import { sadadServiceRoutes } from "./settings/sadadService";
import { paymentSettingsRoutes } from "./settings/paymentSettings";
import { financialSettlementRecordRoutes } from "./financialSettlements/financialSettlementRecord";
import { financialSettlementTransferSettingsRoutes } from "./financialSettlements/financialSettlementTransferSettings";
import { serviceFeesRecordRoutes } from "./financialSettlements/serviceFeesRecord";
import { settlementsDurationRecordRoutes } from "./financialSettlements/settlementsDurationRecord";
import { successfulTransactionsRoutes } from "./transactions/successfulTransactions";
import { failedTransactionsRoutes } from "./transactions/failedTransactions";
import { consumersDataRoutes } from "./transactions/consumersData";
import { exceedFixedPercentRecordRoutes } from "./settings/exceedFixedPercentRecord";
import { exceedExceptionalPercentRecordRoutes } from "./settings/exceedExceptionalPercentRecord";
import { settlementsTransactionsRoutes } from "./financialSettlements/financialSettlementRecord/settlementsTransactions";

export const transactionsRoutes = [
  {
    path: "/transactions",
    to: "/transactions",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "transactions",
      permission: "transactions_management"
    },
    component: () => import("@/views/transactions/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",
        meta: {
          title: "transactions",
          permission: "transactions_management",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.transactions"
            }
          ]
        },
        component: () => import("@/views/transactions")
      },
      ...sadadServiceRoutes,
      ...paymentSettingsRoutes,
      ...financialSettlementRecordRoutes,
      ...financialSettlementTransferSettingsRoutes,
      ...serviceFeesRecordRoutes,
      ...settlementsDurationRecordRoutes,
      ...successfulTransactionsRoutes,
      ...failedTransactionsRoutes,
      ...consumersDataRoutes,
      ...exceedFixedPercentRecordRoutes,
      ...exceedExceptionalPercentRecordRoutes,
      ...settlementsTransactionsRoutes
    ]
  }
];
