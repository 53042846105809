export const viewClientsAndLocationRoutes = [
  {
    path: "view_clients_and_location",
    meta: {
      title: "view_clients_and_location",
      permission: "addresses_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.areas",
          link: true,
          exact: true,
          to: "/areas"
        },
        {
          text: "dashboard.sidebar.view_clients_and_location",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/areas/view_clients_and_location/record")
  }
];
