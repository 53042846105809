export default {
  exceedTransactions: {
    heading: {
      dangerTransactions: "معاملات تمثل خطورة (إضافة/ اعتماد تقرير)",
      exceedTransactions: "معاملات تعدت الحد الأقصي",
      view_exceed_transactions: "عرض",
      add_exceed_transactions: "إضافة تقرير",
      historical_movement: "الحركة التاريخية",
      vendorTransaction_view: "عرض المعاملات الكلية للعميل",
    },

    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        saveText: "هل تريد إتمام عملية الحفظ ؟",
        refuseTitle: "هل تريد إتمام عملية الرفض ؟",
        reasonLabel: "سبب الرفض",
        reviewTitle: "طلب إعادة المراجعة",
      },
    },

    table: {
      "#": "#",
      vendor_name: "اسم العميل",
      phone: "رقم الجوال",
      commercial_number: "السجل التجاري/ الوثيقة",
      expected_transactions: "المعاملات المتوقعه",
      actual_transactions: "المعاملات الفعلية",
      date: "التاريخ",
      status: "الحالة",
      transaction_number: "رقم المعاملة",
      actual_value: "القيمة الفعلية",
      actions: "العمليات",
      total: "الإجمالي",
      type: "النوع",
      added_by: "تم بواسطة",
      department_name: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      seller_name: "البائع",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      view_details: "عرض",
      add_report: "إضافة تقرير",
      refuse: "رفض",
      confirm: "إعتماد",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق",
      accept: "موافق",
    },
    labels: {
      show_all_transactions: "عرض جميع المعاملات",
      use_system_degree: " درجة خطورة النظام",
      vendor_name: "اسم العميل",
      commercial_number: "السجل التجاري / رقم الوثيقة",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      status: "الحالة",
      tax_number: "الرقم الضريبي",
      phone: "رقم الجوال",
      danger_degree: "درجة الخطورة",
      responsible_opinion: "رأي المسئول",
      daily_expected_transactions_value: "قيمة المبيعات المتوقعة للعميل",
      expected_transactions: "المعاملات المتوقعه",
      actual_transactions: "المعاملات الفعلية",
      risk_percent: "نسبة الخطورة",
      type: "النوع",
      degree_severity: "درجة الخطورة",
      vendor_expected_value: "قيمة المبيعات المتوقعة للعميل",
      vendor_actual_value: "قيمة المبيعات الفعلية للعميل",
      vendor_opinion: "رأي المسئول",
      reason: "السبب",
      review_management: "الإدارة المراجعة",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_number: "ادخل السجل التجاري او رقم الوثيقة",
      choose_date_from: "اختر الفترة من",
      choose_date_to: "اختر الفترة إلي",
      choose_status: "اختر الحالة",
      enter_tax_number: "ادخل الرقم الضريبي",
      enter_phone: "ادخل رقم جوال العميل (5xxxxxxxx)",
      enter_danger_degree: "ادخل درجة الخطورة",
      enter_risk_percent: "ادخل نسبة الخطورة",
      choose_type: "اختر النوع",
      select_degree_severity: "اختر درجة الخطورة",
      enter_responsible_opinion: "أدخل رأي المسئول ",
      enter_reason: "أدخل السبب",
      select_review_management: "اختر الإدارة المراجعة",
    },
    statusOptions: {
      all: "الجميع",
      reviewed: "تم مراجعتها",
      accepted: "تم الموافقه",
      rejected: "تم الرفض",
      pending: "تمت المراجعه",
      waiting_review: "انتظار المراجعة",
      failed: "فشل",
      exceed_daily: "تعدت الحد الأقصي يوميا",
      exceed_monthly: "تعدت الحد الأقصي شهريا",
      exceed_yearly: "تعدت الحد الأقصي سنويا",
      success: "تم مراجعتها",
      not_completed: "انتظار المراجعة",
      exceed_transaction: "تعدت الحد الاقصى للمعاملة الواحدة",
      legal: "اعتماد القانونية",
      money_laundry: "اعتماد المخاطر",
    },
    chips: {
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة",
      failed: "فشل",
    },
  },
};
