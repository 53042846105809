export default {
  vipVendors: {
    buttons: {
      save: "حفظ",
      back: "عودة"
    },
    placeholder: {
      add_value: "أضف القيمة"
    },
    labels: {
      expected_sales_value: "قيمة المبيعات المتوقعة ",
      daily_sales: "يوميا",
      monthly_sales: "شهريا",
      three_months_sales: "مبيعات 3 أشهر",
      six_months_sales: "مبيعات 6 أشهر",
      yearly_sales: "مبيعات سنه"
    },

    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟"
      }
    },
    fields: {
      daily_sales: "يوميا",
      monthly_sales: "شهريا",
      three_months_sales: "مبيعات 3 أشهر",
      six_months_sales: "مبيعات 6 أشهر",
      yearly_sales: "سنويا "
    },
    validation: {
      required: "هذا الحقل مطلوب",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} هذا الحقل يجيب ان يكون علي الاقل {min} من الحروف"
    }
  }
};
