export const suspiciousIndicatorSettingsRoutes = [
  {
    path: "suspicious_indicator_settings",
    to: "/money-laundering/suspicious_indicator_settings",
    meta: {
      title: "suspicious_indicator_settings",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/suspiciousIndicatorSettings/record"),
    allow_children: false
  },
  {
    path: "/money-laundering/suspicious_indicator_settings/add",
    meta: {
      title: "suspicious_indicator_settings_add",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings",
          exact: true,
          link: true,
          to: "/money-laundering/suspicious_indicator_settings"
        },
        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/suspiciousIndicatorSettings/crud"),
    allow_children: false
  },
  {
    path: "suspicious_indicator_settings/:id/edit",
    meta: {
      title: "suspicious_indicator_settings_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings",
          exact: true,
          link: true,
          to: "/money-laundering/suspicious_indicator_settings"
        },
        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/suspiciousIndicatorSettings/crud"),
    allow_children: false
  },
  {
    path: "suspicious_indicator_settings/:id/view",
    meta: {
      title: "suspicious_indicator_settings_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings",
          exact: true,
          link: true,
          to: "/money-laundering/suspicious_indicator_settings"
        },
        {
          text: "dashboard.moneyLaundering.suspiciousSndicatorSettings.heading.suspicious_indicator_settings_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/suspiciousIndicatorSettings/view"),
    allow_children: false
  }
];
