import { severitySettingsRoutes } from "./severitySettings";
import { suspiciousIndicatorSettingsRoutes } from "./suspiciousSndicatorSettings";
import { ProjectedSalesActivityAnnuallyRoutes } from "./ProjectedSalesActivityAnnually";
import { severitySettingsNationalitiesRoutes } from "./severitySettingsNationalities";
import { severitySettingsAreasRoutes } from "./severitySettingsAreas";
import { dailyTransactionsRoutes } from "./dailyTransactions";
import { exceedTransactionsRoutes } from "./dangerTransactions/exceedTransactions";
import { reportsExceedTransactionsRoutes } from "./reports/exceedTransactions";
import { reportsSuspectedTransactionsRoutes } from "./reports/suspectedTransactions";
import { reportsSuspectedAccountsRequestRoutes } from "./reports/suspectedAccountsRequest";
import { suspectedTransactionsRoutes } from "./dangerTransactions/suspectedTransactions";
import { suspectedAccountsRequestRoutes } from "./dangerTransactions/suspectedAccountsRequest";
import { risk_ratio_settings_routes } from "./risk_ratio_settings";
import { activitySeveritySettingsRoutes } from "./activitySeveritySettings";
import { vendorTransactionsRoutes } from "./vendorTransactions";
import { vendorTransactionsRequestsRoutes } from "./vendorTransactionsRequests";
import { riskVendorsRoutes } from "./riskVendors";

export const moneyLaunderingRoutes = [
  {
    path: "/money-laundering",
    to: "/money-laundering",
    icon: "mdi-wallet",
    meta: {
      title: "money_laundering",
      permission: "money_laundering",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",

        meta: {
          title: "money_laundering",
          permission: "money_laundering",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.money_laundering",
              disabled: false
            }
          ]
        },
        component: () => import("@/views/moneyLaundering")
      },
      ...severitySettingsRoutes,
      ...suspiciousIndicatorSettingsRoutes,
      ...ProjectedSalesActivityAnnuallyRoutes,
      ...severitySettingsNationalitiesRoutes,
      ...severitySettingsAreasRoutes,
      ...dailyTransactionsRoutes,
      ...exceedTransactionsRoutes,
      ...reportsExceedTransactionsRoutes,
      ...reportsSuspectedTransactionsRoutes,
      ...reportsSuspectedAccountsRequestRoutes,
      ...suspectedTransactionsRoutes,
      ...suspectedAccountsRequestRoutes,
      ...risk_ratio_settings_routes,
      ...activitySeveritySettingsRoutes,
      ...vendorTransactionsRoutes,
      ...vendorTransactionsRequestsRoutes,
      ...riskVendorsRoutes
    ]
  }
];
