export const vendorAccountRequestRoutes = [
  {
    path: "revisionRequest",
    to: "/vendors/revisionRequest",
    meta: {
      title: "revisionRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.revision_request",
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/vendors/revisionRequest/record"),
    allow_children: false,
  },
  {
    path: "revisionRequest/:id/view",
    meta: {
      title: "viewRevisionRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.revision_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/revisionRequest",
        },
        {
          text: "dashboard.vendors.heading.viewRevisionRequest",
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/vendors/revisionRequest/view"),
    allow_children: false,
  },
  {
    path: "accountRegistrationRequest",
    to: "/vendors/accountRegistrationRequest",
    meta: {
      title: "accountRegistrationRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.account_registration_request",
          disabled: true,
        },
      ],
    },
    component: () =>
      import("@/views/vendors/accountRegistrationRequest/record"),
    allow_children: false,
  },
  {
    path: "accountRegistrationRequest/:id/view",
    meta: {
      title: "editRevisionRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.account_registration_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/revisionRequest",
        },
        {
          text: "dashboard.vendors.heading.editRevisionRequest",
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/vendors/accountRegistrationRequest/view"),
    allow_children: false,
  },
  {
    path: "refusedRequest",
    to: "/vendors/refusedRequest",
    meta: {
      title: "refusedRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.refused_canceled_request",
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/vendors/refusedRequest/record"),
    allow_children: false,
  },
  {
    path: "refusedRequest/:id/view",
    meta: {
      title: "editRevisionRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors",
        },
        {
          text: "dashboard.vendors.cards.refused_canceled_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/refusedRequest",
        },
        {
          text: "dashboard.vendors.heading.viewRevisionRequest",
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/vendors/refusedRequest/view"),
    allow_children: false,
  },

  // {
  //   path: "refusedRequest",
  //   to: "/vendors/refusedRequest",
  //   meta: {
  //     title: "refusedRequest",
  //     bread_crumb: [
  //       {
  //         text: "dashboard.sidebar.vendors"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.heading.account_request"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.cards.refused_request"),
  //         disabled: true,
  //       },
  //     ],
  //   },
  //   component: () => import("@/views/vendors/refusedRequest/record"),
  //   allow_children: false,
  // },
  // {
  //   path: "refusedRequest/:id/view",
  //   meta: {
  //     title: "editRevisionRequest",
  //     bread_crumb: [
  //       {
  //         text: "dashboard.sidebar.vendors"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.heading.account_request"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.cards.refused_request"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors/revisionRequest",
  //       },
  //       {
  //         text: "dashboard.vendors.heading.editRevisionRequest"),
  //         disabled: true,
  //       },
  //     ],
  //   },
  //   component: () => import("@/views/vendors/refusedRequest/view"),
  //   allow_children: false,
  // },

  // {
  //   path: "accountCancelledRequest",
  //   to: "/vendors/accountCancelledRequest",
  //   meta: {
  //     title: "account_cancelled_request",
  //     bread_crumb: [
  //       {
  //         text: "dashboard.sidebar.vendors"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.heading.account_request"),
  //         disabled: false,
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },
  //       {
  //         text: "dashboard.vendors.cards.account_cancelled_request"),
  //         disabled: true,
  //       },
  //     ],
  //   },
  //   component: () => import("@/views/vendors/accountCancelledRequest/record"),
  //   allow_children: false,
  // },
];
