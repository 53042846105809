import exceedTransactions from "./exceedTransactions";
import suspectedTransactions from "./suspectedTransactions";
import suspectedAccountsRequest from "./suspectedAccountsRequest";
import suspectedEmployeesRequest from "./suspectedEmployeesRequest";

export default {
  reports: {
    // START:: HEADING
    heading: {
      title: "طلبات إنشاء حساب تمثل خطورة (إضافة/ اعتماد تقرير)",
      exceedTransactions: "معاملات تعدت الحد الأقصي",
      suspectedTransactions: "معاملات بها مؤشر اشتباه",
      suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
      suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
      view: "عرض"
    },
    // START:: CARDS
    cards: {
      exceedTransactions: "طلبات مبيعات تعدت الحد الأقصي للنشاط سنويا",
      suspectedTransactions: "معاملات بها مؤشر اشتباه",
      suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
      suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
      jobRequests: "طلبات توظيف من رصيد جاك"
    },
    // START:: BUTTONS
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
      accept: "قبول",
      refuse: "رفض"
    },
    buttons: {
      cancel: "إلغاء",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      search: "بحث",
      reset: "عرض الكل",
      edit: "تعديل"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        refuse: "هل متأكد من رفض انشاء حساب للعميل ؟",
        accept: "هل متأكد من السماح بإنشاء حساب ؟"
      }
    },
    chips: {
      all: "الجميع",
      accept: "مقبولة",
      refuse: "مرفوضة",
      pending: "انتظار المراجعة",
      nationality_risk: "جنسية ذات خطورة",
      region_risk: "منطقة ذات خطورة",
      black_list: "مشتبه به"
    },
    // START:: Exceed Transactions
    ...exceedTransactions,
    // START:: Suspected Transactions
    ...suspectedTransactions,
    // START:: Suspected Accounts Request
    ...suspectedAccountsRequest,
    // START:: Suspected Employees Request
    ...suspectedEmployeesRequest
  }
};
