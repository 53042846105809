export default {
  exceedFixedPercentRecord: {
    heading: {
      exceed_fixed_percentage_add: "إضافة نسبة",
      exceed_fixed_percentage_edit: "تعديل نسبة",
      exceed_fixed_percentage_view: "عرض نسبة",
      historical_movment: "الحركه التاريخية"
    },
    labels: {
      rate: "النسبة",
      max_yearly_amount: "النسبة السنوية",
      max_daily_amount: "النسبة اليومية"
    },
    placeholder: {
      enter_rate: "أدخل النسبة"
    },
    table: {
      "#": "#",
      percent: "النسبة",
      creating_date: "تاريخ الإنشاء",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      no_data: "لا توجد نتائج متاحة",
      max_yearly_amount: "النسبة السنوية",
      max_daily_amount: "النسبة اليومية",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      add_rate: "إضافة نسبة"
    },
    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف"
    },
    chips: {
      send_done: "تم الإرسال",
      send_fail: "فشل الإرسال"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },

    statusOptions: {
      all: "الجميع"
    }
  }
};
