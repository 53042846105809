export default {
  documentTypes: {
    fields: {
      name: "الاسم",
      job_title: "المسمي الوظيفي",
      document_type: "توع الوثيقة",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    heading: {
      add: "إضافة",
      edit: "تعديل",
      document_types: "سجل مهن الوثيقه",
      view_documentTypes: "عرض",
      historical_movment: "الحركة التاريخية",
    },
    popup: {
      buttons: {
        save: "حفظ",
        cancel: "إلغاء",
        refuse: "غير موافق",
        accept: "موافق",
      },
      body: {
        reasonLabel: "السبب",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        vipAddTitle: "إضافة تصنيف كبار العملاء",
        vipVendorAddLabel1: "سيتم تصنيف المنشأه",
        vipVendorAddLabel2: "السجل التجاري / رقم الوثيقة",
        vipCancelTitle: "إلغاء تصنيف كبار العملاء",
        vipVendorCancelLabel1: "سيتم إلغاء تصنيف المنشأه",
        vipVendorCancelLabel2: "السجل التجاري / رقم الوثيقة",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        page_has_client:
          "لا يمكن حذف مسمي مهني مربوط بعميل مفعل او لديه طلب لم يتم اعتماده",
      },
      reasonValidation: "السبب",
    },

    table: {
      "#": "#",
      document_type: "نوع الوثيقة",
      job_title: "المسمي المهني",
      actions: "العمليات",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
      status: "الحالة",
      added_by: "تم بواسطة",
      employee_job: "الوظيفة",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
    },
    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      add_document_type: "إضافة مسمي مهني ",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
    },
    labels: {
      document_type: "نوع الوثيقة",
      job_title: "المسمي المهني",
      status: "الحالة",
    },
    placeholder: {
      document_type: "اختر نوع الوثيقة",
      job_title: "ادخل المسمي المهني",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
      free_work_document: "وثيقة عمل حر",
      profession_practice_document: "وثيقة مزاولة مهنة",
    },
  },
};
