export const ProjectedSalesActivityAnnuallyRoutes = [
  {
    path: "Projected_sales_activity_annually",
    to: "/money-laundering/Projected_sales_activity_annually",
    meta: {
      title: "Projected_sales_activity_annually",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.cards.Projected_sales_activity_severity_settings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/ProjectedSalesActivityAnnually/record"),
    allow_children: false
  },
  {
    path: "/money-laundering/Projected_sales_activity_annually/add",
    meta: {
      title: "Projected_sales_activity_annually_add",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.cards.Projected_sales_activity_severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/Projected_sales_activity_annually"
        },
        {
          text: "dashboard.moneyLaundering.ProjectedSalesActivityAnnually.heading.Projected_sales_activity_annually_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/ProjectedSalesActivityAnnually/crud"),
    allow_children: false
  },
  {
    path: "Projected_sales_activity_annually/:id/edit",
    meta: {
      title: "Projected_sales_activity_annually_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.cards.Projected_sales_activity_severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/Projected_sales_activity_annually"
        },
        {
          text: "dashboard.moneyLaundering.ProjectedSalesActivityAnnually.heading.Projected_sales_activity_annually_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/ProjectedSalesActivityAnnually/crud"),
    allow_children: false
  },
  {
    path: "Projected_sales_activity_annually/:id/view",
    meta: {
      title: "Projected_sales_activity_annually_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.cards.Projected_sales_activity_severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/Projected_sales_activity_annually"
        },
        {
          text: "dashboard.moneyLaundering.ProjectedSalesActivityAnnually.heading.Projected_sales_activity_annually_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/ProjectedSalesActivityAnnually/view"),
    allow_children: false
  }
];
