export default {
  exceedTransactions: {
    // START:: HEADING
    heading: {
      historical_movment: "الحركة التاريخية",
    },
    // START:: LABELS
    labels: {
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      vendor_phone: "رقم الجوال",
      vendor_identity: "رقم الهوية",
      vendor_comment: "رأي المسؤل",
      vendor_name: "اسم العميل",
      tax_number: "الرقم الضريبي",
      date_from: "الفترة من",
      date_to: "الفترة الي",
      risk_percent: "نسبة الخطورة",
      responsable_risk_percent: "نسبة الخطورة المضافة من المسئول",
      identity_number: "رقم الهوية",
      activity: "النشاط",
      expected_daily_transaction: "المبيعات المتوقعة اليومية",
      expected_monthly_transaction: "المبيعات المتوقعة الشهرية",
      expected_yearly_transaction: "المبيعات المتوقعة السنوية",
      activity_yearly_transactions: "المبيعات السنوية للنشاط",
      vendor_expected_yearly_transactions: "المبيعات السنويةالمتوقعة للعميل",
    },
    // START:: PLACEHOLDER
    placeholder: {
      facility_name: "أدخل اسم المنشأة",
      commercial_registration_document_no: "أدخل السجل التجاري / رقم الوثيقة",
      date_from: "أدخل الفترة من",
      date_to: "أدخل الفترة الي",
      degree_of_risk: "اختر درجة الخطورة",
      risk_percent: "أدخل نسبة الخطورة",
      select_activity: "اختر النشاط",
      enter_vendor_name: "أدخل اسم العميل",
      enter_identity_number: "أدخل رقم الهوية",
      enter_vendor_expected_yearly_transactions: "أدخل القيمة",
    },
    // START:: TABLE
    table: {
      "#": "#",
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      degree_of_risk: "درجة الخطورة",
      risk_percent: "نسبة الخطورة",
      date: "التاريخ",
      vendor_name: "اسم العميل",
      vendor_phone: "رقم الجوال",
      vendor_identity: "رقم الهوية",
      activity: "النشاط",
      expected_yearly_business: "المبيعات المتوقعة السنوية للنشاط",
      vendor_yearly_transactions: "المبيعات السنوية للعميل",
      facility_profession_name: "اسم المنشأة/المسمي المهني",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق",
    },
    chips: {
      no_danger: "لا يوجد خطورة",
    },
  },
};
