<template>
  <div>
    <SideBar />
    <v-container>
      <router-view />
    </v-container>
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: { SideBar: () => import("@/components/SideBar") }
};
</script>
