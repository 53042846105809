import { image } from "vee-validate/dist/rules";
import Vue from "vue";
import { mapGetters } from "vuex";
import { hasIn } from "lodash";
export const globalMixin = {
  computed: {
    ...mapGetters({
      getPermissions: "getPermissions",
      pagination: "getPagination",
      get_programs: "get_programs",
      get_abilities: "get_abilities",
      user: "getUser",
      getQuery: "getQuery"
    }),
    serverErrors() {
      return this.$store.getters.serverErrors;
    },
    routeHashed() {
      if (this.$route.hash === "record") {
        return true;
      } else {
        return false;
      }
    },
    activityTypes() {
      return {
        created: {
          color: "indigo",
          text: "created"
        },
        updated: {
          color: "orange",
          text: "updated"
        }
      };
    }
  },
  data() {
    return {
      timeStamp: null
    };
  },
  methods: {
    hasPermissions(per_key) {
      if (this.get_abilities.includes("*")) {
        return true;
      } else {
        return this.get_abilities.includes(per_key);
      }
    },
    hasProgramPermission(...permission) {
      if (this.get_programs.length > 0 && this.get_programs.includes("*")) {
        return true;
      } else {
        return hasIn(this.get_programs, permission);
      }
    },
    async handleValidateInput(formData, refField) {
      const isValid = await this.$refs[refField].isValid();
      if (this.serverErrors) {
        this.$store.dispatch("ClearServerErrors");
      }
      if (isValid) {
        return this.$http.post({ reqName: `validate`, data: formData });
      }
    },

    toggleDialog() {
      this.$eventBus.$emit("toggle-dialog");
    },
    handleDebounce(callback, args) {
      if (this.timStamp) {
        clearTimeout(this.timStamp);
      }
      this.timStamp = setTimeout(() => {
        callback(args);
      }, 500);
    },

    handleRedirect(recordPage = "", viewPage = "") {
      if (this.$route.hash === "#record") {
        if (recordPage) {
          this.$router.push(recordPage);
        } else {
          this.$router.push(this.redirect);
        }
      } else if (this.$route.hash === "#view") {
        if (!viewPage) {
          this.$router.go(-1);
        } else {
          this.$router.replace({ path: viewPage, query: {} });
        }
      } else {
        this.$router.push("/home");
      }
    },
    popUp(
      title = this.$t("dashboard.message.warning"),
      type = "question",
      singleButton = false
    ) {
      const swal = this.$swal.fire({
        title,
        type,
        showCloseButton: false,
        showCancelButton: !singleButton,
        showConfirmButton: true,
        confirmButtonText: `${this.$t("dashboard.button.confirm")}`,
        cancelButtonText: `${this.$t("dashboard.button.cancel")}`
      });
      return swal;
    }
  },
  filters: {
    maxWordTruncate(value, count = 20) {
      if (value && value.length > count) {
        return value && value.substring(0, count) + "...";
      } else {
        return value;
      }
    }
  }
};

Vue.mixin(globalMixin);
