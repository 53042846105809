import Vue from "vue";
import VueMeta from "vue-meta";
import "./shared";
import "./mixins";
import "./permission";
import "@/assets/scss/main.scss";
import "@/assets/fonts/stylesheet.css";
import "@/api";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);
Vue.use(VueMeta, {
  // optional pluginOptions
  //   refreshOnceOnNavigation: true
});
Vue.prototype.$eventBus = new Vue();
