export const authorizationsVendorRoutes = [
  {
    path: "authorizations",
    to: "/vendors/authorizations",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "authorizations",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.authorizeReport.heading.authorizations",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/authorizations/record"),
    allow_children: false
  },
  {
    path: "authorizations/:id/view",
    meta: {
      title: "view_authorizations",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.authorizeReport.heading.authorizations",
          to: "/vendors/authorizations",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/authorizations/view"),
    allow_children: false
  }
];
