export const dataRecordingRoutes = [
  {
    path: "deductions",
    to: "/settings/commissionsTaxes/deductions",
    meta: {
      title: "deductions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.deductions",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/deductions/record"),
    allow_children: false
  },
  {
    path: "Jack_balance_dues",
    to: "/settings/commissionsTaxes/Jack_balance_dues",
    meta: {
      title: "Jack_balance_dues",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Jack_balance_dues",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/JackBalanceDues/record"),
    allow_children: false
  },
  {
    path: "Jack_balance_fee",
    to: "/settings/commissionsTaxes/Jack_balance_fee",
    meta: {
      title: "Jack_balance_fee",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Jack_balance_fee",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/JackBalanceFee/record"),
    allow_children: false
  },
  {
    path: "added_tax",
    to: "/settings/commissionsTaxes/added_tax",
    meta: {
      title: "added_tax",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.added_tax",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/addedTax/record"),
    allow_children: false
  },
  {
    path: "Payment_services_dues",
    to: "/settings/commissionsTaxes/Payment_services_dues",
    meta: {
      title: "Payment_services_dues",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Payment_services_dues",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/PaymentServicesDues/record"),
    allow_children: false
  },
  {
    path: "Intermediary_bank_receivables_record",
    to: "/settings/commissionsTaxes/Intermediary_bank_receivables_record",
    meta: {
      title: "Intermediary_bank_receivables_record",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commission_taxes_reports",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Intermediary_bank_receivables_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/settings/commissionsTaxes/IntermediaryBankReceivablesRecord/record"
      ),
    allow_children: false
  }
];
