<template>
  <v-btn v-on="$listeners" v-bind="$attrs" large style="border-radius: 8px">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "Button",
  inheritAttrs: false
};
</script>
