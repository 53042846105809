export default {
  follow_up: {
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },
    table: {
      "#": "#",
      activity_name: "اسم النشاط",
      activity_type: "نوع النشاط",
      employee_name: "اسم الموظف",
      id_number: "الرقم التعريفي",
      ip_number: "رقم معرف الجهاز",
      email: "البريد الالكتروني",
      date_time: "التاريخ و الوقت",
      actions: "العمليات",
      program:"البرنامج",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      view_details: "عرض",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
    },
    labels: {
      activity_name: "اسم النشاط",
      activity_type: "نوع النشاط",
      employee_name: "اسم الموظف",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      activity_details: "تفاصيل النشاط",
      programs: "البرامج",
    },
    placeholder: {
      enter_activity_name: "ادخل اسم النشاط",
      select_activity_type: "اختر نوع النشاط",
      first_name: "الأول",
      second_name: "الثاني",
      third_name: "الثالث",
      fourth_name: "الرابع",
      select_period_from: "اختر الفترة من",
      select_period_to: "اختر الفترة إلي",
      select_program: "اختر البرنامج",
    },
    statusOptions: {
      all: "الجميع",
      vacant: "شاغرة",
      occupied: "مشغولة",
      active: "مفعل",
      in_active: " معطل",
      Unauthorized_browsing_attempts: "محاولات تصفح غير مسموح بها",
      activity_log: "سجل النشاطات",
    },
    chips: {
      vacant: "شاغرة",
      occupied: "مشغولة",
      active: "مفعل",
      in_active: " معطل",
    },
  },
};
