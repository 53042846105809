export default {
  links: {
    heading: {
      links: "الروابط",
    },

    table: {
      "#": "#",
      links: "الروابط",
      staticPage: "الصفحة الثابتة",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },

    buttons: {
      save: "حفظ",
      export: "تصدير",
    },

    placeholder: {
      select_staticPage: "اختر الصفحة الثابتة",
    },
  },
};
