export const exceedExceptionalPercentRecordRoutes = [
  {
    path: "exceed-exceptional-percentage-record",
    to: "/transactions/exceed-exceptional-percentage-record",
    meta: {
      title: "exceed_exceptional_percentage_record",
      permission: "percentage_increases_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.settings",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_exceptional_percentage_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/settings/exceedExceptionalPercentRecord/record"
      ),
    allow_children: false
  },
  {
    path: "exceed-exceptional-percentage-record/:id/view",
    meta: {
      title: "exceed_exceptional_percentage_view",
      permission: "percentage_increases_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_exceptional_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-exceptional-percentage-record"
        },
        {
          text: "dashboard.exceedExceptionalPercentRecord.heading.exceed_exceptional_percentage_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/settings/exceedExceptionalPercentRecord/view"
      ),
    allow_children: false
  },
  {
    path: "exceed-exceptional-percentage-record/:id/edit",
    meta: {
      title: "exceed_exceptional_percentage_edit",
      permission: "percentage_increases_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_exceptional_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-exceptional-percentage-record"
        },
        {
          text: "dashboard.exceedExceptionalPercentRecord.heading.exceed_exceptional_percentage_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/settings/exceedExceptionalPercentRecord/crud"
      ),
    allow_children: false
  },
  {
    path: "exceed-exceptional-percentage-record/add",
    meta: {
      title: "exceed_exceptional_percentage_add",
      permission: "percentage_increases_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_exceptional_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-exceptional-percentage-record"
        },
        {
          text: "dashboard.exceedExceptionalPercentRecord.heading.exceed_exceptional_percentage_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/settings/exceedExceptionalPercentRecord/crud"
      ),
    allow_children: false
  }
];
