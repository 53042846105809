export default {
  ProjectedSalesActivityAnnually: {
    heading: {
      Projected_sales_activity_annually:
        "إعدادات المبيعات المتوقعة للنشاط سنويا",
      Projected_sales_activity_annually_add: "إضافة",
      Projected_sales_activity_annually_edit: "تعديل",
      Projected_sales_activity_annually_view: "عرض",
      historical_movment: "الحركة التاريخية",
    },
    labels: {
      Maximum_sales_activity: "الحد الاقصي للمبيعات في النشاط",
      activity_name: "اسم النشاط",
      activity: "النشاط",
    },
    placeholder: {
      enter_activity: "ادخل النشاط",
      enter_Maximum_sales_activity: "ادخل الحد الاقصي للمبيعات في النشاط",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete_expected_sales: "هل متأكد من حذف قيمة المبيعات المتوقعة",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة",
      edit: "تعديل",
      close: "غلق",
      add_sales: "إضافة خطورة / مبيعات",
    },
    table: {
      "#": "#",
      risk_ratio: "مؤشر الخطورة",
      activity: "اسم النشاط",
      Projected_sales_date_activity: "تاريخ تعيين المبيعات المتوقعة للنشاط",
      Expected_sales_value_per_year: "قيمة المبيعات المتوقعة سنوياً",
      actions: "العمليات",
      total: "الإجمالي",
      Date: "التاريخ",
      added_by: "تم بواسطة",
      created_at: "تاريخ النشاط",
      department: "اسم النشاط",
      description: "النشاط",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
  },
};
