export default {
  activitySeveritySettings: {
    heading: {
      moneyLaunderingSettings: "اعدادات إدارة المخاطر",
      activitySeveritySettings: "اعدادات درجة الخطورة للنشاط",
      add_activitySeveritySettings: "إضافة",
      edit_activitySeveritySettings: "تعديل",
      view_activitySeveritySettings: "عرض",
      historical_movement: "الحركة التاريخية"
    },
    labels: {
      activity_name: "اسم النشاط",
      risk_ratio: "مؤشر الخطورة",
      risk_degree: "درجة الخطورة"
    },
    placeholder: {
      select_activity_name: "اختر اسم النشاط",
      enter_risk_ratio: "أدخل مؤشر الخطورة",
      enter_risk_degree: "ادخل درجة الخطورة"
    },
    table: {
      "#": "#",
      activity_name: "اسم النشاط",
      risk_ratio: "مؤشر الخطورة",
      risk_degree: "درجة الخطورة",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department_name: "اسم القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },

    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف"
    },
    buttons: {
      export: "تصدير",
      add_activity: "إضافة نشاط",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق"
    },

    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },
    statusOptions: {
      all: "الجميع",
      in_active: "معطل",
      active: "مفعل"
    },
    chips: {
      no_danger: "لا يوجد خطورة"
    }
  }
};
