<template>
  <v-tooltip top :color="color" max-width="250">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="showText && on" ref="mySlot">
        <slot />
      </span>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "TruncateTooltip",
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    showText: {
      type: Boolean,
      default: true,
    },
    // truncate: {
    //   type: Number,
    //   default: 10,
    // },
  },

  // methods: {
  //   truncateData() {
  //     let TextContent =
  //       this.$refs.mySlot.getElementsByClassName("truncateText")[0].innerHTML;
  //     const TextLength = TextContent.length;

  //     if (TextLength > this.truncate) {
  //       const substringData = TextContent.substring(0, this.truncate) + "...";
  //       this.$refs.mySlot.getElementsByClassName("truncateText")[0].innerHTML =
  //         substringData;
  //     }
  //   },
  // },

  // mounted() {
  //   this.truncateData();
  // },
};
</script>
