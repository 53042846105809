export const staticPagesRoutes = [
  {
    path: "staticPages",
    to: "/settings/staticPages",
    meta: {
      title: "staticPages",
      permission: "static_pages_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.staticPages.heading.staticPages",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/staticPages/record"),
    allow_children: false
  },

  {
    path: "staticPages/add",
    meta: {
      title: "add_staticPages",
      permission: "static_pages_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.staticPages.heading.staticPages",
          exact: true,
          link: true,
          to: "/settings/staticPages"
        },
        {
          text: "dashboard.staticPages.heading.add_staticPages",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/staticPages/crud"),
    allow_children: false
  },
  {
    path: "staticPages/:id/view",
    meta: {
      title: "view_staticPages",
      permission: "static_pages_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.staticPages.heading.staticPages",
          exact: true,
          link: true,
          to: "/settings/staticPages"
        },
        {
          text: "dashboard.staticPages.heading.view_staticPages",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/staticPages/view"),
    allow_children: false
  },
  {
    path: "staticPages/:id/edit",
    meta: {
      title: "edit_staticPages",
      permission: "static_pages_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.staticPages.heading.staticPages",
          exact: true,
          link: true,
          to: "/settings/staticPages"
        },
        {
          text: "dashboard.staticPages.heading.edit_staticPages",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/staticPages/crud"),
    allow_children: false
  }
];
