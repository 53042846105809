export const employeesRoutes = [
  {
    path: "employees",
    to: "/employeesManagement/employees",
    meta: {
      title: "employees",
      permission: "employees_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },
        {
          text: "dashboard.employees.heading.employees",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/employees/record"),
    allow_children: false
  },

  {
    path: "employees/add",
    meta: {
      title: "add_employee",
      permission: "employees_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.employees.heading.employees",
          to: "/employeesManagement/employees",
          link: true,
          exact: true
        },
        {
          text: "dashboard.employees.heading.add_employee",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/employees/crud"),
    allow_children: false
  },

  {
    path: "employees/:id/view",
    meta: {
      title: "view_employee",
      permission: "employees_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.employees.heading.employees",
          to: "/employeesManagement/employees",
          link: true,
          exact: true
        },
        {
          text: "dashboard.employees.heading.view_employee",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/employees/view"),
    allow_children: false
  },
  {
    path: "employees/:id/edit",
    meta: {
      title: "edit_employee",
      permission: "employees_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },

        {
          text: "dashboard.employees.heading.employees",
          to: "/employeesManagement/employees",
          link: true,
          exact: true
        },
        {
          text: "dashboard.employees.heading.edit_employee",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/employees/crud"),
    allow_children: false
  }
];
