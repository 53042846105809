import Vue from "vue";
import Router from "vue-router";
import Layout from "@/layout";
import cookie from "js-cookie";
import store from "@/store";
import { asyncRoutes } from "./asyncRoutes";
Vue.use(Router);
//Catching errors in push replace
//Of course, errors in replace can be captured the same way
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
// Base Routes
export const constantRouterMap = [
  {
    path: "/",
    redirect: "/home",
    component: Layout,
    name: "layout",
    props: true,
    meta: {
      title: "layout_page"
    },
    children: [...asyncRoutes]
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    meta: {
      title: "login"
    },
    hidden: true
  },
  {
    path: "/no-permissions",
    component: () => import("@/views/noPermissions"),
    meta: {
      title: "noPermissions"
    },
    hidden: true
  },
  {
    path: "/send-code",
    component: () => import("@/views/sendCode"),
    meta: {
      title: "verify_code"
    },
    hidden: true
  },
  {
    path: "/verify-code",
    component: () => import("@/views/verifyCode"),
    meta: {
      title: "send_code"
    },
    hidden: true
  },
  {
    path: "/reset-password",
    component: () => import("@/views/resetPassword"),
    meta: {
      title: "reset_password"
    },
    hidden: true
  },
  {
    path: "/complete-register",
    component: () => import("@/views/completeRegister"),
    meta: {
      title: "completeRegister"
    },
    hidden: true
  },
  {
    path: "/401",
    component: () => import("@/views/errorPage/401"),
    name: "Page401",
    meta: {
      title: "Page401"
    },
    hidden: true
  },
  {
    path: "/not-found",
    component: () => import("@/views/errorPage/404"),
    name: "404",
    meta: {
      title: "404"
    },
    hidden: true
  },
  {
    path: "/authorize-report",
    component: () => import("@/views/authorizeReport"),
    name: "authorizeReport",
    meta: {
      title: "authorizeReport"
    },
    hidden: true
  },
  {
    path: "*",
    component: () => import("@/views/errorPage/404"),
    name: "Page404",
    meta: {
      title: "Page404"
    },
    hidden: true
  }
];
const router = new Router({
  mode: "hash",
  routes: constantRouterMap
});
router.beforeEach((to, from, next) => {
  if (cookie.get("token")) {
    if (
      to.path === "/login" ||
      to.path === "/verify-code" ||
      to.path === "/reset-password" ||
      to.path === "/complete-register" ||
      to.path === "/send-code" ||
      to.path === "/reset"
    ) {
      next("/");
    } else {
      next();
    }
  } else {
    if (
      to.path === "/login" ||
      to.path === "/verify-code" ||
      to.path === "/reset-password" ||
      to.path === "/complete-register" ||
      to.path === "/reset" ||
      to.path === "/send-code"
    ) {
      next();
    } else {
      next("/login");
    }
  }

  // if (to.path == "/profile/verifyCode") {
  //   if (from.path == "/profile") {
  //     to.path == "/profile/verifyCode";
  //   } else {
  //     router.back();
  //   }
  // }
});

export default router;
