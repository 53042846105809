export const paymentServicesRecordRoutes = [
  {
    path: "payment-services-record",
    to: "/settings/commissionsTaxes/payment-services-record",
    meta: {
      title: "paymentServicesRecord",
      permission: "payment_gateways_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commissionsTaxes",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes"
        },
        {
          text: "dashboard.commissionsTaxes.cards.paymentServicesRecord",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServicesRecord/record"),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/payment-services-record/add",
    meta: {
      title: "paymentServicesRecord_add",
      permission: "payment_gateways_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commissionsTaxes",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes"
        },
        {
          text: "dashboard.commissionsTaxes.cards.paymentServicesRecord",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/payment-services-record"
        },
        {
          text: "dashboard.paymentServicesRecord.heading.add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServicesRecord/crud"),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/payment-services-record/:id/edit",
    meta: {
      title: "paymentServicesRecord_edit",
      permission: "payment_gateways_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commissionsTaxes",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes"
        },
        {
          text: "dashboard.commissionsTaxes.cards.paymentServicesRecord",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/payment-services-record"
        },
        {
          text: "dashboard.paymentServicesRecord.heading.edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServicesRecord/crud"),
    allow_children: false
  }
];
