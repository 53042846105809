<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(submitForm)">
            <v-card class="px-3 py-10" outlined>
              <v-row class="view-row">
                <v-col cols="12" md="6" class="py-5">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.vendor_name"
                        )
                      }}
                    </span>
                  </label>
                  <ShimmerLoader type="heading" :loading="loading">
                    <label>
                      <span class="font-medium form-label mb-2 primary--text">
                        {{
                          form.vendor && form.vendor.name
                            ? form.vendor.name
                            : $t("dashboard.public.without")
                        }}
                      </span>
                    </label>
                  </ShimmerLoader>
                </v-col>
                <v-col cols="12" md="6" class="py-5">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.commercial_number"
                        )
                      }}
                    </span>
                  </label>
                  <ShimmerLoader type="heading" :loading="loading">
                    <label>
                      <span class="font-medium form-label mb-2 primary--text">
                        <template
                          v-if="
                            form.account_type == 'profession_practice_document'
                          "
                        >
                          {{ form.document_number || "---" }}
                        </template>
                        <template v-else>
                          {{ form.record_number || "---" }}
                        </template>
                      </span>
                      <!-- <span class="font-medium form-label mb-2 primary--text">
                        {{
                          form.record_number
                            ? form.record_number
                            : $t("dashboard.public.without")
                        }}
                      </span> -->
                    </label>
                  </ShimmerLoader>
                </v-col>
              </v-row>
              <v-row class="view-row">
                <v-col cols="12" md="6" class="py-5">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.tax_number"
                        )
                      }}
                    </span>
                  </label>
                  <ShimmerLoader type="heading" :loading="loading">
                    <label>
                      <span class="font-medium form-label mb-2 primary--text">
                        {{
                          form.tax_number
                            ? form.tax_number
                            : $t("dashboard.public.without")
                        }}
                      </span>
                    </label>
                  </ShimmerLoader>
                </v-col>
                <v-col cols="12" md="6" class="py-5">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.phone"
                        )
                      }}
                    </span>
                  </label>
                  <ShimmerLoader type="heading" :loading="loading">
                    <label>
                      <span class="font-medium form-label mb-2 primary--text">
                        {{
                          form.vendor && form.vendor.phone
                            ? form.vendor.phone
                            : $t("dashboard.public.without")
                        }}
                      </span>
                    </label>
                  </ShimmerLoader>
                </v-col>
              </v-row>
            </v-card>
            <!-- START:: SEND REQ -->
            <v-card flat class="py-10 mb-5 card-shadow">
              <v-row>
                <v-col cols="12" class="py-0">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.degree_severity"
                        )
                      }}
                      <span class="starrisk">*</span>
                    </span>
                  </label>
                  <FormGroup rules="required" name="public.degree_severity">
                    <template #default="{ attrs }">
                      <FormsAutoComplete
                        v-bind="attrs"
                        :placeholder="
                          $t(
                            'dashboard.moneyLaundering.exceedTransactions.placeholder.select_degree_severity'
                          )
                        "
                        outlined
                        :loading="loadingDegrees"
                        :items="degreeItems"
                        item-text="name"
                        item-value="id"
                        v-model="form.degree_severity"
                        label=""
                      ></FormsAutoComplete>
                    </template>
                  </FormGroup>
                  <!-- <FormGroup
                    rules="required"
                    name="public.danger_degree"
                    errorName="danger_degree"
                  >
                    <template #default="{ attrs }">
                      <label>
                        <span class="font-bold form-label mb-2">
                          {{
                            $t(
                              "dashboard.moneyLaundering.exceedTransactions.labels.risk_percent"
                            )
                          }}
                          <span class="starrisk">*</span>
                        </span>
                      </label>
                      <FormsTextInput
                        normalField
                        oninput="this.value = this.value&& this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')"
                        :placeholder="
                          $t(
                            'dashboard.moneyLaundering.exceedTransactions.placeholder.enter_risk_percent'
                          )
                        "
                        outlined
                        v-bind="attrs"
                        :form="form"
                        v-model="form.custom_percent"
                        suffix="%"
                      />
                    </template>
                  </FormGroup> -->
                </v-col>
                <v-col cols="12" class="py-0">
                  <label>
                    <span class="font-medium form-label mb-2">
                      {{
                        $t(
                          "dashboard.moneyLaundering.exceedTransactions.labels.responsible_opinion"
                        )
                      }}
                      <span class="starrisk">*</span>
                    </span>
                  </label>
                  <FormGroup
                    name="public.validation.responsible_opinion"
                    rules="required"
                    errorName="responsible_opinion"
                  >
                    <template #default="{ attrs }">
                      <FormsTextArea
                        v-bind="attrs"
                        outlined
                        v-model="form.responsible_opinion"
                        no-resize
                        rows="3"
                        label=""
                      />
                    </template>
                  </FormGroup>
                </v-col>
              </v-row>
            </v-card>
            <!--end card-->
            <!--start buttons-->
            <v-col class="actions-btns d-flex justify-end" cols="12">
              <v-btn
                type="submit"
                :loading="loadingButton"
                class="primary mx-5"
              >
                <v-icon left dense>mdi-content-save</v-icon>
                {{
                  $t(
                    "dashboard.moneyLaundering.exceedTransactions.buttons.save"
                  )
                }}
              </v-btn>

              <v-btn type="button" outlined color="primary" @click="backToPage">
                <v-icon left dense>mdi-arrow-left</v-icon>
                {{
                  $t(
                    "dashboard.moneyLaundering.exceedTransactions.buttons.back"
                  )
                }}
              </v-btn>
            </v-col>
            <!--end buttons-->
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <GenericDialog
      :dialog.sync="confirmDialog"
      color="green"
      icon="save.png"
      type="confirmSubmit"
      @on-submit="onConfirm"
      contentType="moneyLaundering.exceedTransactions.popup.body.confirmSubmit"
      acceptBtn="moneyLaundering.exceedTransactions.popup.buttons.accept"
      refuseBtn="moneyLaundering.exceedTransactions.popup.buttons.refuse"
    ></GenericDialog>
    <GenericDialog
      :dialog.sync="backDialog"
      color="warning"
      type="back"
      icon="info.png"
      :redirect="redirect"
      contentType="moneyLaundering.exceedTransactions.popup.body.back"
      acceptBtn="moneyLaundering.exceedTransactions.popup.buttons.accept"
      refuseBtn="moneyLaundering.exceedTransactions.popup.buttons.refuse"
    ></GenericDialog>
  </v-container>
</template>
<script>
import ExtendableComponent from "@/components/ExtendableComponent";
import { cloneDeep } from "lodash";
const defaultForm = {
  custom_percent: "",
  tax_number: "",
  record_number: "",
  responsible_opinion: "",
  type: "",
  facility_id: "",
  document_number: "",
  account_type: "",
  degree_severity: "",
};
export default {
  name: "AddReport",
  props: {
    addReportType: {
      type: String,
      default: "transactions_exceeded_limits",
    },
  },
  extends: ExtendableComponent,
  data: () => ({
    confirmDialog: false,
    item: {},
    form: { ...defaultForm },
    comparedForm: { ...cloneDeep(defaultForm) },
    loadingButton: false,
    loading: true,
    degreeItems: [],
    loadingDegrees: false,
  }),
  methods: {
    async submitForm() {
      this.confirmDialog = !this.confirmDialog;
    },

    onConfirm() {
      this.loadingButton = true;
      this.handleCreate();
    },

    resetData() {
      this.form.responsible_opinion = "";
      this.comparedForm.responsible_opinion = "";
      this.form.degree_severity = "";
      this.comparedForm.degree_severity = "";
      // this.form.custom_percent = this.comparedForm.custom_percent =
      //   this.item.custom_percent;

      this.$refs.form.reset();
    },

    generateFormData() {
      const formData = new FormData();
      formData.append("responsible_opinion", this.form.responsible_opinion);
      // formData.append("custom_percent", this.form.custom_percent);
      formData.append("risk_degree_id", this.form.degree_severity);
      formData.append("type", this.addReportType);
      formData.append("transaction_indictor_id", this.$route.params.id);
      this.$route.query.facility_id &&
        formData.append("facility_id", this.$route.query.facility_id);
      return formData;
    },

    handleCreate() {
      this.$http
        .post({
          reqName: `reports`,
          data: this.generateFormData(),
        })
        .then(() => {
          // this.resetData();
          this.$router.go(-1);
        })
        .finally(() => {
          this.loadingButton = false;
          this.confirmDialog = !this.confirmDialog;
        });
    },
    async handleShowData() {
      const res = await this.$http.get({
        reqName: `get_vendor_info_for_facility/${this.$route.query.facility_id}`,
      });
      const { data } = res.data;
      this.item = data;
      this.form = {
        account_type: data.type,
        document_number: data.document_number,
        record_number: data.record_number,
        tax_number: data.tax_number,
        vendor: data.vendor,
        degree_severity: data.given_risk_degree.id
          ? data.given_risk_degree.id
          : data.risk_percent.id,
        responsible_opinion: "",
        custom_percent: data.vendor.risk_percent
          ? data.vendor.risk_percent
          : "",
      };
      this.loading = false;
      this.comparedForm = { ...this.form };
    },
    async getDegreeOptions() {
      this.loadingDegrees = true;
      await this.$http
        .get({
          reqName: "/list_risk_rates?type=degree",
        })
        .then((res) => {
          const { data } = res.data;
          this.degreeItems = data;
          this.loadingDegrees = false;
        })
        .catch((err) => console.log(err));
    },
  },
  created() {
    this.getDegreeOptions();
  },
  watch: {
    $route: {
      handler({ params }) {
        if (params.id) {
          this.handleShowData();
        }
      },
      immediate: true,
    },
  },
};
</script>
