export default {
  failedTransactions: {
    heading: {
      failedTransactions: "معاملات غير مكتملة",
      failed_transactions_view: "عرض",
      failed_transactions_edit: "تعديل",
    },
    labels: {
      vendor_name: "اسم العميل",
      branch: "الفرع",
      commercial_record: "السجل التجاري / رقم الوثيقة",
      created_at: "وقت/تاريخ انشاء المعاملة",
      refused_date: "تاريخ / وقت فشل المعاملة",
      tax_number: "الرقم الضريبي",
      vendor_device_ip: "رقم تعريف جهاز البائع",
      transaction_number: "رقم المعاملة",
      transaction_value: "قيمة المعاملة",
      status: "الحالة",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      service_pay_type: "نوع خدمة الدفع",
      payment_types: "نوع الدفع",
      seller_name: "اسم البائع",
    },
    placeholder: {
      enter_vendor_name: "أدخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_transaction_number: "ادخل رقم العاملة",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_status: "اختر الحالة",
      select_branch: "اختر الفرع",
      select_vendor_name: "اختر اسم العميل",
      select_service_pay_type: "اختر نوع خدمة الدفع",
      select_seller_name: "اختر اسم البائع",
    },
    table: {
      "#": "#",
      payment_type: "نوع الدفع",
      vendor_name: "اسم العميل",
      branch: "الفرع",
      commercial_record: "السجل التجاري/الوثيقة",
      tax_number: "الرقم الضريبي",
      transaction_value: "قيمة المعاملة",
      transaction_number: "رقم المعاملة",
      created_at: "وقت/تاريخ انشاء المعاملة",
      refused_date: "تاريخ / وقت رفض المعاملة",
      status: "الحالة",
      actions: "العمليات",
      seller_name: "اسم البائع",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      no_data: "لا توجد نتائج متاحة",
      serviceType: "نوع خدمة الدفع",

      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      client_mechanism_transfer: "تحويل ألية العميل",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
    },
    tooltip: {
      view_details: "عرض",
    },
    chips: {
      deducted: "تم استقطاعها",
      un_deducted: "لم تستقطع",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: "معطل",
      day: "يوم",
      month: "شهر",
      year: "سنة",
      failed: "معاملات فاشلة",
      not_paid: "فواتير لم تسدد",
      expired: " لم تدفع منتهية",
    },
    paymentTypes: {
      credit: "الإلكتروني",
      cash: "نقدي",
      credit_and_cash: "الإلكتروني ونقدي ",
    },
  },
};
