export const severitySettingsRoutes = [
  {
    path: "severity-settings",
    to: "/money-laundering/severity-settings",
    meta: {
      title: "severity_settings",
      permission: "risk_rates_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/severitySettings/record"),
    allow_children: false
  },
  {
    path: "/money-laundering/severity-settings/add",
    meta: {
      title: "severity_settings_add",
      permission: "risk_rates_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings_add",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/severitySettings/crud"),
    allow_children: false
  },
  {
    path: "severity-settings/:id/edit",
    meta: {
      title: "severity_settings_edit",
      permission: "risk_rates_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings_edit",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/severitySettings/crud"),
    allow_children: false
  },
  {
    path: "severity-settings/:id/view",
    meta: {
      title: "severity_settings_view",
      permission: "risk_rates_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings",
          exact: true,
          link: true,
          to: "/money-laundering/severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.severitySettings.heading.severity_settings_view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/severitySettings/view"),
    allow_children: false
  }
];
