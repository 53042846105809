export const dailyTransactionsRoutes = [
  {
    path: "dailyTransactions",
    to: "/money-laundering/dailyTransactions",
    meta: {
      title: "dailyTransactions",
      permission: "vendor_daily_transaction_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/dailyTransactions/record"),
    allow_children: false
  },
  {
    path: "dailyTransactions/dangerous_vendors/:id",
    to: "/money-laundering/dailyTransactions/dangerous_vendors",
    meta: {
      title: "dangerous_vendors",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_record",
          exact: true,
          link: true,
          to: "/money-laundering/dailyTransactions"
        },
        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dangerous_vendors",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/dailyTransactions/dangerous_vendors"),
    allow_children: false
  },
  {
    path: "dailyTransactions/:id/askView",
    meta: {
      title: "dailyTransactions_askview",
      permission: "vendor_daily_transaction_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_record",
          exact: true,
          link: true,
          to: "/money-laundering/dailyTransactions"
        },
        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_askview",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/dailyTransactions/view/ask_view"),
    allow_children: false
  },

  {
    path: "dailyTransactions/:id/view",
    meta: {
      title: "dailyTransactions_view_details",
      permission: "vendor_daily_transaction_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_record",
          exact: true,
          link: true,
          to: "/money-laundering/dailyTransactions"
        },
        {
          text: "dashboard.moneyLaundering.dailyTransactions.heading.dailyTransactions_view_details",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/dailyTransactions/view"),
    allow_children: false
  }
];
