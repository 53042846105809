import appBar from "./modules/appBar";
import banks from "./modules/banks";
import common from "./modules/common";
import footer from "./modules/footer";
import login from "./modules/login";
import settings from "./modules/settings";
import sidebar from "./modules/sidebar";
import transactions from "./modules/transactions";
import vendors from "./modules/vendors";
import employeesManagement from "./modules/employeesManagement";
import followUp from "./modules/followUp";
import support from "./modules/support";
import blackList from "./modules/blackList";
import vendorsRecord from "./modules/vendorsRecord";
import documentTypes from "./modules/documentTypes";
import branches from "./modules/branches";
import authorizeReport from "./modules/authorizeReport";
import delegation from "./modules/delegation";
import areas from "./modules/areas";
import profile from "./modules/profile";
import moneyLaundering from "./modules/moneyLaundering";
import transactionsModule from "./modules/transactionsModule";

export default {
  dashboard: {
    ...login,
    ...sidebar,
    ...appBar,
    ...banks,
    ...common,
    ...settings,
    ...transactions,
    ...vendors,
    ...footer,
    ...employeesManagement,
    ...support,
    ...footer,
    ...followUp,
    ...blackList,
    ...vendorsRecord,
    ...documentTypes,
    ...branches,
    ...authorizeReport,
    ...delegation,
    ...areas,
    ...profile,
    ...moneyLaundering,
    ...transactionsModule,
  },
};
