export default {
  suspectedEmployeesRequest: {
    // START:: HEADING
    heading: {},
    // START:: LABELS
    labels: {
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_rating: "تصنيف الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      employee_phone: "جوال الموظف",
      employee_comment: "رأي المسؤل",
      employee_identity: "هوية الموظف",
      employee_name: "اسم الموظف",
      tax_number: "الرقم الضريبي",
      date_from: "الفترة من",
      date_to: "الفترة الي",
      suspected: "مؤشر الإشتباه",
      status: "الحالة",
      vendor_type: "نوع العميل",
      responsable_risk_percent:"نسبة الخطورة المضافة من المسئول"
    },
    // START:: PLACEHOLDER
    placeholder: {
      facility_name: "أدخل اسم المنشأة",
      commercial_registration_document_no: "أدخل السجل التجاري / رقم الوثيقة",
      date_from: "أدخل الفترة من",
      date_to: "أدخل الفترة الي",
      risk_rating: "اختر تصنيف الخطورة",
      degree_of_risk: "اختر درجة الخطورة",
      status: "اختر الحالة",
      vendor_type: "اختر نوع العميل",
    },
    // START:: TABLE
    table: {
      "#": "#",
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      employees_identity: "هوية الموظف",
      suspected: "مؤشر الإشتباه",
      employees_name: "اسم الموظف",
      employees_phone: "جوال الموظف",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_rating: "تصنيف الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      status: "الحالة",
      employee_name: "اسم الموظف",
    },
  },
};
