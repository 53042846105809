export const reportsSuspectedAccountsRequestRoutes = [
  {
    path: "reports/suspected-accounts-request",
    to: "/money-laundering/reports/suspected-accounts-request",
    meta: {
      title: "reports.suspectedAccountsRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.cards.danger_suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.suspectedAccountsRequest",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/suspectedAccountsRequest/record"),
    allow_children: false
  },
  {
    path: "reports/suspected-accounts-request/:id/view",
    meta: {
      title: "reports.suspectedAccountsRequest_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.cards.danger_suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.reports.heading.suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/reports/suspected-accounts-request"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/suspectedAccountsRequest/view"),
    allow_children: false
  }
];
