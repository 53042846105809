export const documentTypesRoutes = [
  {
    path: "documentTypes",
    to: "/vendors/documentTypes",
    meta: {
      title: "document_types",
      permission: "document_types_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.documentTypes.heading.document_types",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/documentTypes/record"),
    allow_children: false
  },
  {
    path: "documentTypes/add",
    meta: {
      title: "add",
      permission: "document_types_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.documentTypes.heading.document_types",
          exact: true,
          link: true,
          to: "/vendors/documentTypes"
        },
        {
          text: "dashboard.documentTypes.heading.add",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/documentTypes/crud"),
    allow_children: false
  },
  {
    path: "documentTypes/:id/edit",
    meta: {
      title: "edit_documentTypes",
      permission: "document_types_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.documentTypes.heading.document_types",
          exact: true,
          link: true,
          to: "/vendors/documentTypes"
        },
        {
          text: "dashboard.documentTypes.heading.edit",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/documentTypes/crud"),
    allow_children: false
  },
  {
    path: "documentTypes/:id/view",
    meta: {
      title: "view_documentTypes",
      permission: "document_types_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.documentTypes.heading.document_types",
          exact: true,
          link: true,
          to: "/vendors/documentTypes"
        },
        {
          text: "dashboard.documentTypes.heading.view_documentTypes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/documentTypes/view"),
    allow_children: false
  }
];
