export const canceledDelegationsRequest = [
  {
    path: "canceledDelegationsRequest",
    to: "/vendors/canceledDelegationsRequest",
    meta: {
      title: "canceledDelegationsRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.delegation_cancelled_request",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/canceledDelegationsRequest/record"),
    allow_children: false
  },
  {
    path: "/vendors/canceledDelegationsRequest/:id/view",
    meta: {
      title: "canceledDelegationsRequest_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.delegation_cancelled_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/canceledDelegationsRequest"
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/canceledDelegationsRequest/view"),
    allow_children: false
  }
];
