export const vendorEmployeesRecordRoutes = [
  {
    path: "employeesRecord",
    to: "/vendors/employeesRecord",
    meta: {
      title: "employeesRecord",
      permission: "vendor_employees_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.employees_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/employeesRecord/record"),
    allow_children: false
  },
  {
    path: "employeesRecord/:id/view/",
    meta: {
      title: "show_employeesRecord",
      permission: "vendor_employees_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.employees_record",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/employeesRecord"
        },
        {
          text: "dashboard.vendors.heading.view_employees_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/employeesRecord/view"),
    allow_children: false
  }
];
