export const riskVendorsRoutes = [
  {
    path: "/money-laundering/risk-vendors",
    to: "/money-laundering/risk-vendors",
    meta: {
      title: "risk_vendors",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.transactions.heading.risk_vendors",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/riskVendors/record"),
    allow_children: false
  },

  {
    path: "/money-laundering/risk-vendors/:id/view",
    meta: {
      title: "view_exceed_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.transactions.heading.risk_vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/risk-vendors"
        },
        {
          text: "dashboard.moneyLaundering.riskVendors.heading.view_details",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/riskVendors/view"),
    allow_children: false
  },

  {
    path: "/money-laundering/risk-vendors/transactions/:id/view",
    meta: {
      title: "view_exceed_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.transactions.heading.risk_vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/risk-vendors"
        },
        {
          text: "dashboard.moneyLaundering.riskVendors.heading.view_transactions_details",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/riskVendors/viewTransactions"),
    allow_children: false
  },

  {
    path: "/money-laundering/risk-vendors/employees/:reqName/:id/view",
    meta: {
      title: "view_exceed_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        // {
        //   text: "dashboard.transactions.heading.risk_vendors",
        //   disabled: false,
        //   exact: true,
        //   link: true,
        //   to: "/money-laundering/risk-vendors"
        // },
        {
          text: "dashboard.moneyLaundering.riskVendors.heading.view_employees_details",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/riskVendors/viewEmployees"),
    allow_children: false
  }
];
