export const severitySettingsNationalitiesRoutes = [
  {
    path: "severity_settings_nationalities",
    to: "/money-laundering/severity_settings_nationalities",
    meta: {
      title: "severity_sttings_nationalities",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsNationalities/record"),
    allow_children: false
  },
  {
    path: "severity_settings_nationalities/add",
    meta: {
      title: "severity_settings_nationalities_add",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_nationalities"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsNationalities/crud"),
    allow_children: false
  },
  {
    path: "severity_settings_nationalities/:id/edit",
    meta: {
      title: "severity_settings_nationalities_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities",
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_nationalities"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsNationalities/crud"),
    allow_children: false
  },

  {
    path: "severity_settings_nationalities/:id/view",
    meta: {
      title: "severity_settings_nationalities_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities",
          exact: true,
          link: true,
          to: "/money-laundering/severity_settings_nationalities"
        },
        {
          text: "dashboard.moneyLaundering.severitySettingsNationalities.heading.severity_settings_nationalities_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/severitySettingsNationalities/view"),
    allow_children: false
  }
];
