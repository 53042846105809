import staticPages from "./staticPages";
import links from "./links";
import translations from "./translations";
import countries from "./countries";
import commissionsTaxes from "./commissionsTaxes";
import vipVendors from "./vipVendors";
import rasid_jack_programs from "./rasid_jack_programs";
import rasid_jack_vendors_programs from "./rasid_jack_vendors_programs";

export default {
  ...staticPages,
  ...links,
  ...commissionsTaxes,
  ...countries,
  ...translations,
  ...vipVendors,
  ...rasid_jack_programs,
  ...rasid_jack_vendors_programs,
  settings: {
    cards: {
      staticPages: "الصفحات الثابتة",
      links: "الروابط",
      translations: "الترجمات",
      commissionsTaxes: "العمولات والضرائب",
      countries: "الدول",
      system_setting: "إعدادات النظام",
      vipVendors: "إعدادات تصنيف كبار العملاء",
      system_programs: "برامج النظام",
      rasid_jack_programs: "برامج رصيد جاك",
      rasid_jack_vendors_programs: "برامج عملاء رصيد جاك",
      general_setting: "إعدادات عامة",
      commission_taxes_setting: "إعدادات نسب العمولات والضرائب",
      payment_gate_settings: "إعدادات نسب / رسوم خدمات الدفع",
      commission_taxes_reports: "تقارير نسب العمولات والضرائب",
      add_payment_gate_settings: "إضافة",
      edit_payment_gate_settings: "تعديل",
      rasid_jack_dues_rate: "نسب مستحقات رصيد جاك الثابتة",
      added_value_rates: "نسبة القيمة المضافة",
      intermediaryBankReceivables: "مستحقات البنك الوسيط",
      servicesFees: "رسوم خدمات",
      withholding_receivables: "المستحقات المستقطعة",
    },
  },
};
