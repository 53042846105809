export default {
  translations: {
    heading: {
      translations: "الترجمات",
    },

    table: {
      "#": "#",
      ar_lang: "اللغة العربية",
      key: "المفتاح",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },

    buttons: {
      export: "تصدير",
    },
    labels: {
      ar_lang: "اللغة العربية",
      key: "المفتاح",
    },
    placeholder: {
        enter_ar_lang:"أدخل اللغة العربية",
        enter_key:"أدخل المفتاح"
    },
  },
};
