import { settingsRoutes } from "./modules/settings";
import { statisticsRoute } from "./modules/statistics";
import { vendorsRoutes } from "./modules/vendors";
import { transactionsRoutes } from "./modules/transactions";
import { areasRoutes } from "./modules/areas";
import { followUpRoutes } from "./modules/followUp";
import { employeesManagementRoutes } from "./modules/employeesManagement";
import { supportRoutes } from "./modules/support";
import { usersRoute } from "./modules/users";
import { moneyLaunderingRoutes } from "./modules/moneyLaundering";

export const asyncRoutes = [
  ...statisticsRoute,
  ...employeesManagementRoutes,
  ...vendorsRoutes,
  ...transactionsRoutes,
  ...moneyLaunderingRoutes,
  ...areasRoutes,
  ...supportRoutes,
  ...followUpRoutes,
  ...settingsRoutes,
  ...usersRoute
];
