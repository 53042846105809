export const vendorTransactionsRequestsRoutes = [
  {
    path: "vendor-transactions-requests",
    meta: {
      title: "vendor_transactions_record",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          exact: true,
          link: true,
          to: "/money-laundering",
        },
        {
          text: "dashboard.transactions.heading.vendors_transactions_requests_record",
          disabled: true,
        },
      ],
    },
    component: () =>
      import("@/views/moneyLaundering/vendorTransactionsRequests/record"),
    allow_children: false,
  },
  {
    path: "/money-laundering/vendor-transactions-requests/:id/view",
    meta: {
      title: "view_exceed_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          exact: true,
          link: true,
          to: "/money-laundering",
        },
        {
          text: "dashboard.transactions.heading.vendors_transactions_requests_record",
          exact: true,
          link: true,
          to: "/money-laundering/vendor-transactions-requests",
        },
        {
          text: "dashboard.moneyLaundering.riskVendors.heading.view_details",
          disabled: true,
        },
      ],
    },
    component: () =>
      import("@/views/moneyLaundering/vendorTransactionsRequests/view"),
    allow_children: false,
  },
];
