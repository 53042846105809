export const paymentServicesRoutes = [
  {
    path: "payment-services",
    to: "/settings/commissionsTaxes/payment-services",
    meta: {
      title: "payment_gate_settings",
      permission: "payment_gateway_percents_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        // {
        //   text: "dashboard.settings.cards.commissionsTaxes"),
        //   exact: true,
        //   link: true,
        //   to: "/settings/commissionsTaxes",
        // },
        {
          text: "dashboard.settings.cards.payment_gate_settings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServices/record"),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/payment-services/add",
    meta: {
      title: "add_payment_gate_settings",
      permission: "payment_gateway_percents_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        // {
        //   text: "dashboard.settings.cards.commissionsTaxes"),
        //   exact: true,
        //   link: true,
        //   to: "/settings/commissionsTaxes",
        // },
        {
          text: "dashboard.settings.cards.payment_gate_settings",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/payment-services"
        },
        {
          text: "dashboard.settings.cards.add_payment_gate_settings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServices/crud"),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/payment-services/:id/edit",
    meta: {
      title: "edit_payment_gate_settings",
      permission: "payment_gateway_percents_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        // {
        //   text: "dashboard.settings.cards.commissionsTaxes"),
        //   exact: true,
        //   link: true,
        //   to: "/settings/commissionsTaxes",
        // },
        {
          text: "dashboard.settings.cards.payment_gate_settings",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/payment-services"
        },
        {
          text: "dashboard.settings.cards.edit_payment_gate_settings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/commissionsTaxes/paymentServices/crud"),
    allow_children: false
  }
];
