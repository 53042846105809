export const consumersDataRoutes = [
  {
    path: "consumers-data-record",
    to: "/transactions/consumers-data-record",
    meta: {
      title: "consumers_record",
      permission: "consumer_logs_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.consumersData.heading.consumers_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/transactions/consumersData/record"),
    allow_children: false
  }
];
