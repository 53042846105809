export default {
  suspectedAccountsRequest: {
    // START:: HEADING
    heading: {},
    // START:: LABELS
    labels: {
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_percent: "نسبة الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      vendor_phone: "رقم الجوال",
      vendor_comment: "رأي العميل",
      vendor_identity: "رقم الهوية",
      vendor_name: "اسم العميل",
      tax_number: "الرقم الضريبي",
      date_from: "الفترة من",
      date_to: "الفترة الي",
      suspected: "مؤشر الإشتباه",
      status: "الحالة",
      responsible_opinion: "رأي المسئول",
      responsible_name: "اسم المسئول",
      responsible_identity_number: "هوية المسئول",
      responsible_phone: "جوال المسئول",
      responsable_risk_percent: "نسبة الخطورة المضافة من المسئول",
      facility_profession_name: "اسم المنشأة/المسمي المهني",
      identity_number: "رقم الهوية",
      request_code: "كود الطلب",
      request_number: "رقم الطلب",
    },
    // START:: PLACEHOLDER
    placeholder: {
      facility_name: "أدخل اسم المنشأة",
      commercial_registration_document_no: "أدخل السجل التجاري / رقم الوثيقة",
      date_from: "أدخل الفترة من",
      date_to: "أدخل الفترة الي",
      risk_percent: "أدخل نسبة الخطورة",
      degree_of_risk: "اختر درجة الخطورة",
      status: "اختر الحالة",
      enter_vendor_name: "أدخل اسم العميل",
      enter_identity_number: "أدخل رقم الهوية",
    },
    // START:: TABLE
    table: {
      "#": "#",
      actions: "العمليات",
      facility_name: "اسم المنشأة",
      vendor_identity: "رقم الهوية",
      suspected: "مؤشر الإشتباه",
      vendor_name: "اسم العميل",
      vendor_phone: "رقم الجوال",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_percent: "نسبة الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      status: "الحالة",
      facility_profession_name: "اسم المنشأة/المسمي المهني",
    },
    chips: {
      no_danger: "لا يوجد خطورة",
    },
    statusOptions: {
      success: "تم مراجعتها",
      not_completed: "انتظار المراجعة",
      accepted: "مقبولة",
      rejected: "مرفوضة",
      pending: "انتظار المراجعة",
    },
  },
};
