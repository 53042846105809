export const approvedRequestsRoutes = [
  {
    path: "approvedRequests",
    to: "/vendors/approvedRequests",
    meta: {
      title: "approvedRequests",
      permission: "facility_update_requests_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.Approved_requests",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/approvedRequests/record"),
    allow_children: false
  },
  {
    path: "approvedRequests/:id/view",
    meta: {
      title: "view_approvedRequests",
      permission: "facility_update_requests_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.cards.Approved_requests",
          to: "/vendors/approvedRequests",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/approvedRequests/view"),
    allow_children: false
  }
];
