export default {
  staticPages: {
    fields: {
      name: "اسم الصفحة",
      description: "محتوي الصفحة",
      status: "الحالة",
      image: "صورة الصفحة",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    heading: {
      staticPages: "الصفحات الثابتة",
      add_staticPages: "إضافة صفحة",
      historical_movement: "الحركة التاريخية",
      edit_staticPages: "تعديل صفحة",
      view_staticPages: "عرض صفحة",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        page_has_link: "لا يمكن حذف صفحة مرتبطة برابط",
        edit_page_with_link: "لا يمكن تعطيل صفحة مرتبطة برابط",
      },
    },

    table: {
      "#": "#",
      page_name: "اسم الصفحة",
      status: "الحالة",
      added_by: "تم بواسطة",
      department_name: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      add_permissions: "إضافة صلاحية",
      save: "حفظ",
      back: "عودة",
      add_staticPages: "إضافة صفحة",
      edit: "تعديل",
      close: "غلق",
    },
    labels: {
      page_name: "اسم الصفحة",
      image: "صورة",
      status: "الحالة",
      description: "محتوي الصفحة",
      show_options: "اختيارات الظهور",
      show_in_app: "يعرض في التطبيق",
      show_in_website: "يعرض في الموقع",
    },
    placeholder: {
      enter_page_name: "أدخل اسم الصفحة",
      select_status: "اختر الحالة",
      enter_description: "ادخل محتوي الصفحة",
      drag_image: "اسحب أو ارفع الصورة",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
    },
    chips: {
      active: "مفعل",
      in_active: " معطل",
    },
  },
};
