<template>
  <v-dialog
    persistent
    max-width="500"
    :value="dialog"
    class="radius-8"
    @input="$emit('update:dialog')"
  >
    <v-card max-width="500" class="pb-2 radius-8">
      <v-btn
        class="close-modal radius-8"
        max-height="30"
        max-width="30"
        fab
        color="grey lighten-4"
        @click="$emit('update:dialog')"
      >
        <v-icon color="primary"> mdi-close </v-icon>
      </v-btn>
      <v-card-title
        class="primary--text pt-15 d-flex align-center justify-center"
      >
        <img :src="`${$attrs.imgFile}`" alt="photo" />
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PreviewImageDialog",
  inheritAttrs: false,
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.close-modal {
  position: absolute;
  left: 10px;
  top: 10px;
}
.v-dialog__content .v-btn {
  min-width: inherit !important;
}

.v-card__title img {
  max-width: 100%;
  max-height: 300px;
}
</style>
