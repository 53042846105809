export default {
  risk_ratio_settings: {
    labels: {
      activity_percentage: "مؤشر خطورة النشاط",
      region_percentage: "مؤشر خطورة المناطق",
      character_percentage: "مؤشر خطورة  الشخصيات",
      natural: "طبيعي",
      company_legal: "اعتباري",
      nationality_percentage: "مؤشر خطورة الجنسيات",
      transactions_percentage: "مؤشر خطورة قيم المعاملات (المبيعات) السنوية",
      capital_amount_percentage: "مؤشر خطورة حجم رأس مال الشركة / المؤسسة",
      age_percentage: "نسب خطورة العمر",
      owner_percentage: "مؤشر خطورة عمر المالك",
      company_percentage: "مؤشر خطورة عمر الشركة",
      owner: "مؤشر خطورة جنسية الملاك",
      company: "اعتباري",
      employee: "مؤشر خطورة جنسية الموظفين",
      from: "القيمة من",
      to: "القيمة الي",
      percent: "مؤشر الخطورة",
      age_from: "العمر من ",
      age_to: "العمر الي",
      owner_age_percentage: "مؤشر خطورة عمر المالك",
      company_age_percentage: "مؤشر خطورة عمر الشركة / المؤسسة"
    },
    placeholder: {
      enter_percentage: "أدخل المؤشر",
      enter_value: "أدخل القيمة",
      enter_age: "ادخل العمر"
    },
    buttons: {
      save: "حفظ",
      back: "عودة"
    }
  }
};
