export const pendingDelegationsRoutes = [
  {
    path: "pendingRequest",
    to: "/vendors/pendingRequest",
    meta: {
      title: "pendingRequest",
      permission: "get-vendor-waiting-delegations",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.pending_request",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/pendingRequest/record"),
    allow_children: false
  },
  {
    path: "pendingRequest/:id/view",
    meta: {
      title: "view_pendingRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.pendingRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/pendingRequest"
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/pendingRequest/view"),
    allow_children: false
  }
];
