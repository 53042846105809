import { registerVendorRoutes } from "./registerVendor";
import { vendorAccountRequestRoutes } from "./accountRequest";
import { vendorProtectionSystemsRoutes } from "./protection_systems";
import { vendorEmployeesRecordRoutes } from "./employeesRecord";
import { blackListRoutes } from "./blackList";
import { vendorsRecordRoutes } from "./vendorsRecord";
import { documentTypesRoutes } from "./documentTypes";
import { branchesRoutes } from "./branches";
import { followUpRequest } from "./followUpRequest";

import { changeCustomerDataRoutes } from "./changeCustomerData/legalAccreditation";
import { moneyLaunderingApprovalRoutes } from "./changeCustomerData/moneyLaunderingApproval";
import { SystemAdministratorApprovalRoutes } from "./changeCustomerData/SystemAdministratorApproval";
import { approvedRequestsRoutes } from "./changeCustomerData/approvedRequests";
import { approvedAndRefusedRequestsRoutes } from "./changeCustomerData/approvedAndRefusedRequests";
import { delegatedRequestsRoutes } from "./changeCustomerData/delegatedRequests";
import { authorizationsVendorRoutes } from "./authorizations";
import { electronicDelegationsRoutes } from "./electronicDelegations";
import { pendingDelegationsRoutes } from "./pendingDelegations";
import { recordingDelegationsRoutes } from "./recordingDelegations";
import { canceledDelegationsRequest } from "./canceledDelegationsRequest";
import { vipVendorsRoutes } from "./vipVendors";
import { activitiesRoutes } from "./activities";
import { vendorBanksRoutes } from "./banks";

export const vendorsRoutes = [
  {
    path: "/vendors",
    to: "/vendors",
    icon: "mdi-account-group",
    meta: {
      title: "vendors",
      permission: "vendors_management",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/vendors/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",
        meta: {
          title: "vendors",
          permission: "vendors_management",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.vendors",
              disabled: false
            }
          ]
        },
        component: () => import("@/views/vendors")
      },
      ...registerVendorRoutes,
      ...vendorAccountRequestRoutes,
      ...vendorProtectionSystemsRoutes,
      ...vendorEmployeesRecordRoutes,
      ...vendorsRecordRoutes,
      ...documentTypesRoutes,
      ...authorizationsVendorRoutes,
      ...changeCustomerDataRoutes,
      ...moneyLaunderingApprovalRoutes,
      ...SystemAdministratorApprovalRoutes,
      ...approvedRequestsRoutes,
      ...delegatedRequestsRoutes,
      ...branchesRoutes,
      ...blackListRoutes,
      ...vendorEmployeesRecordRoutes,
      ...followUpRequest,
      ...electronicDelegationsRoutes,
      ...recordingDelegationsRoutes,
      ...pendingDelegationsRoutes,
      ...canceledDelegationsRequest,
      ...vipVendorsRoutes,
      ...approvedAndRefusedRequestsRoutes,
      ...activitiesRoutes,
      ...vendorBanksRoutes
    ]
  }
];
