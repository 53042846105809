export default {
  rasid_jack_vendors_programs: {
    heading: {
      rasid_jack_vendors_programs: "برامج عملاء رصيد جاك",
      system_vendors: "برامج النظام",
      add_rasid_jack_vendors_program: "إضافة",
      edit_rasid_jack_vendors_program: "تعديل",
      view_rasid_jack_vendors_program: "عرض",
      historical_movment: "الحركة التاريخية",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    table: {
      "#": "#",
      program_name: "اسم البرنامج",
      main_program: "البرنامج الرئيسي",
      permissions: "الصلاحيات",
      added_by: "تم بواسطة",
      department_name: "اسم القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق",
      add: "إضافة",
      add_rasid_jack_program: "إضافة برنامج",
      edit_rasid_jack_program: "تعديل برنامج",
      view_rasid_jack_program: "عرض برنامج",
    },
    labels: {
      program_name: "اسم البرنامج",
      main_program: "البرنامج الرئيسي",
      permissions: "الصلاحيات",
      program_key: "مفتاح البرنامج",
    },
    placeholder: {
      enter_program_name: "أدخل اسم البرنامج",
      select_main_program: "اختر البرنامج",
      select_permissions: "أختر صلاحية",
      enter_program_key: "أدخل مفتاح البرنامج",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
      no_data: "لا توجد بيانات",
    },
    chips: {
      active: "مفعل",
      in_active: " معطل",
    },
  },
};
