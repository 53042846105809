export const ReceivablesTransferSettingsForTheIntermediaryBankRoutes = [
  {
    path: "receivables-transfer-setting-for-the-intermediary-bank",
    to: "/settings/commissionsTaxes/receivables-transfer-setting-for-the-intermediary-bank",
    meta: {
      title: "ReceivablesTransferSettingsForTheIntermediaryBank",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Receivables_transfer_settings_for_the_intermediary_bank",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/settings/commissionsTaxes/ReceivablesTransferSettingsForTheIntermediaryBank/record"
      ),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/receivables-transfer-setting-for-the-intermediary-bank/add",
    meta: {
      title: "ReceivablesTransferSettingsForTheIntermediaryBank_add",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Receivables_transfer_settings_for_the_intermediary_bank",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/receivables-transfer-setting-for-the-intermediary-bank"
        },
        {
          text: "dashboard.ReceivablesTransferSettingsForTheIntermediaryBank.heading.add",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/settings/commissionsTaxes/ReceivablesTransferSettingsForTheIntermediaryBank/crud"
      ),
    allow_children: false
  },
  {
    path: "/settings/commissionsTaxes/receivables-transfer-setting-for-the-intermediary-bank/:id/edit",
    meta: {
      title: "ReceivablesTransferSettingsForTheIntermediaryBank_edit",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.commissionsTaxes.cards.Receivables_transfer_settings_for_the_intermediary_bank",
          exact: true,
          link: true,
          to: "/settings/commissionsTaxes/receivables-transfer-setting-for-the-intermediary-bank"
        },
        {
          text: "dashboard.ReceivablesTransferSettingsForTheIntermediaryBank.heading.edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/settings/commissionsTaxes/ReceivablesTransferSettingsForTheIntermediaryBank/crud"
      ),
    allow_children: false
  }
];
