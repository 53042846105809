export const moneyLaunderingApprovalRoutes = [
  {
    path: "moneyLaunderingApproval",
    to: "/vendors/moneyLaunderingApproval",
    meta: {
      title: "moneyLaunderingApproval_record",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.moneyLaunderingApproval_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/moneyLaunderingApproval/record"),
    allow_children: false
  },
  {
    path: "moneyLaunderingApproval/:id/view",
    meta: {
      title: "view_moneyLaunderingApproval",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.cards.money_laundering_approval",
          to: "/vendors/moneyLaunderingApproval",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/moneyLaunderingApproval/view"),
    allow_children: false
  }
];
