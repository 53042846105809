export const rasid_jack_vendors_programs_routes = [
  {
    path: "rasid_jack_vendors_programs",

    meta: {
      title: "rasid_jack_vendors_programs",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.system_vendors",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.rasid_jack_vendors_programs",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/rasid_jack_vendors_programs/record"),
    allow_children: false
  },

  {
    path: "rasid_jack_vendors_programs/add",
    meta: {
      title: "add_rasid_jack_vendors_program",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.system_vendors",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.rasid_jack_vendors_programs",
          exact: true,
          link: true,
          to: "/settings/rasid_jack_vendors_programs"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.add_rasid_jack_vendors_program",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/rasid_jack_vendors_programs/crud"),
    allow_children: false
  },
  {
    path: "rasid_jack_vendors_programs/:id/view",
    meta: {
      title: "add_rasid_jack_vendors_program",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.system_vendors",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.rasid_jack_vendors_programs",
          exact: true,
          link: true,
          to: "/settings/rasid_jack_vendors_programs"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.view_rasid_jack_vendors_program",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/rasid_jack_vendors_programs/view"),
    allow_children: false
  },
  {
    path: "rasid_jack_vendors_programs/:id/edit",
    meta: {
      title: "add_rasid_jack_vendors_program",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.system_vendors",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.rasid_jack_vendors_programs",
          exact: true,
          link: true,
          to: "/settings/rasid_jack_vendors_programs"
        },
        {
          text: "dashboard.rasid_jack_vendors_programs.heading.edit_rasid_jack_vendors_program",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/settings/rasid_jack_vendors_programs/crud"),
    allow_children: false
  }
];
