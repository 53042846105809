import { transactionsClientAreaRoutes } from "./transactions_client_area";
import { viewClientsAndLocationRoutes } from "./view_clients_and_location";

export const areasRoutes = [
  {
    path: "/areas",
    to: "/areas",
    icon: "mdi-office-building-marker",
    meta: {
      title: "areas",
      permission: "statistics_and_tracking_department"
    },
    component: () => import("@/views/areas/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",
        meta: {
          title: "areas",
          permission: "statistics_and_tracking_department",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.areas"
            }
          ]
        },
        component: () => import("@/views/areas")
      },
      ...transactionsClientAreaRoutes,
      ...viewClientsAndLocationRoutes
    ]
  }
];
