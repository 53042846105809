import service from "@/config/axios";
import Vue from "vue";

class HttpClient {
  static instance;
  constructor() {
    if (HttpClient.instance instanceof HttpClient) {
      return HttpClient.instance;
    }
    HttpClient.instance = Object.freeze(this);
  }
  get({ reqName, query }) {
    return service({ url: reqName, params: { ...query }, method: "get" });
  }

  // in case delete we using post request  data  will be {_method:delete}
  post({ reqName, data }) {
    return service({ url: reqName, data, method: "post" });
  }
}

export const $http = new HttpClient();

Vue.prototype.$http = $http;
