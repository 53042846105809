export default {
  areas: {
    fields: {
      region: "المنطقة",
      city: "المدينة",
      district: "الحي",
      street: "الشارع",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    cards: {
      transactions_client_area: "مناطق تواجد العملاء والمعاملات",
      client_area: "مناطق تواجد العملاء",
      view_clients_and_location: "خريطة عرض العملاء والمعاملات",
    },
    buttons: {
      add: "إضافة منطقة",
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
    },
    placeholder: {
      choose_region: "اختر المنطقة",
      choose_city: "اختر المدينة",
      choose_district: "اختر الحي",
      enter_date_from: "اختر الفترة من",
      enter_date_to: "اختر الفترة الي",
      choose_activity: "اختر النشاط",
      enter_region: "أدخل المنطقة",
      enter_city: "أدخل المدينة",
      enter_district: "أدخل الحي",
      enter_street: "أدخل الشارع",
      enter_commercial_register: "ادخل السجل التجاري / رقم الوثيقه",
    },
    labels: {
      region: "المنطقة",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      activity: "النشاط",
      city: "المدينة",
      district: "الحي",
      street: "الشارع",
      commercial_register: "السجل التجاري / رقم الوثيقة",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete_country: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },
    table: {
      "#": "#",
      facility_name: "اسم المنشأة",
      date_time: "الوقت/التاريخ",
      location: "المنطقة/المدينه/الحي/الشارع",
      client_count: "إجمالي عدد العملاء",
      transactions_count: "إجمالي عدد المعاملات",
      client_name: "اسم العميل",
      commercial_number: "التجاري / رقم الوثيقة",
      activity: "النشاط",
      branch: "الفرع",
      city: "المدينة",
      district: "الحي",
      street: "الشارع",
      area: "المنطقة",
      actions: "العمليات",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    heading: {
      add_new_area: "إضافة منقطة جديدة",
    },
    map: {
      transactions_No: "عدد المعاملات",
      transactions_value: "قيمة المعاملات",
      sellers_No: "عدد البائعين",
    },
  },
};
