import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "@/lang";
import "./plugins/vee-validate";
import "./plugins/mapApi";
import "./bootstrap";
import Cookies from "js-cookie";
Vue.config.productionTip = false;

new Vue({
  store,
  vuetify,
  router,
  i18n,
  beforeCreate() {
    store.dispatch("setTrans");
  },
  created() {
    if (Cookies.get("token")) {
      store.dispatch("GetUserInfo");
    }
  },
  render: (h) => h(App)
}).$mount("#app");
