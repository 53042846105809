export const activitiesRoutes = [
  {
    path: "activities",
    to: "/vendors/activities",
    meta: {
      title: "activities_record",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.moneyLaundering.activities.heading.activities_record",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/activities/record"),
    allow_children: false
  },
  {
    path: "/vendors/activities/:id/view",
    meta: {
      title: "activities_view",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.moneyLaundering.activities.heading.activities_record",
          exact: true,
          link: true,
          to: "/vendors/activities"
        },
        {
          text: "dashboard.moneyLaundering.activities.heading.activities_view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/activities/view"),
    allow_children: false
  },
  {
    path: "/vendors/activities/:id/edit",
    meta: {
      title: "activities_edit",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.moneyLaundering.activities.heading.activities_record",
          exact: true,
          link: true,
          to: "/vendors/activities"
        },
        {
          text: "dashboard.moneyLaundering.activities.heading.activities_edit",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/activities/crud"),
    allow_children: false
  },
  {
    path: "/vendors/activities/add",
    meta: {
      title: "activities_add",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.moneyLaundering.activities.heading.activities_record",
          exact: true,
          link: true,
          to: "/vendors/activities"
        },
        {
          text: "dashboard.moneyLaundering.activities.heading.activities_add",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/activities/crud"),
    allow_children: false
  }
];
