export const delegatedRequestsRoutes = [
  {
    path: "delegatedRequests",
    to: "/vendors/delegatedRequests",
    meta: {
      title: "delegatedRequests",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.Delegated_requests",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/delegatedRequests/record"),
    allow_children: false
  },
  {
    path: "delegatedRequests/:id/view",
    meta: {
      title: "view_delegatedRequests",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.cards.Delegated_requests",
          to: "/vendors/delegatedRequests",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/delegatedRequests/view"),
    allow_children: false
  }
];
