<template>
  <v-hover v-slot="{ hover }">
    <v-card
      min-height="180px"
      outlined
      :to="$attrs.to"
      class="text-center pa-sm-4 pa-2 mx-auto mx-sm-0 d-flex justify-center align-center"
      :color="hover ? 'primary' : ''"
    >
      <div>
        <v-btn
          v-if="$attrs.CardIcon && !image"
          class="mb-2"
          fab
          :color="hover ? 'white' : 'grey lighten-4'"
        >
          <v-icon color="primary">
            {{ $attrs.CardIcon }}
          </v-icon>
        </v-btn>

        <v-list-item-avatar v-if="$attrs.CardIcon && image">
          <v-img :src="require(`@/assets/icons/${$attrs.CardIcon}`)"></v-img>
        </v-list-item-avatar>

        <v-card-title
          class="justify-center pb-0"
          :class="hover ? 'white--text' : 'black--text'"
        >
          {{ $attrs.CardTitle ? $t(`dashboard.${$attrs.CardTitle}`) : "" }}
        </v-card-title>
      </div>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CardCategory",
  inheritAttrs: false,
  props: {
    image: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px;
  transition: background 0.2s ease-in-out;
}

.v-card__title {
  word-break: normal;
  font-size: 16px;
}
.v-btn {
  border-radius: 15px 0 15px 0;
}
</style>
