var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ValidationProvider',_vm._g(_vm._b({ref:"fileRef",attrs:{"name":_vm.name,"rules":!_vm.imageUrl['0'] ? _vm.rules : ''},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input',{ref:"fileInput",attrs:{"type":"file","hidden":""},on:{"change":function($event){return _vm.onFileChange($event, 'target')}}}),_c('div',{class:{
      'drag-area': _vm.drag ? 'dragenter' : 'dragleave',
      'validation-error':
        errors.length > 0 || (_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName]),
    },on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDragLeave.apply(null, arguments)}}},[_c('div',{on:{"drop":function($event){return _vm.onFileChange($event, 'dataTransfer')}}},[_c('v-btn',{staticClass:"upload-btn",attrs:{"block":"","type":"button","icon":"","ripple":false},on:{"click":function($event){return _vm.openFile()}}},[(_vm.image != null)?_c('div',{staticClass:"image_preview",class:{
            'drag-area': _vm.drag ? 'dragenter' : 'dragleave',
            'validation-error':
              errors.length > 0 ||
              (_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName]),
          }},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.image,"alt":_vm.uploadText ? _vm.uploadText : _vm.$t(`dashboard.${_vm.$attrs.label}`)}})]),_c('div',{staticClass:"edit-icon-btn"},[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]):[_c('div',{staticClass:"image_add",class:{ active: _vm.isActive }},[_c('v-icon',{staticClass:"ml-3",attrs:{"color":"#45A9D5","large":""}},[_vm._v(" mdi-folder-multiple-image ")])],1)]],2)],1)]),_c('transition',{attrs:{"name":"fade","mode":"in-out"}},[_c('v-row',{staticClass:"my-0",staticStyle:{"position":"absolute"}},[(errors.length > 0)?_c('v-col',{staticClass:"error--text upload_file_error",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(`${errors[0]}`))+" ")]):_vm._e(),(_vm.serverErrors)?_c('v-col',{staticClass:"error--text upload_file_error",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.serverErrors && _vm.serverErrors[_vm.$attrs.errorName])+" ")]):_vm._e()],1)],1)]}}])},'ValidationProvider',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }