export const vendorsBlacklistRoutes = [
  {
    path: "vendors",
    to: "/vendors/blackList/vendors",
    meta: {
      title: "blackListVendors",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendorsBlacklist.heading.blackList",
          exact: true,
          link: true,
          to: "/vendors/blackList"
        },
        {
          text: "dashboard.vendorsBlacklist.heading.vendors",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/blackList/vendors/record"),
    allow_children: false
  },
  {
    path: "vendors/:id/view",
    meta: {
      title: "blackListVendors",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendorsBlacklist.heading.blackList",
          exact: true,
          link: true,
          to: "/vendors/blackList"
        },
        {
          text: "dashboard.vendorsBlacklist.heading.vendors",
          exact: true,
          link: true,
          to: "/vendors/blackList/vendors"
        },
        {
          text: "dashboard.vendorsBlacklist.heading.viewVendorsBlacklist",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/blackList/vendors/view"),
    allow_children: false
  }
];
