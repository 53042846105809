export default {
  fixedRatioSettings: {
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },
    table: {
      "#": "#",
      added_by: "تم بواسطة",
      department_name: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    labels: {
      rate: "النسبة",
    },
    placeholder: {
      enter_rate: "أضف النسبة",
    },
  },
};
