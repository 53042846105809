export const exceedFixedPercentRecordRoutes = [
  {
    path: "exceed-fixed-percentage-record",
    to: "/transactions/exceed-fixed-percentage-record",
    meta: {
      title: "exceed_fixed_percentage_record",
      permission: "percentage_fixed_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.settings",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_fixed_percentage_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/exceedFixedPercentRecord/record"),
    allow_children: false
  },
  {
    path: "exceed-fixed-percentage-record/:id/view",
    meta: {
      title: "exceed_fixed_percentage_view",
      permission: "percentage_fixed_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.settings",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_fixed_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-fixed-percentage-record"
        },
        {
          text: "dashboard.exceedFixedPercentRecord.heading.exceed_fixed_percentage_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/exceedFixedPercentRecord/view"),
    allow_children: false
  },
  {
    path: "exceed-fixed-percentage-record/:id/edit",
    meta: {
      title: "exceed_fixed_percentage_edit",
      permission: "percentage_fixed_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.settings",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_fixed_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-fixed-percentage-record"
        },
        {
          text: "dashboard.exceedFixedPercentRecord.heading.exceed_fixed_percentage_edit",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/exceedFixedPercentRecord/crud"),
    allow_children: false
  },
  {
    path: "exceed-fixed-percentage-record/add",
    meta: {
      title: "exceed_fixed_percentage_add",
      permission: "percentage_fixed_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.exceed_fixed_percentage_record",
          exact: true,
          link: true,
          to: "/transactions/exceed-fixed-percentage-record"
        },
        {
          text: "dashboard.exceedFixedPercentRecord.heading.exceed_fixed_percentage_add",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/exceedFixedPercentRecord/crud"),
    allow_children: false
  }
];
