<template>
  <v-app>
    <Notification />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import cookies from "js-cookie";

export default {
  name: "App",
  components: { Notification: () => import("@/components/Notification") },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: "ar",
        theme: cookies.get("theme") || "blue"
      },
      bodyAttrs: {
        class: `body-theme  body-theme__theme--${this.$store.getters["getThemeMode"]}`
      },
      title: `${this.$t(`dashboard.sidebar.${this.$route.meta.title}`)}`
    };
  },

  methods: {
    handleTheme() {
      const theme_name = cookies.get("theme") || "blue";
      const theme_mode = cookies.get("theme_mode") || "light";
      this.$store.dispatch("SetTheme", theme_name);
      this.$store.dispatch("SetThemeMode", theme_mode);
      this.$nextTick(() => {
        this.$vuetify.theme.themes.light = this.$store.getters["getTheme"];
      });
      this.$nextTick(() => {
        this.$vuetify.theme.themes.dark = this.$store.getters["getTheme"];
      });
      if (cookies.get("theme_mode") === "dark") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
  },
  created() {
    this.handleTheme();
  }
};
</script>
