export default {
  addedTax: {
        heading: {
          added_tax:"الضريبة المضافة",
        },
        table: {
          "#": "#",
          client_name:"اسم العميل",
          Commercial_register:"السجل التجارى",
          taxNumber:"الرقم الضريبى",
          transaction_number:"رقم المعاملة",
          value_added_percentage:"نسبة القيمة المضافة",
          transaction_value:"قيمة المعاملة",
          value_added:"القيمة المضافة",
          Date:"التاريخ",
          actions: "العمليات",
          total: "الإجمالي",
          pagination: {
            show: "عرض",
            to: "إلى",
            of: "من",
            result: "الإجمالي",
          },
        },
        buttons: {
          export: "تصدير",
          add_rate:"إضافه نسبه"
        },
        labels: {
          client_name:"اسم العميل",
          Commercial_Register:"السجل التجارى",
          transaction_number:"رقم المعاملة",
          taxNumber:"الرقم الضريبى",
          period_from: "الفترة من",
          period_to: "الفترة إلي",
          rate:"النسبه"
  
        },
        placeholder: {
          enter_CommercialRegister:"ادخل السجل التجارى",
          date_from: " ادخل الفترة من",
          date_to: "ادخل الفترة الى",
          enter_Commercial_Register:"ادخل السجل التجارى",
          taxNumber:"ادخل الرقم الضريبى",
          enter_client_name:"ادخل اسم العميل",
          Commercial_Register:"السجل التجارى",
          enter_transaction_number:"ادخل رقم المعاملة",
        },
      },
    };
    