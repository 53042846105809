export default {
  state: () => ({
    version: "publish v20",
    web_url: "",
  }),
  mutations: {
    SET_VERSION(state, version) {
      state.version = version;
    },
    SET_URL(state, url) {
      state.web_url = url;
    },
  },
  actions: {
    async SetVersionAndUrl({ commit }) {
      // const res = await this.$axios('https://api.fintechrsa.com/api/version')
      // const { version, website } = await res.data.data;
      // commit("SET_URL", website);
      // commit("SET_VERSION", version);
    },
  },
  getters: {
    getVersion: (state) => state.version,
    getUrl: (state) => state.web_url,
  },
};
