export default {
  setTheValueAddedPercentage: {
    heading: {
      add_setTheValueAddedPercentage: "إضافه نسبه",
    },
    fields: {
      type_client: "نوع العميل",
      Commercial_Register: "السجل التجارى",
      taxNumber: "الرقم الضريبى",
      client_name: "اسم العميل",
      rate: "النسبه",
      percentage: "نسبة القيمة المضافة",
      date: "تاريخ التنفيذ",
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
    },

    table: {
      "#": "#",
      added_by: "تم بواسطة",
      category_name: "إسم القسم",
      date: "تاريخ النشاط",
      description: "تفاصيل النشاط",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      add_rate: "إضافه نسبه",
      save: "حفظ",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    labels: {
      percentage: "نسبة القيمة المضافة",
      date: "تاريخ التنفيذ",
    },
    placeholder: {
      percentage: "ادخل نسبة القيمة المضافة",
      date: "ادخل تاريخ التنفيذ",
    },
    chips: {
      active: "مفعل",
      deactive: " معطل",
    },
  },
};
