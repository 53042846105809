export default {
  vendorsBlacklist: {
    fields: {
      reasonValidation: "السبب",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    heading: {
      blackList: "القائمة السوداء",
      vendors: "سجل العملاء",
      viewVendorsBlacklist: "عرض",
      historical_movment: "الحركة التاريخية",
    },
    popup: {
      buttons: {
        save: "حفظ",
        cancel: "إلغاء",
      },
      body: {
        reasonLabel: "السبب",
        exclusionVendorLabel1: "سبب استبعاد العميل",
        exclusionVendorLabel2: "من القائمة السوداء",
      },
      reasonValidation: "السبب",
    },
    table: {
      "#": "#",
      facility_name: "اسم المنشأه",
      vendor_name: "اسم العميل",
      commercial_number: "السجل التجاري / الوثيقة",
      identity_number: "رقم الهوية",
      reason: "سبب الإدراج بالقائمة",
      actions: "العمليات",
      status: "الحالة",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      employee_job: "الوظيفة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      exclusion: "استبعاد",
      view_details: "عرض",
    },
    buttons: {
      export: "تصدير",
      back: "العودة",
    },
    labels: {
      vendor_name: "اسم العميل",
      facility_name: "اسم المنشأه",
      commercial_number: "السجل التجاري / الوثيقة",
      identity_number: "رقم الهوية",
      status: "الحالة",
      reason: "السبب",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_facility_name: "ادخل اسم المنشأة",
      enter_commercial_number: "ادخل السجل التجاري / رقم الوثيقة",
      enter_identity_number: "ادخل رقم الهوية",
      select_status: "اختر الحالة",
    },
    statusOptions: {
      all: "الجميع",
      entered: "مدرجة",
      excluded: "مستبعدة",
    },
    chips: {
      entered: "مدرجة",
      excluded: "مستبعدة",
    },
  },
  suspectsList: {
    fields: {
      reasonValidation: "السبب",
      name: "الاسم",
      facility_name: "اسم المنشأة",
      ar_first_name: "الاسم الأول",
      ar_second_name: "الاسم الثاني",
      ar_third_name: "الاسم الثالث",
      ar_fourth_name: "الاسم الرابع",
      en_first_name: "الاسم الأول",
      en_second_name: "الاسم الثاني",
      en_third_name: "الاسم الثالث",
      en_fourth_name: "الاسم الرابع",
      identity_number: "رقم الهوية",
      residency_number: "رقم الإقامة",
      id_number_type: "نوع الرقم التعريفي",
      reason: "السبب",
      id_number: "الرقم التعريفي",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
    heading: {
      blackList: "القائمة السوداء",
      suspects_list: "سجل قوائم المشتبه فيهم",
      add: "اضافة",
    },
    popup: {
      buttons: {
        save: "حفظ",
        cancel: "إلغاء",
        refuse: "غير موافق",
        accept: "موافق",
      },
      body: {
        reasonLabel: "السبب",
        exclusionVendorLabel1: "سبب استبعاد العميل",
        exclusionVendorLabel2: "من القائمة السوداء",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
      },
      reasonValidation: "السبب",
    },
    table: {
      "#": "#",
      facility_name: "اسم المنشأة",
      vendor_name: "اسم العميل",
      ar_name: "الإسم باللغة العريبة",
      en_name: "الإسم باللغة الإنجليزية",
      id_number: "الرقم التعريفي",
      id_number_type: "نوع الرقم التعريفي",
      status: "الحالة",
      reason: "سبب الإدراج بالقائمة",
      actions: "العمليات",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      exclusion: "استبعاد",
    },
    buttons: {
      export: "تصدير",
      add: "اضافة مشتبه فيه",
      save: "حفظ",
      back: "عودة",
    },
    labels: {
      ar_name: "الإسم باللغة العريبة",
      en_name: "الإسم باللغة الإنجليزية",
      facility_name: "اسم المنشأة",
      id_number: "الرقم التعريفي",
      id_number_type: "نوع الرقم التعريفي",
      identity_number: "رقم الهوية",
      residency_number: "رقم الإقامة",
      banned_job: "الوظيفة الممنوع منها",
      reason: "سبب الإدارج في القائمة",
    },
    placeholder: {
      enter_facility_name: "ادخل اسم المنشأة",
      first_name: "الأول",
      second_name: "الثاني",
      third_name: "الثالث",
      fourth_name: "الرابع",
      enter_first_name: "الأول",
      enter_second_name: "الثاني",
      enter_third_name: "الثالث",
      enter_fourth_name: "الرابع",
      enter_en_first_name: "الأول",
      enter_en_second_name: "الثاني",
      enter_en_third_name: "الثالث",
      enter_en_fourth_name: "الرابع",
      enter_id_number: "ادخل رقم التعريفي",
      select_id_number_type: "اختر نوع الرقم التعريفي",
      enter_identity_number: "ادخل رقم الهوية",
      enter_residency_number: "ادخل رقم الإقامة",
      select_banned_job: "اختر الوظيفة الممنوع منها",
      enter_reason: "أدخل السبب",
    },
    statusOptions: {
      all: "الجميع",
      identity_number: "رقم الهوية",
      residency_number: "رقم الإقامة",
      passport: "جواز سفر",
      entered: "مدخلة",
      excluded: "مستبعدة",
    },
    chips: {
      entered: "مدخلة",
      excluded: "مستبعدة",
    },
  },
};
