import cards from "./cards";
import dailyTransactions from "./dailyTransactions";
import severitySettings from "./severitySettings";
import suspiciousSndicatorSettings from "./suspiciousSndicatorSettings";
import ProjectedSalesActivityAnnually from "./ProjectedSalesActivityAnnually";
import severitySettingsNationalities from "./severitySettingsNationalities";
import severitySettingsAreas from "./severitySettingsAreas";
import reports from "./reports";
import dangerTransactions from "./dangerTransactions";
import paymentServices from "./paymentServices";
import risk_ratio_settings from "./risk_ratio_settings";
import activitySeveritySettings from "./activitySeveritySettings";
import activities from "./activities";
import riskVendors from "./riskVendors";

export default {
  moneyLaundering: {
    ...cards,
    ...dailyTransactions,
    ...severitySettings,
    ...suspiciousSndicatorSettings,
    ...ProjectedSalesActivityAnnually,
    ...severitySettingsNationalities,
    ...severitySettingsAreas,
    ...reports,
    ...dangerTransactions,
    ...paymentServices,
    ...risk_ratio_settings,
    ...activitySeveritySettings,
    ...activities,
    ...riskVendors,
  },
};
