import i18n from "@/lang";
export const sadadServiceRoutes = [
  {
    path: "sadadService",
    to: "/transactions/sadadService",
    meta: {
      title: "sadad_record",
      bread_crumb: [
        {
          text: i18n.t("dashboard.sidebar.transactions"),
          exact: true,
          link: true,
          to: "/transactions",
        },
        {
          text: i18n.t("dashboard.transactions.heading.sadad_record"),
          disabled: true,
        },
      ],
    },
    component: () =>
      import("@/views/transactions/settings/sadadService/record"),
    allow_children: false,
  },
  {
    path: "sadadService/:id/view",
    meta: {
      title: "view_sadadService",
      bread_crumb: [
        {
          text: i18n.t("dashboard.sidebar.transactions"),
          exact: true,
          link: true,
          to: "/transactions",
        },
        {
          text: i18n.t("dashboard.transactions.heading.sadad_record"),
          exact: true,
          link: true,
          to: "/transactions/sadadService",
        },
        {
          text: i18n.t("dashboard.transactions.heading.view_sadadService"),
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/transactions/settings/sadadService/view"),
    allow_children: false,
  },
  {
    path: "sadadService/:id/edit",
    meta: {
      title: "edit_sadadService",
      bread_crumb: [
        {
          text: i18n.t("dashboard.sidebar.transactions"),
          exact: true,
          link: true,
          to: "/transactions",
        },
        {
          text: i18n.t("dashboard.transactions.heading.sadad_record"),
          exact: true,
          link: true,
          to: "/transactions/sadadService",
        },
        {
          text: i18n.t("dashboard.transactions.heading.edit_sadadService"),
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/transactions/settings/sadadService/crud"),
    allow_children: false,
  },

  {
    path: "sadadService/add",
    meta: {
      title: "add_sadadService",
      bread_crumb: [
        {
          text: i18n.t("dashboard.sidebar.transactions"),
          exact: true,
          link: true,
          to: "/transactions",
        },
        {
          text: i18n.t("dashboard.transactions.heading.sadad_record"),
          exact: true,
          link: true,
          to: "/transactions/sadadService",
        },
        {
          text: i18n.t("dashboard.transactions.heading.add_sadadService"),
          disabled: true,
        },
      ],
    },
    component: () => import("@/views/transactions/settings/sadadService/crud"),
    allow_children: false,
  },
];
