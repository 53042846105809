export default {
  messagesBox: {
    heading: {
      messagesBox: "صندوق الرسائل",
      view_messagesBox: "عرض صندوق الرسائل",
      historical_movement: "الحركة التاريخية",
      reply_messagesBox: "الرد علي الرسالة",
    },
    tooltip: {
      view_details: "عرض",
      reply: "رد",
    },
    buttons: {
      cancel: "إلغاء",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      search: "بحث",
      reset: "عرض الكل",
      edit: "تعديل",
    },
    placeholder: {
      enter_phone: "أدخل رقم الجوال (5xxxxxxxx)",
      select_date: "يوم/شهر/سنة",
      select_status: "اختر الحالة",
      enter_sender: "اسم المرسل",
      select_responsible_employee: "اختر الموظف المسئول",
      enter_reply: "نص الرد",
      enter_responsible_opinion: "أدخل رأي المسئول",
      enter_report_host_opinion: "أدخل رأي مضيف التقرير",
      enter_reject_reason: "أدخل سبب الرفض",
    },
    labels: {
      phone: "رقم الجوال",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      employee: "الموظف",
      status: "الحالة",
      sender: "اسم المرسل",
      date: "تاريخ الرسالة",
      message: "نص الرسالة",
      responsible_employee: "اسم الموظف المسئول",
      reply: "الرد",
      email: "البريد الإلكتروني",
      replied_text: "نص الرد",
      message_type: "نوع الرسالة",
      responsible_opinion: "رأي المسئول",
      report_host_opinion: "رأي مضيف التقرير",
      reject_reason: "سبب الرفض",
    },
    chips: {
      pending: "جديد",
      replied: "تم الرد",
      waiting: "بإنتظار الرد",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
      },
    },
    fields: {
      responsible_employee: "اسم الموظف المسئول",
      reply: "الرد",
    },
    validation: {
      required: "{field} مطلوب",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} هذا الحقل يجيب ان يكون علي الاقل {min} من الحروف",
    },
    statusOptions: {
      all: "الجميع",
      pending: "جديد",
      replied: "تم الرد",
      waiting: " بإنتظار الرد",
    },
    table: {
      "#": "#",
      job_name: "وظيفة الموظف",
      sender: "اسم المرسل",
      phone: "رقم الجوال",
      date: "التاريخ",
      document: "الوثيقة",
      message_status: "حالة الرسالة",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      total: "الإجمالي",
      no_data: "لا توجد نتائج متاحة",
      message_type: "نوع الرسالة",
      noDepartment: "بدون قسم",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
  },
};
