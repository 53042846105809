export const departmentsRoutes = [
  {
    path: "departments",
    to: "/employeesManagement/departments",
    meta: {
      title: "departments",
      permission: "departments_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          exact: true,
          link: true,
          to: "/employeesManagement"
        },
        {
          text: "dashboard.departments.heading.departments",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/departments/record"),
    allow_children: false
  },
  {
    path: "departments/add",
    meta: {
      title: "add_department",
      permission: "departments_store",

      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          disabled: false,
          to: "/employeesManagement",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.departments",
          to: "/employeesManagement/departments",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.add_department",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/departments/crud"),
    allow_children: false
  },
  {
    path: "departments/:id/edit",
    meta: {
      title: "edit_department",
      permission: "departments_update",

      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          disabled: false,
          to: "/employeesManagement",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.departments",
          to: "/employeesManagement/departments",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.edit_department",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/departments/crud"),
    allow_children: false
  },
  {
    path: "departments/:id/view",
    meta: {
      title: "view_departments",
      permission: "departments_show",

      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          disabled: false,
          to: "/employeesManagement",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.departments",
          to: "/employeesManagement/departments",
          link: true,
          exact: true
        },
        {
          text: "dashboard.departments.heading.view_department",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/employeesManagement/departments/view"),
    allow_children: false
  }
];
