export default {
  public: {
    headingHome: {
      vendors: "العملاء",
      account_request: "طلبات فتح حساب",
      vendors_black_list: "عملاء محظوريين",
      vendors_active: "عملاء مفعلين",
      transactions: "المعاملات",
      branches: "الفروع",
      success_transactions: "معاملات ناجحة",
      settlements: "تسويات",
      risk_vendors: "عملاء المخاطر",
      money_laundering: "إدارة المخاطر",
      revisionRequest: "طلبات قيد المراجعة",
      refusedRequest: "طلبات مرفوضة / ملغاه للتكرار",
      suspectedTransactions: "معاملات بها مؤشر اشتباه",
      month: "معاملات شهرية",
      year: "معاملات سنوية"
    },
    validation: {
      is_identity_number: "يجب ادخال الرقم التعريفي يبدأ ب 1",
      is_not_identity_number: "يجب ادخال الرقم التعريفي يبدأ ب 2",
      required: "هذا الحقل مطلوب",
      required_f: "هذا الحقل مطلوبة",
      max: "هذا الحقل يجب ان لا يتجاوز {max} حرف ",
      min: "هذا الحقل يجب ان لا يقل عن  {min} حرف",
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة ",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      max_value: " هذا الحقل لا يجب ان يزيد عن {max_value}",
      min_value: " هذا الحقل لا يجب ان يقل عن {min_value}",
      fixed_amount: "هذا الحقل مطلوب",
      card: "هذا الحقل مطلوب",
      activity: "هذا الحقل مطلوب",
      allowed_repeating: "هذا الحقل مطلوب",
      mimes: "هذا الحقل يجب ان يكون {mimes}",
      beforeDate: "عفوا تاريخ من يجب ان يكون قبل تاريخ الى",
      afterDate: "عفوا تاريخ إلي يجب ان يكون بعد تاريخ من",
      size: "هذا الحقل لا يجب انا يتجاوز {size} كيلو بايت",
      validIdentityNumber: "هذا الحقل يجب أن يبدأ ب 1 أو 2",
      total_percentage: "مجموع النسب يجب ان لا يتجاوز 100",
      confirmed: "كلمة المرور غير متطابقة",
      strongPassword:
        "كلمة المرور يجب يتكون من ارقام واحرف كبيرة وصغيرة و علامات خاصة"
    },
    total: "الإجمالي",
    Total_customer_dues: "اجمالي مستحقات العميل",
    Total_due_intermediary_bank: "اجمالي المستحق للبنك الوسيط",
    client: "عميل",
    sar: "ر.س",
    no_data: "لا توجد نتائج متاحة",
    without: "بدون",
    notification_count: "عدد الإشعارات",
    enter_location: "أدخل الموقع",
    ryal: "ر.س",
    hour: "ساعة",
    month: "شهر",
    day: "يوم",
    not_found: "صفحة غير موجودة",
    buttons: {
      add: "اضافة",
      edit: "تعديل",
      cancel: "إلغاء",
      Done: "موافق",
      not_agree: "غير موافق",
      total: "الإجمالي",
      client: "عميل",
      sar: "ر.س",
      all: "الجميع",
      back: "عودة",
      save: "حفظ",
      export: "تصدير",
      reset: "عرض الكل",
      pdf: "pdf",
      xlsx: "xlsx",
      next: "التالي",
      confirm: "تحقق",
      go_home: "العودة للرئيسية",
      skip: "تخطي",
      send: "إرسال"
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
      cancel_comment: "إلغاء التعليق"
    },
    pagination: {
      show: "عرض",
      to: "إلى",
      of: "من",
      result: "الإجمالي"
    },
    loading: "تحميل",
    active: "مفعل",
    in_active: " معطل",
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        cant_delete_country_related_to_employee: "لا يمكن حذف دوله مرتبطة بموظف"
      }
    }
  }
};
