export default {
  suspectedTransactions: {
    heading: {
      dangerTransactions: "معاملات تمثل خطورة (إضافة/ اعتماد تقرير)",
      suspectedTransactions: "معاملات بها مؤشر اشتباه",
      view_suspected_transactions: "عرض",
      add_suspected_transactions: "إضافة تقرير",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    table: {
      "#": "#",
      vendor_name: "اسم العميل",
      commercial_number: "السجل التجاري/ الوثيقة",
      allowed_repetitions_number: "عدد مرات التكرار المسموح به",
      repetitions_number: "عدد مرات تكرار المؤشر",
      suspicions_index: "مؤشر الاشتباه",
      transaction_number: "رقم المعاملة",
      date: "التاريخ",
      status: "الحالة",
      actions: "العمليات",
      total: "الإجمالي",
      type: "النوع",
      seller_name: "البائع",
      suspicious_indicator: "مؤشر الاشتباه",
      allowed_repeating: "عدد مرات  تكرار المؤشر  المسموح",
      actual_repeating: "عدد مرات تكرار المؤشر الفعلي",
      transaction_value: "قيمه المعاملة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      view_details: "عرض",
      add_report: "إضافة تقرير",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق",
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_number: "السجل التجاري / رقم الوثيقة",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      status: "الحالة",
      tax_number: "الرقم الضريبي",
      phone: "رقم الجوال",
      danger_degree: "درجة الخطورة",
      responsible_opinion: "رأي المسئول",
      daily_expected_transactions_value: "قيمة المبيعات المتوقعة للعميل",
      suspicion_indicator: "مؤشر اشتباه",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_number: "ادخل السجل التجاري او رقم الوثيقة",
      choose_date_from: "اختر الفترة من",
      choose_date_to: "اختر الفترة إلي",
      choose_status: "اختر الحالة",
      enter_tax_number: "ادخل الرقم الضريبي",
      enter_phone: "ادخل رقم جوال العميل (5xxxxxxxx)",
      select_danger_degree: "اختر درجة الخطورة",
      enter_suspicion_indicator: "أدخل مؤشر الاشتباه",
    },
    statusOptions: {
      all: "الجميع",
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة",
      pending: "انتظار المراجعة",
    },
    chips: {
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة",
    },
  },
};
