import financialSettlementRecord from "./financialSettlements/financialSettlementRecord";
import financialSettlementTransferSettings from "./financialSettlements/financialSettlementTransferSettings";
import serviceFeesRecord from "./financialSettlements/serviceFeesRecord";
import settlementsDurationRecord from "./financialSettlements/settlementsDurationRecord";
import successfulTransactions from "./transactions/successfulTransactions";
import failedTransactions from "./transactions/failedTransactions";
import vendorTransactions from "./transactions/vendorTransactions";
import consumersData from "./transactions/consumersData";
import exceedFixedPercentRecord from "./settings/exceedFixedPercentRecord";
import exceedExceptionalPercentRecord from "./settings/exceedExceptionalPercentRecord";
import sadadService from "./settings/sadadService";
import paymentSettings from "./settings/paymentSettings";
import settlementsTransactions from "./financialSettlements/settlementsTransactions";

export default {
  ...financialSettlementRecord,
  ...financialSettlementTransferSettings,
  ...serviceFeesRecord,
  ...settlementsDurationRecord,
  ...successfulTransactions,
  ...failedTransactions,
  ...vendorTransactions,
  ...consumersData,
  ...exceedFixedPercentRecord,
  ...exceedExceptionalPercentRecord,
  ...sadadService,
  ...paymentSettings,
  ...settlementsTransactions,
};
