<template>
  <div>
    <v-chip
      v-if="$attrs.status"
      :color="$attrs.color ? $attrs.color : 'success'"
      class="chip-container"
    >
      <span
        class="font-bold"
        :class="$attrs.textColor ? $attrs.textColor : 'success--text'"
      >
        <slot name="title">
          {{ $t(`dashboard.${$attrs.statusTitle}`) }}
        </slot>
      </span>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "GridStatus",
};
</script>