export const linksRoutes = [
  {
    path: "links",
    to: "/settings/links",
    meta: {
      title: "links",
      permission: "links_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.links.heading.links",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/links"),
    allow_children: false
  }
];
