export const branchesRoutes = [
  {
    path: "branches",
    to: "/vendors/branches",
    meta: {
      title: "branches",
      permission: "branches_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.branches.heading.branches",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/branches/record"),
    allow_children: false
  },

  // {
  //   path: "branches/add",
  //   meta: {
  //     title: "add_branch",
  // permission: "branches_store",
  //     bread_crumb: [
  //       {
  //         text: "dashboard.sidebar.vendors"),
  //         exact: true,
  //         link: true,
  //         to: "/vendors",
  //       },

  //       {
  //         text: "dashboard.branches.heading.branches"),
  //         to: "/vendors/branches",
  //         link: true,
  //         exact: true,
  //       },
  //       {
  //         text: "dashboard.branches.heading.add_branch"),
  //         disabled: true,
  //       },
  //     ],
  //   },
  //   component: () => import("@/views/vendors/branches/crud"),
  //   allow_children: false,
  // },
  {
    path: "branches/:id/edit",
    meta: {
      title: "edit_branch",
      permission: "branches_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.branches.heading.branches",
          to: "/vendors/branches",
          link: true,
          exact: true
        },
        {
          text: "dashboard.branches.heading.edit_branch",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/branches/crud"),
    allow_children: false
  },
  {
    path: "branches/:id/view",
    meta: {
      title: "view_branch",
      permission: "branches_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.branches.heading.branches",
          exact: true,
          link: true,
          to: "/vendors/branches"
        },
        {
          text: "dashboard.branches.heading.view_branch",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/branches/view"),
    allow_children: false
  }
];
