export default {
  suspectedTransactions: {
    // START:: HEADING
    heading: {},
    // START:: LABELS
    labels: {
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_percent: "نسبة الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
      vendor_phone: "جوال العميل",
      vendor_comment: "رأي العميل",
      vendor_identity: "هوية العميل",
      vendor_name: "اسم العميل",
      tax_number: "الرقم الضريبي",
      date_from: "الفترة من",
      date_to: "الفترة الي",
      suspected: "مؤشر الإشتباه",
      zero_transactions: "المعاملات الصفرية",
      same_amount_transactions: "معاملات لها نفس القيمة",
      fixed_amount: "قيمة ثابته",
      card: "بطاقة",
      responsable_risk_percent:"نسبة الخطورة المضافة من المسئول"
    },
    // START:: PLACEHOLDER
    placeholder: {
      facility_name: "أدخل اسم المنشأة",
      commercial_registration_document_no: "أدخل السجل التجاري / رقم الوثيقة",
      date_from: "أدخل الفترة من",
      date_to: "أدخل الفترة الي",
      risk_percent: "ادخل نسبة الخطورة",
      degree_of_risk: "اختر درجة الخطورة",
    },
    // START:: TABLE
    table: {
      "#": "#",
      actions: "العمليات",
      facility_name: "إسم المنشأة",
      vendor_identity: "هوية العميل",
      suspected: "مؤشر الإشتباه",
      vendor_name: "اسم العميل",
      vendor_phone: "جوال العميل",
      commercial_registration_document_no: "السجل التجاري / رقم الوثيقة",
      expected_transactions: "المعاملات المتوقعة",
      actual_transactions: "المعاملات الفعلية",
      risk_percent: "نسبة الخطورة",
      degree_of_risk: "درجة الخطورة",
      date: "التاريخ",
    },
    chips: {
      no_danger: "لا يوجد خطورة",
    },
  },
};
