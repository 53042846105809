export const paymentSettingsRoutes = [
  {
    path: "paymentSettings",
    to: "/transactions/paymentSettings",
    meta: {
      title: "password_settings_payment",
      permission: "Payment_OTP_requests_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.settings",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.password_settings_payment",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/paymentSettings/record"),
    allow_children: false
  },
  {
    path: "paymentSettings/:id/view",
    meta: {
      title: "view_password_settings_payment",
      permission: "Payment_OTP_requests_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.password_settings_payment",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions/paymentSettings"
        },
        {
          text: "dashboard.transactions.heading.view_password_settings_payment",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/paymentSettings/view"),
    allow_children: false
  },
  {
    path: "paymentSettings/:id/edit",
    meta: {
      title: "edit_password_settings_payment",
      permission: "Payment_OTP_requests_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.password_settings_payment",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions/paymentSettings"
        },
        {
          text: "dashboard.transactions.heading.edit_password_settings_payment",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/paymentSettings/crud"),
    allow_children: false
  },
  {
    path: "paymentSettings/add",
    meta: {
      title: "add_password_settings_payment",
      permission: "Payment_OTP_requests_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.password_settings_payment",
          disabled: false,
          exact: true,
          link: true,
          to: "/transactions/paymentSettings"
        },
        {
          text: "dashboard.transactions.heading.add_password_settings_payment",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/transactions/settings/paymentSettings/crud"),
    allow_children: false
  }
];
