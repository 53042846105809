export default {
  cards: {
    departments: "الأقسام",
    jobs: "الوظائف",
    employees: "الموظفين",
    severityMoneyLaundering: "إعدادات  إدارة المخاطر",
    severity_settings: "إعدادات درجات الخطورة",
    suspicious_indicator_settings: "اعدادات مؤشرات الاشتباه",
    Projected_sales_activity_annually: "إعدادات المبيعات المتوقعة للنشاط سنويا",
    Severity_settings_nationalities: "إعدادات درجات الخطورة للجنسيات",
    severity_settings_areas: "إعدادات درجات الخطورة للمناطق",
    dailyTransactions: "المعاملات اليومية للعملاء",
    dangerTransactions: "معاملات تمثل خطورة (إضافة/ اعتماد تقرير)",
    exceedTransactions: "معاملات تعدت الحد الأقصي",
    suspectedTransactions: "معاملات بها مؤشر اشتباه",
    suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
    suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
    settings: "إعدادات إدارة المخاطر",
    risk_ratio_settings: "إعدادات مؤشر المخاطر",
    activities: "الآنشطه",
    activity_severity_settings: "إعدادات درجة الخطورة للنشاط",
    follow_up: "المتابعة",
    danger_suspectedAccountsRequest:
      "طلبات إنشاء حساب تمثل خطورة (إضافة/ اعتماد تقرير)",
    Projected_sales_activity_severity_settings:
      "إعدادات المبيعات المتوقعة / درجة الخطورة للنشاط سنويا"
  }
};
