import Vue from "vue";
import Vuex from "vuex";
import errors from "./modules/errors";
import notification from "./modules/notification";
import user from "./modules/user";
import query from "./modules/query";
import version from "./modules/version";
import routes from "./modules/routes";
import tables from "./modules/tables";
import themes from "./modules/themes";
import getters from "./getters";
import locale from "./modules/locale";

Vue.use(Vuex);

export default new Vuex.Store({
  nameSpaced: false,
  modules: {
    errors,
    notification,
    user,
    query,
    routes,
    tables,
    themes,
    version,
    locale
  },
  getters: getters
});
