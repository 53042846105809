export default {
  suspectedEmployeesRequest: {
    heading: {
      dangerTransactions: "معاملات تمثل خطورة (إضافة/ اعتماد تقرير)",
      suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
      view_suspectedEmployeesRequest: "عرض",
      add_suspectedEmployeesRequest: "إضافة تقرير"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },

    table: {
      "#": "#",
      facility_name: "اسم المنشأة",
      commercial_number: "السجل التجاري/ الوثيقة",
      employee_name: "اسم الموظف",
      job: "الوظيفة",
      phone: "رقم الجوال",
      identity_number: "رقم الهوية",
      date: "التاريخ",
      status: "الحالة",
      actions: "العمليات",
      total: "الإجمالي",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    tooltip: {
      view_details: "عرض",
      add_report: "إضافة تقرير"
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      close: "غلق"
    },
    labels: {
      vendor_name: "اسم العمسل",
      employee_name: "اسم الموظف",
      commercial_number: "السجل التجاري / رقم الوثيقة",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      status: "الحالة",
      tax_number: "الرقم الضريبي",
      phone: "رقم الجوال",
      danger_degree: "درجة الخطورة",
      responsible_opinion: "رأي المسئول",
      daily_expected_transactions_value: "قيمة المبيعات المتوقعة للعميل",
      employee_identity: "هوية الموظف",
      facility_name: "اسم المنشأة",
      date: "التاريخ",
      reason: "سبب الإشتباه",
      identity_number: "رقم الهوية"
    },
    placeholder: {
      enter_vendor_name: "أدخل اسم العميل",
      enter_commercial_number: "ادخل السجل التجاري او رقم الوثيقة",
      choose_date_from: "اختر الفترة من",
      choose_date_to: "اختر الفترة إلي",
      choose_status: "اختر الحالة",
      enter_tax_number: "ادخل الرقم الضريبي",
      enter_phone: "ادخل رقم جوال العميل (5xxxxxxxx)",
      select_danger_degree: "اختر درجة الخطورة",
      enter_facility_name: "ادخل اسم المنشأة",
      enter_employee_identity: "ادخل هوية الموظف"
    },
    statusOptions: {
      all: "الجميع",
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة"
    },
    chips: {
      reviewed: "تم مراجعتها",
      waiting_review: "انتظار المراجعة"
    }
  }
};
