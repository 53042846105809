export default {
  banks: {
    buttons: {
      add: "إضافة بنك",
      export: "تصدير",
      edit: "تعديل ",
      back: "عوده",
    },
    placeholder: {
      choose_bank: "أدخل الإسم",
      choose_status: "اختر الحالة",
      enter_bank_name: "ادخل اسم البنك",
      enter_status: "ادخل الحالة",
    },
    labels: {
      bank_name: "اسم البنك",
      status: "الحالة",
    },
    table: {
      "#": "#",
      name: "اسم البنك",
      status: "الحالة",
      actions: "العمليات",
      transfer_fee_amount: "قيمة رسوم التحويل",
      transferred_money_settlements: "قيمة التسويات المالية المحولة",
    },
    heading: {
      add_bank: "إضافة بنك",
    },
    statusOptions: {
      all: "الجميع",
      vacant: "شاغرة",
      occupied: "مشغولة",
      active: "مفعل",
      in_active: " معطل",
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
    },
    msgDialog: {
      makesure_activation:
        "هل متأكد من تفعيل الحساب الذي قام بعمل محاولة مشبوه ؟ ",
      delet_sure: "هل تريد حذف صلاحية ' تعديل موظف ' ؟",
    },
    fields: {
      bank_name: "اسم البنك",
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete_bank: "هل تريد حذف بنك",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        undelete_bank_1: "لا يمكنك حذف بنك ",
        undelete_bank_2: "لوجود عميل به"
      }
    }
  }
};
