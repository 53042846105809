export const statisticsRoute = [
  {
    path: "/home",
    to: "/home",
    icon: "mdi-chart-areaspline",
    meta: {
      title: "home",
      permission: "home_index"
    },
    component: () => import("@/views/statistics")
  }
];
