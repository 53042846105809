export default {
  ReceivablesTransferSettingsForTheIntermediaryBank: {
    heading: {
      add: "اضافة",
      edit: "تعديل",
    },
    fields: {
      bank_name: "البنك",
      value: "قيمة رسوم التحويل",
      percentage: "نسبة رسوم التحويل",
      min: "الحد الأدني",
      max: "الحد الأعلي",
    },
    validation: {
      required: "{field} مطلوب",
      mimes: "{field} يجب ان تكون  {mimes}",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        unarchive_job: "لا يمكنك تعطيل قسم ' اسم القسم ' لوجود موظفين به",
      },
    },
    feesTypes: {
      fixed: "قيمة",
      percent: "نسبة",
    },
    table: {
      "#": "#",
      bank_name: "اسم البنك",
      percentage: "نسبة رسوم التحويل",
      min: "الحد الأدني",
      max: "الحد الأعلي",
      created_at: "التاريخ",
      actions: "العمليات",
      value_or_percentage: "قيمة / نسبة رسوم التحويل",
    },
    buttons: {
      export: "تصدير",
      add: "إضافة",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    labels: {
      bank_name: "اسم البنك",
      value: "قيمة رسوم التحويل",
      percentage: "نسبة رسوم التحويل",
      min: "الحد الأدني",
      max: "الحد الأعلي",
      fees_type: "نوع الرسوم",
      value_or_percentage: "قيمة / نسبة رسوم التحويل",
    },
    placeholder: {
      bank_name: "اختر البنك",
      value: "ادخل قيمة رسوم التحويل",
      percentage: "ادخل نسبة رسوم التحويل",
      min: "ادخل الحد الأدني",
      max: "ادخل الحد الأعلي",
      choose_fees_type: "اختر نوع الرسوم",
      enter_value: "أدخل القيمة",
    },
    statusOptions: {},
    chips: {
      active: "مفعل",
      deactive: " معطل",
    },
    msgDialog: {
      makesure_activation:
        "هل متأكد من تفعيل الحساب الذي قام بعمل محاولة مشبوه ؟ ",
      delet_sure: "هل تريد حذف صلاحية ' تعديل موظف ' ؟",
    },
  },
};
