export const countriesRoutes = [
  {
    path: "/settings/countries",
    to: "/settings/countries",
    meta: {
      title: "countries",
      permission: "countries_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.countries.heading.countries",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/countries/record"),
    allow_children: false
  },

  {
    path: "countries/add",
    meta: {
      title: "add_countries",
      permission: "countries_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.countries.heading.countries",
          exact: true,
          link: true,
          to: "/settings/countries"
        },
        {
          text: "dashboard.countries.heading.add_countries",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/countries/crud"),
    allow_children: false
  },
  {
    path: "countries/:id/view",
    meta: {
      title: "view_countries",
      permission: "countries_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.countries.heading.countries",
          exact: true,
          link: true,
          to: "/settings/countries"
        },
        {
          text: "dashboard.countries.heading.view_countries",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/countries/view"),
    allow_children: false
  },
  {
    path: "countries/:id/edit",
    meta: {
      title: "edit_countries",
      permission: "countries_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },

        {
          text: "dashboard.countries.heading.countries",
          exact: true,
          link: true,
          to: "/settings/countries"
        },
        {
          text: "dashboard.countries.heading.edit_countries",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/countries/crud"),
    allow_children: false
  }
];
