export default {
  transactions: {
    heading: {
      financial_settlements: "التسويات المالية",
      successful_transactions: "المعاملات الناجحه",
      incomplete_transactions: "المعاملات الغير مكتملة",
      settings: "إعدادات المعاملات",
      complete_transactions: "عمليات مكتملة التسوية",
      complete_transactions_view: "عرض",
      inprogress_transactions: "عمليات جاري تسوياتها",
      ready_transactions: "عمليات جاهزة",
      unready_transactions: "عمليات غير جاهزة",
      failed_settlements: "تسويات فاشلة",
      successful_transaction: "معاملات ناجحة",
      transferred_transactions: "معاملات تم تحويلها للعميل",
      exceed_transactions: "معاملات تعدت الحد الاقصي",
      failed_transactions: "معاملات غير مكتملة",
      "settlements-time-record": "إعدادات المدة الزمنية للتسوية المالية",
      fromHere: "من هنا",
      consumers_record: "سجل بيانات المستهلكين",
      incomplete_transaction: "معاملات غير مكتملة",
      unpaid_pills: "فواتير لم تسدد",
      sadad_record: "سجل اعدادات خدمة سداد",
      password_settings_payment: "اعدادات طلب كلمة السر لعملية الدفع",
      exceed_percentage_record: "سجل نسب الزيادة",
      add_time: "إضافة مدة زمنية للتسوية",
      add_rate: "إضافة نسبة ثابتة",
      add_exceptional_rate: "إضافة نسبة استثنائية",
      sadad_settings: "اعدادات خدمة سداد",
      password_request_settings: "اعدادات طلب رقم سري",
      subscription_fees_record: "سجل بيانات رسوم الإشتراك",
      vendors_transactions_record: "سجل طلبات عرض معاملات عميل",
      vendors_transactions_requests_record: "طلبات عرض معاملات العميل المرسلة",
      risk_vendors: "عملاء المخاطر",
      subscription_fees_settings: "إعدادات رسوم خدمة الإشتراك",
      activate_subscription_fees: "تفعيل رسوم خدمة الإشتراك",
      vendor_transactions_record: "سجل طلبات عرض معاملات عميل",
      active_transactions: "معاملات مفعلة ولم يم تحويلها للعميل",
      inActive_transactions: "معاملات لم يتم تفعيلها",
      expired_pills: "فواتير لم تدفع منتهية",
      transactions: "المعاملات",
      exceed_fixed_percentage_record:
        "إعدادات نسب الزيادات الثابتة عن الحد الأقصي",
      exceed_exceptional_percentage_record:
        "إعدادات نسب الزيادات الإستثنائية عن الحد الأقصي",
      view_sadadService: "عرض",
      edit_sadadService: "تعديل",
      add_sadadService: "إضافة",
      view_password_settings_payment: "عرض",
      edit_password_settings_payment: "تعديل",
      add_password_settings_payment: "إضافة",
      historical_movment: "الحركة التاريخية",
      financial_settlements_record: "سجل التسويات المالية"
    },
    cards: {
      transferred_transactions: "معاملات تم تحويلها للعميل",
      active_transactions_untransferred: "معمالات مفعله ولم يتم تحويلها",
      inactive_transactions: "معاملات لم يتم تفعيلها",
      exceed_transactions: "معاملات تعدت الحد الاقصي",
      failed_transactions: "معاملات غير مكتملة",
      unpaid_pills: "فواتير لم تسدد",
      expired_pills: "فواتير لم تدفع منتهية",
      setting_time_settlement: "إعدادات المدة الزمنية للتسوية",
      consumers_record: "سجل بيانات المستهلكين",
      exceed_percentage_record: "سجل نسب الزيادة عن الحد الأقصي",
      sadad_record: "سجل اعدادات خدمة سداد",
      password_settings_payment: "اعدادات طلب كلمة السر لعملية الدفع",
      subscription_fees_record: "سجل بيانات رسوم الإشتراك",
      vendors_transactions_record: "سجل طلبات عرض معاملات عميل",
      vendors_transactions_requests_record: "طلبات عرض معاملات العميل المرسلة",
      financial_settlements_record: "سجل التسويات المالية",
      services_fees_record: "سجل بيانات رسوم خدمات",
      successful_transactions: "معاملات ناجحة",
      exceed_fixed_percentage_record:
        "إعدادات نسب الزيادات الثابتة عن الحد الأقصي",
      exceed_exceptional_percentage_record:
        "إعدادات نسب الزيادات الاستثنائية عن الحد الأقصي",
      record_time_settlement: "إعدادات المدة الزمنية للتسوية",
      risk_vendors: "عملاء المخاطر"
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_settlement_number: "ادخل رقم التسوية",
      enter_date_from: "اختر الفترة من",
      enter_date_to: "اختر الفترة إلي",
      enter_transaction_number: "ادخل رقم العاملة",
      enter_document_number: "ادخل رقم الوثيقة",
      enter_last_iban_number: "ادخل اخر اربع ارقام",
      select_payment_service: "اختر خدمات الدفع",
      enter_pill_number: "ادخل رقم الفاتورة",
      select_period: "اختر الفترة الزمنية",
      enter_duration: "أدخل المدة الزمنية",
      enter_rate: "اضف النسبة",
      enter_reason: "أدخل السبب",
      enter_min_limit: "اضف الحد الأدنى",
      enter_max_limit: "اضف الحد الأعلى",
      select_type: "اختر النوع",
      select_payment_method: "اختر خدمة الدفع",
      enter_required_amount: "اضف القيمة",
      select_service: "اختر الخدمة",
      select_status: "اختر الحالة",
      enter_required_fees: "ادخل القيمة",
      select_branch: "اختر الفرع",
      enter_payment_number: "ادخل رقم السداد",
      enter_phone: "أدخل رقم الجوال (5xxxxxxxx)"
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري / رقم الوثيقة",
      settlement_number: "رقم التسوية",
      date_from: "الفترة من",
      date_to: "الفترة إلي",
      transaction_number: "رقم المعاملة",
      document_number: "رقم الوثيقة",
      last_iban_number: "اخر اربع أرقام من بطاقة الدفع",
      fixed_rate: "نسبة ثابتة",
      exceptional_rate: "نسبة اثتسنائية",
      payment_service: "خدمات الدفع",
      pill_number: "رقم الفاتورة",
      period: "الفترة الزمنية",
      duration: "المدة الزمنية كل",
      rate: "النسبة",
      reason: "السبب",
      min_limit: "الحد الأدنى",
      max_limit: "الحد الأعلى",
      type: "النوع",
      payment_method: "خدمة الدفع",
      required_amount: "الملبغ المطلوب أكبر من",
      service: "الخدمة",
      status: "الحالة",
      required_fees: "قيمة الرسوم المطلوبة",
      create_commercial_account_service:
        "خدمة إشتراك لإنشاء حساب من نوع سجل تجاري",
      create_document_account_service:
        "خدمة اشتراك لإنشاء حساب من نوع سجل وثيقة",
      account_update_service: "خدمة تعديل بيانات",
      branch: "الفرع",
      payment_number: "رقم السداد",
      phone: "رقم الجوال",
      value: "القيمة"
    },
    table: {
      "#": "#",
      date_time: "تاريخ / وقت الإنشاء",
      settlement_number: "رقم التسوية",
      vendor_name: "اسم العميل",
      Commercial_record: "السجل التجاري/الوثيقة",
      total_transactions: "اجمالي المعاملات",
      rasid_jack_rights: "مستحقات رصيد جاك",
      financial_settlement: "التسوية المالية",
      branch: "الفرع",
      seller_ip: "رقم تعريف جهاز البائع",
      tax_number: "الرقم الضريبي",
      transaction_number: "رقم المعاملة",
      transaction_value: "قيمة المعاملة",
      cutting_dues: "المستحقات المستقطعة",
      vendor_owed_value: "القيمة المستحقة للعميل",
      refuse_date: "تاريخ / وقت الرفض",
      pill_number: "رقم الفاتورة",
      pill_value: "قيمة الفاتورة",
      date: "التاريخ",
      duration: "المدة الزمنية",
      payment_card: "بطاقة الدفع",
      consumer_name: "اسم المستهلك",
      phone: "الجوال",
      email: "البريد الإلكتروني",
      message_status: "حالة الإرسال",
      add_date: "تاريخ الإضافة",
      min_limit: "الحد الأدنى",
      max_limit: "الحد الأعلى",
      value: "القيمة",
      rate: "النسبة",
      occasion: "المناسبة",
      birth_ad: "ميلادي",
      birth_hijri: "هجري",
      execution_date: "تاريخ التنفيذ من / إلي",
      creating_date: "تاريخ الإنشاء",
      payment_service: "خدمه الدفع",
      required_amount: "المبلغ المطلوب يكون أكبر من",
      company_name: "اسم المنشأة",
      service_type: "نوع الخدمة",
      fees_value: "قيمة رسوم الخدمة",
      status: "الحالة",
      total_fees: "الإجمالي لقيم رسوم الخدمة",
      responsable_person: "اسم المسؤل المعتمد",
      responsable_person_identity: "هوية المسؤل المعنمد",
      reason: "السبب",
      bank: "البنك",
      iban: "الآيبان",
      payment_type: "نوع خدمة الدفع",
      payment_number: "رقم السداد",
      actions: "العمليات",
      total: "الإجمالي",
      no_data: "لا توجد نتائج متاحة",
      added_by: "تم بواسطة",
      department: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      type: "النوع",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    statusOptions: {
      all: "الجميع",
      rate: "النسبة",
      value: "القيمة",
      maada: "مدي"
    },
    buttons: {
      add_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      add_settings: "إضافة اعدادات",
      add_payment: "اعدادات خدمة سداد",
      add_rate: "إضافة نسبة",
      add_service_fees: "إضافة رسوم خدمة",
      edit: "تعديل",
      add_password: "اعدادات طلب رقم سري"
    },
    chips: {
      send_done: "تم الإرسال",
      send_fail: "فشل الإرسال",
      deducted_done: "تم استقطاعها",
      deducted_fail: "لم تستقطع",
      confirmed: "مقبول",
      refused: "مرفوض"
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
      transfer: "تحويل التسوية",
      confirm: "قبول",
      refuse: "رفض"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        confirmedVendorContent1: "هل متأكد من عرض معاملات العميل",
        refusedVendorContent1: "هل متأكد من رفض معاملات العميل",
        vendorContentBody: "لإدارة المخاطر؟"
      },
      reasonLabel: "سبب الرفض",
      reasonValidation: "السبب",
      refuseAuthLabel: "سبب الإلغاء",
      refuseAuthValidation: "سبب الإلغاء",
      revisionReasonLabel: "طلب إعادة مراجعة",
      revisionReasonValidation: "إعادة مراجعة"
    }
  }
};
