export const activitySeveritySettingsRoutes = [
  {
    path: "activity-severity-settings",
    to: "/money-laundering/activity-severity-settings",
    meta: {
      title: "activitySeveritySettings",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.activitySeveritySettings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/activitySeveritySettings/record"),
    allow_children: false
  },

  {
    path: "activity-severity-settings/add",
    meta: {
      title: "add_activitySeveritySettings",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.activitySeveritySettings",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/activity-severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.add_activitySeveritySettings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/activitySeveritySettings/crud"),
    allow_children: false
  },
  {
    path: "activity-severity-settings/:id/view",
    meta: {
      title: "view_activitySeveritySettings",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.activitySeveritySettings",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/activity-severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.view_activitySeveritySettings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/activitySeveritySettings/view"),
    allow_children: false
  },
  {
    path: "activity-severity-settings/:id/edit",
    meta: {
      title: "edit_activitySeveritySettings",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.activitySeveritySettings",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/activity-severity-settings"
        },
        {
          text: "dashboard.moneyLaundering.activitySeveritySettings.heading.edit_activitySeveritySettings",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/activitySeveritySettings/crud"),
    allow_children: false
  }
];
