<script>
import { isEqual } from "lodash";
export default {
  name: "ExtendableComponent",
  beforeRouteLeave(to, from, next) {
    // const self = this.$children && this.$children[0];
    if (!this["isFilled"]) {
      next();
    } else {
      if (!this.confirmDialog) {
        this.backDialog = true;
        this.redirect = to.path;
        this.$eventBus.$on("on-sidebar-route-clicked", (route) => {
          this.redirect = route;
          this.$eventBus.$on("on-backDialog-confirmed", () => {
            next();
          });
        });
        this.$eventBus.$on("on-backDialog-confirmed", () => {
          next();
        });
      } else {
        next();
      }
    }
  },
  data() {
    return {
      backDialog: false,
      redirect: ""
    };
  },
  methods: {
    backToPage() {
      if (!this.isFilled) {
        this.$router.push(this.redirect);
      } else {
        this.backDialog = true;
      }
    }
  },
  computed: {
    isFilled() {
      return !isEqual(this.form, this.comparedForm);
    }
  },
  watch: {
    $route: {
      handler({ hash }) {
        if (hash) {
          this.redirect = hash.replace("#", "/");
        } else {
          this.redirect = "/";
        }
      },
      immediate: true
    }
  }
};
</script>
