export default {
  paymentSettings: {
    heading: {
      exceed_exceptional_percentage_add: "إضافة نسبة",
      exceed_exceptional_percentage_edit: "تعديل نسبة",
      exceed_exceptional_percentage_view: "عرض نسبة",
      historical_movment: "الحركه التاريخية",
    },
    labels: {
      rate: "النسبة",
      occasion: "المناسبة",
      execution_date: "تاريخ التنفيذ من / إلي",
      reason: "السبب",
      ad: "ميلادي",
      hijri: "هجري",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
    },
    placeholder: {
      enter_rate: "أدخل النسبة",
      enter_reason: "أدخل السبب",
      enter_period: "يوم / شهر / سنة",
    },
    table: {
      "#": "#",
      percent: "النسبة",
      occasion: "المناسبة",
      ad: "ميلادي",
      hijri: "هجري",
      execution_date: "تاريخ التنفيذ من / إلي",
      creating_date: "تاريخ الإنشاء",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      no_data: "لا توجد نتائج متاحة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      add_rate: "إضافة نسبة",
    },
    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف",
    },
    chips: {
      send_done: "تم الإرسال",
      send_fail: "فشل الإرسال",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    statusOptions: {
      all: "الجميع",
    },
  },
};
