export const financialSettlementTransferSettingsRoutes = [
  {
    path: "financial-settlement-transfer-settings",
    to: "/transactions/financial-settlement-transfer-settings",
    meta: {
      title: "financialSettlementTransferSettings",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.financialSettlementTransferSettings.cards.financialSettlementTransferSettings",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementTransferSettings/crud"
      ),
    allow_children: false
  }
];
