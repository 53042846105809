export default {
  dailyTransactions: {
    heading: {
      dailyTransactions_record: "المعاملات اليومية للعملاء",
      dailyTransactions_view: "عرض  معاملات عميل ",
      dailyTransactions_askview: "طلب عرض  معاملات عميل",
      dailyTransactions_view_details: "عرض",
      dangerous_vendors: "موظفين ذوي خطورة",
      change_risk_percent: "تغيير نسبة الخطورة",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
      },
    },
    table: {
      "#": "#",
      facility_name: "المنشاة/المسمى المهنى",
      vendor_type: "نوع العميل",
      severity_index: "مؤشر الخطورة",
      commercial_record_doc: "السجل التجاري / رقم الوثيقة",
      transaction_No: "رقم المعاملة",
      transaction_value: "قيمة المعاملة",
      expected_total_transactions: "إجمالى المبيعات المتوقعة",
      actual_total_transactions: "إجمالى المبيعات الفعلية",
      vendor_dangerous: "درجه الخطورة",
      activity: "النشاط",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      actions: "العمليات",
      total: "الإجمالي",
      employee_job: "وظيفة الموظف",
      employee_name: "اسم الموظف",
      identity: "الهوية",
      job: "الوظيفة",
      branch: "الفرع",
      seller_name: "اسم البائع",
      seller_identity: "هوية البائع",
      seller_app_num: "رقم تطبيق البائع",
      transaction_date: "الوقت / تاريخ انشاء المعاملة",
      status: "الحالة",
      risk_ratio: "درجة الخطورة الممنوحة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      view_ask: "طلب عرض",
      revision: "جاري المراجعة",
      view_details: "عرض",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      send: "ارسال",
      change_dangerous: "تغيير نسبة الخطورة",
    },
    labels: {
      facility_name: "المنشاة/المسمى المهنى",
      vendor_type: "نوع العميل",
      commercial_record_doc: "السجل التجاري / رقم الوثيقة",
      activity: "النشاط",
      transaction_No: "رقم المعاملة",
      vendor_dangerous: "درجه خطورة العميل",
      code: "كود الطلب",
      vendor_cat: "تصنيف العميل",
      responsible_employee: "اسم المسئول المعتمد",
      responsible_employee_identity: "هوية المسئول المعتمد",
      transaction_value: "قيمة المعاملة",
      expected_total_transactions: "إجمالى المبيعات المتوقعة",
      actual_total_transactions: "إجمالى المبيعات الفعلية",
      date: "التاريخ والوقت",
      reason: "السبب",
      dangerous_degree: "درجة الخطورة",
      dangerous_employees_count: "عدد الموظفين ذوي الخطورة",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      risk_percent: "نسبة الخطورة",
      status: "الحالة",
      risk_ratio: "درجة الخطورة الممنوحة",
      vendor_name: "اسم العميل",
      vendor_identity: "هوية العميل",
      severity_index: "مؤشر الخطورة",
      seller_name: "اسم البائع",
    },
    placeholder: {
      enter_facility_name: "ادخل اسم المنشأه",
      select_vendor_type: "اختر نوع العميل",
      enter_commercial_record_doc: "ادخل السجل التجاري أو رقم الوثيقة",
      select_activity: "اختر النشاط",
      enter_transaction_No: "ادخل رقم المعاملة",
      select_vendor_dangerous: "اختر درجه خطورة العميل",
      enter_reason: "ادخل السبب",
      select_period_from: "اختر الفترة من",
      select_period_to: "اختر الفترة إلي",
      select_dangerous: "اختر نسبة الخطورة ",
      enter_risk_percent: "ادخل نسبة الخطورة",
      select_status: "اختر الحالة",
      enter_risk_ratio: "ادخل درجة الخطورة",
      enter_severity_index: "أدخل مؤشر الخطورة",
      enter_seller_name: "أدخل اسم البائع",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
      high_danger: "شديد الخطورة",
      mid_danger: "متوسط الخطورة",
      low_danger: "ضعيف الخطورة",
      commercial_record: "سجل تجاري",
      free_work_document: "وثيقة عمل حر",
      document: "وثيقة",
      profession_practice_document: "وثيقة مزاولة مهنة",
      certified_commerical_record: "سجل التجاري (تفويض معتمد)",
      electronic_commerical_record: "سجل التجاري (تفويض الكترونى)",
    },
    chips: {
      no_danger: "لا يوجد خطورة",
      active: "مفعل",
      in_active: "معطل",
    },
  },
};
