export const settlementsTransactionsRoutes = [
  {
    path: "financial-settlements/settlementsTransactions/:id/view",
    to: "/transactions/financial-settlements/settlementsTransactions",
    meta: {
      title: "financialSettlement_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements_record",
          exact: true,
          link: true,
          to: "/transactions/financial-settlements"
        },
        {
          text: "dashboard.settlementsTransactions.heading.financialSettlement_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementRecord/settlementsTransactions/record"
      ),
    allow_children: false
  },

  {
    path: "settlementsTransactions/:id/view",
    meta: {
      title: "view_financialSettlement",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions/financial-settlements"
        },
        {
          text: "dashboard.financialSettlementRecord.heading.settlements_transaction_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementRecord/settlementsTransactions/view"
      ),
    allow_children: false
  }
];
