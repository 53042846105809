<template>
  <main class="form-dialog">
    <v-dialog
      class="form-dialog__dialog"
      :max-width="width"
      v-model="dialog"
      persistent
    >
      <v-card :max-width="width" class="form-dialog__card">
        <v-card-title class="primary--text" style="height: 100px">
          <v-avatar size="120" class="form-dialog__card--avatar">
            <slot name="icon">
              <v-img
                contain
                :src="require('@/assets/imgs/upload_img.png')"
                alt="photo"
              />
            </slot>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle
          class="d-flex justify-center primary--text"
          style="font-size: 16px"
        >
          <slot name="title" />
        </v-card-subtitle>
        <v-card-text>
          <slot name="content" />
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <slot name="actions">
            <v-btn
              class="primary"
              @click.prevent="$emit('on-submit')"
              :loading="$attrs.loading"
            >
              {{ buttonText }}
            </v-btn>
            <v-btn class="dark--1" @click="onCloseDialog">
              {{ $t("dashboard.public.buttons.cancel") }}
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
export default {
  name: "FormDialog",
  inheritAttrs: false,
  props: {
    width: {
      type: Number,
      default: 600
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  created() {
    this.handleDialog();
  },
  methods: {
    handleDialog() {
      this.$eventBus.$on("toggle-dialog", () => (this.dialog = !this.dialog));
    },
    onCloseDialog() {
      this.dialog = !this.dialog;
      this.$emit("on-close");
      this.$eventBus.$emit("on-dialog-closed");
    }
  },
  computed: {
    buttonText() {
      if (!this.isEdit) {
        return this.$t("dashboard.public.buttons.add");
      } else {
        return this.$t("dashboard.public.buttons.edit");
      }
    }
  }
};
</script>
