export default {
  successfulTransactions: {
    heading: {
      successfulTransactions: "معاملات ناجحة",
      successful_transactions_view: "عرض",
      successful_transactions_edit: "تعديل",
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري / رقم الوثيقة",
      transaction_number: "رقم المعاملة",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      status: "الحالة",
      branch: "الفرع",
      date_time: "الوقت والتاريخ",
      vendor_device_ip: "رقم تعريف جهاز البائع",
      phone: "رقم الجوال",
      tax_number: "الرقم الضريبى",
      bank: "البنك",
      iban: "الايبان",
      transaction_value: "قيمة المعاملة",
      service_pay_type: "نوع خدمة الدفع",
      vendor_deducted_dues: "مستحقات العميل",
      total_transactions: "إجمالي الكلى لقيمة المعاملات",
      total_receivables_amount: "الاجمالى الكلى لقيمة المستحقات",
      paymentType: "نوع الدفع",
      vendor_amount: "مستحقات العميل",
      jack_dues_amount: "مستحقات رصيد جاك",
      payment_gateway_dues_amount: "مستحقات خدمة الدفع",
      intermediary_bank_dues_amount: "مستحقات البنك الوسيط",
      vat_amount: "نسبة القيمة المضافة",
      value_added_tax: "قيمة الضريبة المضافة",
      jack_balance_earnings: "أرباح رصيد جاك",
      seller_name: "اسم البائع",
    },
    placeholder: {
      enter_vendor_name: "أدخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_transaction_number: "ادخل رقم العاملة",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_status: "اختر الحالة",
      select_vendor_name: "اختر اسم العميل",
      select_paymentType: "اختر نوع الدفع",
      select_service_pay_type: "اختر نوع خدمة الدفع",
      select_seller_name: "اختر اسم البائع",
    },
    table: {
      "#": "#",
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري/الوثيقة",
      transaction_number: "رقم المعاملة",
      transaction_value: "قيمة المعاملة",
      vendor_rights: "مستحقات العميل",
      receivables_withheld: "المستحقات المستقطعة",
      status: "الحالة",
      date_time: "الوقت والتاريخ",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      seller_name: "اسم البائع",
      no_data: "لا توجد نتائج متاحة",
      paymentType: "نوع الدفع",
      branch: "الفرع",
      serviceType: "نوع خدمة الدفع",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      client_mechanism_transfer: "تحويل ألية العميل",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
    },
    tooltip: {
      view_details: "عرض",
    },
    chips: {
      deducted: "تم استقطاعها",
      un_deducted: "لم تستقطع",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      transferred: "تم تحويلها للعميل",
      excess_limit: "تعدت الحد الأقصي",
      ready: "مفعلة ولم يتم تحويل",
      not_ready: "لم يتم تفعيلها للتحويل",
      un_active: "غير مفعل",
      cash: "كاش",
      credit: "الكتروني",
    },
  },
};
