<template>
  <v-textarea
    v-bind="$attrs"
    v-on="$listeners"
    oninput="this.value= this.value&&this.value.replace(/[^a-zA-Z0-9 + \u0621-\u064A\u0660-\u0669g]/g, '').replace(/\+/g,'')"
    @focus.prevent
    @copy.prevent
    @paste.prevent
    @drag.prevent
    @drop.prevent
  ></v-textarea>
</template>

<script>
export default {
  name: "TextInput"
};
</script>
