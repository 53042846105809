export default {
  financialSettlementRecord: {
    heading: {
      financialSettlementRecord: "سجل التسويات المالية",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      historical_movment: "الحركه التاريخية",
      financialSettlement_view: "عرض",
      settlements_transaction_view: "عرض المعاملة",
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري / رقم الوثيقة",
      settlement_number: "رقم التسوية",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      creating_date_from: "تاريخ الإنشاء من",
      creating_date_to: "تاريخ الإنشاء إلي",
      status: "الحالة",
      duration: "المدة الزمنية كل",
      period: "الفترة الزمنية",
      total_transactions: "اجمالي قيمة المعاملات",
      rasid_jack_rights: "مستحقات رصيد جاك",
      financial_settlement_value: "قيمة التسوية المالية",
      date_time: "الوقت والتاريخ",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_settlement_number: "ادخل رقم التسوية",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_status: "اختر الحالة",
      enter_duration: "أدخل المدة الزمنية",
      select_period: "اختر الفترة الزمنية",
      select_vendor_name: "اختر اسم العميل",
      enter_creating_date_from: "اختر تاريخ الإنشاء من",
      enter_creating_date_to: "اختر تاريخ الإنشاء إلي",
    },
    table: {
      "#": "#",
      deductions_amount: "المستحقات المستقطعة",
      settlement_number: "رقم التسوية",
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري/الوثيقة",
      total_transactions: "اجمالي المعاملات",
      rasid_jack_rights: "مستحقات رصيد جاك",
      financial_settlement: "التسوية المالية",
      status: "الحالة",
      date_time: "الوقت والتاريخ",
      created_at: "تاريخ الانشاء",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      actions: "العمليات",
      transfer_date: "تاريخ التحويل",
      complete_transfer_date: "تاريخ اكتمال التحويل",
      no_data: "لا توجد نتائج متاحة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      client_mechanism_transfer: "تحويل ألية العميل",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة",
      account_transfer: "تحويل للحساب",
    },
    tooltip: {
      view_details: "عرض",
      transfer: "تحويل",
    },
    chips: {},
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        confirm_transfer: "هل انت متأكد من اتمام عملية التحويل؟",
        code_confirm_transfer: "يرجي ادخال الكود التأكيد لاتمام عملية التسوية",
      },
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: "معطل",
      day: "يوم",
      month: "شهر",
      year: "سنة",
      completed: "مكتملة",
      loading: "جاري تحويلها",
      not_ready: "غير جاهزة",
      ready: "جاهزة",
      failed: "فشلت",
    },
  },
};
