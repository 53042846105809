export default {
  settlementsTransactions: {
    heading: {
      financialSettlementRecord: "سجل التسويات المالية",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      historical_movment: "الحركه التاريخية",
      financialSettlement_view: "عرض",
    },
    labels: {
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري / رقم الوثيقة",
      settlement_number: "رقم التسوية",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
      status: "الحالة",
      duration: "المدة الزمنية كل",
      period: "الفترة الزمنية",
      total_transactions: "اجمالي قيمة المعاملات",
      rasid_jack_rights: "مستحقات رصيد جاك",
      financial_settlement_value: "قيمة التسوية المالية",
      date_time: "الوقت والتاريخ",
      branch: "الفرع",
      device_ip: "رقم تعريف جهاز البائع",
      phone: "رقم الجوال",
      tax_number: "الرقم الضريبى",
      tax_value: "قيمة المعاملة",
      service_payment_type: "نوع خدمة الدفع",
      vendor_deductible_dues: "مستحقات العميل",
      deductible_dues: "المستحقات المستقطعة",
      bank: "البنك",
      iban: "الأيبان",
      transaction_number: "رقم المعاملة",
      transaction_value: "قيمة المعاملة",
      vendor_amount: "مستحقات العميل",
      jack_dues_amount: "مستحقات رصيد جاك",
      payment_gateway_dues_amount: "مستحقات خدمة الدفع",
      intermediary_bank_dues_amount: "مستحقات البنك الوسيط",
      vat_amount: "نسبة القيمة المضافة",
      settlement_amount: "التسوية المالية",
      transferred_amount: "ما تم تحويله",
      remained_amount: "الباقي من التحويل",
      available_transfer_amount: "المتاح للتحويل",
    },
    placeholder: {
      enter_vendor_name: "ادخل اسم العميل",
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      enter_settlement_number: "ادخل رقم التسوية",
      enter_period_from: "اختر الفترة من",
      enter_period_to: "اختر الفترة إلي",
      select_status: "اختر الحالة",
      enter_duration: "أدخل المدة الزمنية",
      select_period: "اختر الفترة الزمنية",
      select_vendor_name: "اختر اسم العميل",
      enter_transaction_number: "أدخل رقم المعاملة",
    },
    table: {
      "#": "#",
      transaction_value: "قيمة المعاملة",
      transaction_number: "رقم المعاملة",
      vendor_dues: "مستحقات العميل",
      deductible_dues: "المستحقات المستقطعة",
      settlement_number: "رقم التسوية",
      vendor_name: "اسم العميل",
      commercial_record: "السجل التجاري/الوثيقة",
      total_transactions: "اجمالي المعاملات",
      rasid_jack_rights: "مستحقات رصيد جاك",
      financial_settlement: "التسوية المالية",
      status: "الحالة",
      date_time: "الوقت والتاريخ",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      actions: "العمليات",
      no_data: "لا توجد نتائج متاحة",
      seller_name: "اسم البائع",
      branch: "الفرع",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    buttons: {
      export: "تصدير",
      client_mechanism_transfer: "تحويل ألية العميل",
      add_settlement_time: "إضافة مدة زمنية للتسوية",
      save: "حفظ",
      back: "عودة",
      account_transfer: "تحويل للحساب",
    },
    tooltip: {
      view_details: "عرض",
      transfer: "تحويل",
    },
    chips: {},
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: "معطل",
      day: "يوم",
      month: "شهر",
      year: "سنة",
      completed: "مكتملة",
      loading: "جاري تحويلها",
      not_ready: "غير جاهزة",
      ready: "جاهزة",
      failed: "فشلت",
      success: "ناجحة",
      not_completed: "غير مكتملة",
      transferred: "تم تحويلها للعميل  ",
      excess_limit: "تعدت الحد الأقصي",
      ready_trransaction: "مفعلة ولم يتم تحويلها للعميل",
      not_ready_transaction: "لم يتم تفعيل التحويل",
    },
  },
};
