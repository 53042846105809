export const usersRoute = [
  {
    path: "/profile",
    to: "/profile",
    name: "profile",
    meta: {
      title: "profile",
      bread_crumb: [
        {
          text: "dashboard.sidebar.profile",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/users/profile/record"),
    allow_children: true
  }
  // {
  //   path: "/profile/verifyCode",
  //   to: "/profile/verifyCode",
  //   name: "verifyCode",
  //   meta: {
  //     title: "profile",
  //     bread_crumb: [
  //       {
  //         text: "dashboard.sidebar.profile"),
  //         exact: true,
  //         link: true,
  //         to: "/profile",
  //       },
  //     ],
  //   },
  //   component: () => import("@/views/users/profile/verifyCode"),
  //   allow_children: true,
  // },
];
