export const financialSettlementRecordRoutes = [
  {
    path: "financial-settlements",
    to: "/transactions/financial-settlements",
    meta: {
      title: "financialSettlementRecord",
      permission: "settlements_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.financialSettlementRecord.heading.financialSettlementRecord",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementRecord/record"
      ),
    allow_children: false
  },
  {
    path: "financial-settlements/add",
    meta: {
      title: "add_settlement_time",
      permission: "settlements_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.financialSettlementRecord.heading.financialSettlementRecord",
          to: "/transactions/financial-settlements",
          link: true,
          exact: true
        },
        {
          text: "dashboard.financialSettlementRecord.heading.add_settlement_time",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementRecord/crud"
      ),
    allow_children: false
  },
  {
    path: "financial-settlements/:id/view",
    meta: {
      title: "view_financialSettlement",
      permission: "settlements_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.transactions",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.transactions.heading.financial_settlements",
          exact: true,
          link: true,
          to: "/transactions"
        },
        {
          text: "dashboard.financialSettlementRecord.heading.financialSettlementRecord",
          to: "/transactions/financial-settlements",
          link: true,
          exact: true
        },
        {
          text: "dashboard.financialSettlementRecord.heading.financialSettlement_view",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/transactions/financialSettlements/financialSettlementRecord/view"
      ),
    allow_children: false
  }
];
