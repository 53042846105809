<template>
  <ValidationProvider
    ref="ValidationProvider_ref"
    v-slot="{ errors }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      :attrs="{
        'error-messages':
          (serverErrors && serverErrors[$attrs.errorName]) || errors,
      }"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: "FormGroup",
  inheritAttrs: false,
  methods: {
    async isValid() {
      const { valid } = await this.$refs.ValidationProvider_ref.validate();
      return Promise.resolve(valid);
    },
    validateField(callbackFunction) {
      return this.$refs.ValidationProvider_ref.validate().then(({ valid }) => {
        if (valid) {
          callbackFunction();
        }
      });
    },
    resetValidationInput() {
      this.$refs.ValidationProvider_ref.reset();
    },
  },
};
</script>
