<template>
  <ValidationProvider
    :name="name"
    :rules="rules"
    ref="editor"
    v-bind="$attrs"
    v-on="$listeners"
    v-slot="{ errors }"
  >
    <vue-editor
      :class="errors.length > 0 ? 'has_error' : ''"
      @text-change="onTextChange"
      :editorToolbar="customToolbar"
      id="editor"
      v-model="content"
      ref="CustomEditor"
    >
    </vue-editor>
    <v-row class="my-0">
      <v-col
        cols="12"
        class="error--text upload_file_error"
        v-if="errors.length > 0"
      >
        {{ $t(`${errors[0]}`) }}
      </v-col>
      <v-col
        cols="12"
        class="error--text upload_file_error"
        v-if="serverErrors"
      >
        {{ serverErrors && serverErrors[$attrs.errorName] }}
      </v-col>
    </v-row>
  </ValidationProvider>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },
  props: {
    rules: {
      type: [Object, String],
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      type: null,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "strike", "link"],
        ["image"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "left" },
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ direction: "rtl" }],
      ],
    };
  },

  methods: {
    setDirection() {
     
    },

    //limit number of letters in editor
    onTextChange() {
      if (this.$refs.CustomEditor) {
        const quill = this.$refs.CustomEditor.quill,
          len = quill.getLength();
        if (len > this.limit) {
          // quill.deleteText(this.limit, len);
        }

      //    const appClass = document.getElementById("app");
      // if (appClass.classList.contains("v-application--is-rtl")) {
      //   const quill = this.$refs.CustomEditor.quill;
      //           quill.format('align', 'right');

      // }
      }
    },
  },
  computed: {
    content: {
      get() {
        if (this.value) {
          return this.value;
        } else {
          return this.value;
        }
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
  },
  created() {

    this.setDirection();
  },
};
</script>

<style lang="scss">
// .v-application--is-rtl .ql-editor {
//   /* direction: rtl; */
//   text-align: right;
// }

// .ql-editor .ql-align-right {
//   text-align: right;
// }

#editor {
  max-height: 300px;
  resize: none;
  overflow: scroll;
  font-weight: 300;
}
.has_error {
  .ql-container.ql-snow {
    border-color: red;
  }
  .ql-toolbar.ql-snow {
    border-color: red;
  }
}
.error--text {
  direction: rtl;
}
.upload_file_error {
  font-size: 13px;
}
// .v-application--is-rtl
//   .ql-snow
//   .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
//   svg {
//   right: auto;
//   left: 0;
// }
</style>
