export const recordingDelegationsRoutes = [
  {
    path: "recordingRequest",
    to: "/vendors/recordingRequest",
    meta: {
      title: "recordingRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.recording_request",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/recordingRequest/record"),
    allow_children: false
  },
  {
    path: "recordingRequest/:id/view",
    meta: {
      title: "view_recordingRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.recordingRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/recordingRequest"
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/recordingRequest/view"),
    allow_children: false
  },
  {
    path: "recordingRequest/:id/edit",
    meta: {
      title: "edit_recordingRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.account_request",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.recordingRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors/recordingRequest"
        },
        {
          text: "dashboard.vendors.heading.editDelegation",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/recordingRequest/crud"),
    allow_children: false
  }
];
