// The Observer pattern offers a subscription model in which objects subscribe to an event and get notified when the event occurs. This pattern is the cornerstone of event driven programming, including JavaScript. The Observer pattern facilitates good object-oriented design and promotes loose coupling.

// class Observable {
//     constructor() {
//       this.observers = [];
//     }

//     subscribe(func) {
//       this.observers.push(func);
//     }

//     unsubscribe(func) {
//       this.observers = this.observers.filter(observer => observer !== func);
//     }

//     notify(data) {
//       this.observers.forEach(observer => observer(data));
//     }
//   }

class Observable {
  static instance;
  handlers = {};
  constructor() {
    if (Observable.instance instanceof Observable) {
      return Observable.instance;
    }
    Observable.instance = Object.freeze(this);
  }
  subscribe(fnName, callback) {
    this.handlers[fnName] = callback;
  }
  unsubscribe(fnName) {
    delete this.handlers[fnName];
  }
  fire(fnName, data) {
    if (this.handlers[fnName]) {
      this.handlers[fnName](data);
    }
  }
  isExit(handlerKey) {
    return this.handlers[handlerKey];
  }
}

export const observer = new Observable();
