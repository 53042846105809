export const vendorProtectionSystemsRoutes = [
  {
    path: "approvedDevices",
    to: "/vendors/approvedDevices",
    meta: {
      title: "approvedDevices",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.protection_systems",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.approved_devices",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/approvedDevices/record"),
    allow_children: false
  },
  {
    path: "suspiciousDevices",
    to: "/vendors/suspiciousDevices",
    meta: {
      title: "suspiciousDevices",
      permission: "",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.protection_systems",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.suspicious_devices",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/suspiciousDevices/record"),
    allow_children: false
  }
];
