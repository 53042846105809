<template>
  <ShimmerLoader type="table" :loading="loading">
    <transition name="fade" mode="out-in">
      <v-data-table
        v-if="toggleTable"
        :sort-desc.sync="sortDesc"
        :headers="headers"
        :items="tableItems"
        :loading="loading"
        hide-default-footer
        :items-per-page="per_page"
        :mobile-breakpoint="0"
        :no-data-text="$t('dashboard.public.no_data')"
        :loading-text="$t('dashboard.public.loading')"
        @update:options="handleSort"
        v-bind="$attrs"
        v-on="$listeners"
        calculate-widths
        item-key="id"
        ref="table_ref"
      >
        <!-- :custom-sort="customSort" -->
        <template #item="{ item, index, expand, isExpanded }">
          <tr>
            <slot
              :item="item"
              :index="index"
              :expand="expand"
              :is-expanded="isExpanded"
            ></slot>
          </tr>
        </template>
        <!-- <slot /> -->
        <template slot="no-data">
          {{ $t(`dashboard.public.no_data`) }}
        </template>
        <template #expanded-item="{ item, headers }">
          <slot
            name="expend_row"
            :item="item"
            :headers="headers"
            :color="primary"
          />
        </template>
        <template #foot>
          <!-- <tr class="tfoot"> -->
          <slot name="footer" />
          <!-- </tr> -->
        </template>
      </v-data-table>
    </transition>

    <v-row class="my-3 mb-10" v-if="backendPagination">
      <v-col cols="12" lg="4" class="align-self-center order-lg-2">
        <Pagination :reqName="reqName" />
      </v-col>
      <!-- v-if="pagination.total > 0" -->
      <v-col
        cols="12"
        lg="4"
        sm="8"
        class="align-self-center order-sm-3 pagination-total d-flex"
      >
        <div class="primary--text">
          <span>{{ $t(`dashboard.public.pagination.show`) }}</span>
          {{ pagination.from ? pagination.from : 0 }}
          {{ $t(`dashboard.public.pagination.to`) }}
          {{ pagination.to }}
          {{ $t(`dashboard.public.pagination.of`) }}
          {{ pagination.total }}
          {{ $t(`dashboard.public.pagination.result`) }}
        </div>
      </v-col>
      <!-- v-if="pagination.total > 0" -->
      <v-col
        cols="12"
        lg="4"
        sm="4"
        class="align-self-center per-pages-grid order-lg-1"
      >
        <div class="per-pages">
          <span class="primary--text">{{
            $t(`dashboard.public.pagination.show`)
          }}</span>
          <v-select
            outlined
            dense
            v-model="per_page"
            :items="perPages"
            @change="handleGetPerPage"
            hide-details
          ></v-select>
        </div>
      </v-col>
    </v-row>
  </ShimmerLoader>
</template>

<script>
import { observer } from "@/helpers/observer";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CustomTable",
  inheritAttrs: false,
  props: {
    reqName: {
      type: String,
      default: "",
    },
    tableModule: {
      type: String,
      default: "",
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    itemsData: {
      type: Array,
      default: () => [],
    },
    fromStore: {
      type: Boolean,
      default: true,
    },
    backendPagination: {
      type: Boolean,
      default: true,
    },
    is_customPerPage: {
      type: Boolean,
      default: false,
    },
    custom_per_page: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      perPages: [1, 5, 10, 15, 20],
      headers: [],
      loading: true,
      sortDesc: true,
      toggleTable: true,
      disableSort: false,
      per_page: 10,
    };
  },
  computed: {
    ...mapGetters({
      items: "getsTableItems",
    }),
    tableItems() {
      if (this.fromStore) {
        return this.items;
      } else {
        return this.itemsData;
      }
    },
  },
  mounted() {
    this.$eventBus.$on("on-refresh", () => {
      this.toggleTable = false;
      setTimeout(() => {
        this.toggleTable = true;
      }, 0);
    });
  },
  destroyed() {
    observer.unsubscribe("onPaginate");
    observer.unsubscribe("getTableData");
  },
  created() {
    this.createTableHeaders();
    if (this.reqName) {
      this.getTableData();
      this.$nextTick(() => {
        observer.subscribe("getTableData", this.getTableData);
      });
    }
  },
  methods: {
    ...mapActions({ getTableItems: "getTableItems" }),
    getTableData() {
      this.$nextTick(() => {
        this.getTableItems({ reqName: this.reqName }).then(() => {
          this.loading = false;
        });
      });
    },
    handleSort({ sortBy, sortDesc, ...values }) {
      const query = { ...this.getQuery };
      if (sortBy.length > 0) {
        if (sortDesc[0]) {
          query[`sort`] = `-${sortBy[0]}`;
        } else {
          query[`sort`] = sortBy[0];
        }
        this.$store.dispatch("setQuery", query);
        if (!this.reqName) {
          observer.fire("onPaginate");
        } else {
          observer.fire("getTableData");
        }
      }
    },
    // customSort(items, sortBy, sortDesc) {
    //   const query = { ...this.getQuery };

    //   if (sortBy.length > 0) {
    //     query[`sort[column]`] = sortBy[0];
    //     if (sortDesc[0]) {
    //       query[`sort[dir]`] = "desc";
    //     } else {
    //       query[`sort[dir]`] = "asc";
    //     }
    //   }

    //   this.$store.dispatch("setQuery", query);
    //   return items;
    // },
    generateTableHeaders(headersName = [], tableModule) {
      return headersName.map(({ name, sortable, value, width }) => {
        return {
          text: this.$t(`dashboard.${tableModule}.table.${name}`),
          value: value,
          align: "start",
          sortable: sortable ? sortable : false,
          width: width ? width : "",
        };
      });
    },
    createTableHeaders() {
      this.headers = this.generateTableHeaders(
        this.tableHeaders,
        this.tableModule
      );
    },
    handleGetPerPage() {
      this.$store.dispatch("setQuery", {
        ...this.getQuery,
        per_page: this.per_page,
        page: 1,
      });
      if (this.reqName) {
        this.getTableData();
      } else {
        if (observer.isExit("onPaginate")) {
          observer.fire("onPaginate");
        }
      }
    },
  },
  watch: {
    getQuery: {
      handler(query) {
        if (query && query.per_page) {
          this.per_page = Number(query.per_page);
        } else {
          this.per_page = this.custom_per_page;
        }
      },
      immediate: false,
    },
    reqName: {
      handler(value) {
        if (!value) this.loading = false;
      },
      immediate: true,
    },
    // pagination: {
    //   handler(value) {
    //     if (value && value.per_page) {
    //       // this.per_page = value.per_page;
    //       // this.per_page = this.custom_per_page;
    //     }
    //   },
    //   immediate: true
    // },
    custom_per_page: {
      handler(value) {
        if (this.is_customPerPage) {
          this.per_page = value;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style>
.per-pages .v-input {
  grid-column: span 1;
}
</style>
