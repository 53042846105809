export const followUpRequest = [
  {
    path: "followUpRequest",
    to: "/vendors/followUpRequest",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "followUpRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.followOrderStatus",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/followUpRequest/record"),
    allow_children: false
  },
  {
    path: "/vendors/followUpRequest/acceptedFollowUp",
    meta: {
      title: "followUpRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.followOrderStatus",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/followUpRequest/followUpStatus/accepted"),
    allow_children: false
  },
  {
    path: "/vendors/followUpRequest/rejectedFollowUp",
    meta: {
      title: "followUpRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.followOrderStatus",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/followUpRequest/followUpStatus/rejected"),
    allow_children: false
  },

  {
    path: "/vendors/followUpRequest/reviewingFollowUp",
    meta: {
      title: "followUpRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.followOrderStatus",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/followUpRequest/followUpStatus/reviewing"),
    allow_children: false
  }
];
