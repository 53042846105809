export const messagesTypesRoutes = [
  {
    path: "messagesTypes",
    to: "/support/messagesTypes",
    meta: {
      title: "messagesTypes",
      permission: "message_types_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          exact: true,
          link: true,
          to: "/support"
        },
        {
          text: "dashboard.messagesTypes.heading.messagesTypes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesTypes/record"),
    allow_children: false
  },
  {
    path: "messagesTypes/add",
    meta: {
      title: "add_messagesTypes",
      permission: "message_types_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          disabled: false,
          to: "/support",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.messagesTypes",
          to: "/support/messagesTypes",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.add_messagesTypes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesTypes/crud"),
    allow_children: false
  },
  {
    path: "messagesTypes/:id/edit",
    meta: {
      title: "edit_messagesTypes",
      permission: "message_types_update",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          disabled: false,
          to: "/support",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.messagesTypes",
          to: "/support/messagesTypes",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.edit_messagesTypes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesTypes/crud"),
    allow_children: false
  },
  {
    path: "messagesTypes/:id/view",
    meta: {
      title: "view_messagesTypes",
      permission: "message_types_show",
      bread_crumb: [
        {
          text: "dashboard.sidebar.support",
          disabled: false,
          to: "/support",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.messagesTypes",
          to: "/support/messagesTypes",
          link: true,
          exact: true
        },
        {
          text: "dashboard.messagesTypes.heading.view_messagesTypes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/support/messagesTypes/view"),
    allow_children: false
  }
];
