export default {
  riskVendors: {
    heading: {
      view_transactions_details: "عرض معاملات العميل",
      view_employees_details: "عرض موظفين ذوي خطورة",
      view_details: "عرض التفاصيل",
    },
    labels: {
      facility_name: "اسم المنشأة",
      doc_comm_num: "السجل التجاري / الوثيقة",
      vendor_type: "نوع العميل",
      activity: "النشاط",
      vendor_dangerous: "درجه الخطورة الممنوحة",
      order_code: "كود الطلب",
      admin_name: "اسم المسؤول المعتمد",
      admin_identity_number: "هوية المسؤول",
      risk_ratio: "مؤشر الخطورة",
      total_actual_sales_value: "إجمالي قيمة المبيعات  الفعلية",
      total_expected_sales_value: "إجمالي قيمة المبيعات المتوقعه",
      created_at: "التاريخ والوقت",
      number_of_employees_at_risk: "عدد الموظفين ذوي الخطورة",
      transaction_number: "رقم المعاملة",
      date_from: "الفترة من",
      date_to: "الفترة الي",
      facility_professional_title: "اسم المنشأة/المسمي المهني",
      vendor_name: "اسم العميل",
      vendor_identity: "رقم الهوية",
    },
    placeholder: {
      enter_facility_name: "ادخل المنشأة",
      enter_doc_comm_num: "ادخل السجل التجاري / الوثيقة",
      select_vendor_type: "اختر نوع العميل",
      select_activity: "اختر النشاط",
      select_vendor_dangerous: "اختر درجة الخطورة ",
      transaction_number: "ادخل رقم المعاملة",
      date_from: "ادخل الفترة من",
      date_to: "ادخل الفترة الي",
      enter_facility_professional_title: "ادخل اسم المنشأة/المسمي المهني",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        delete_category:
          "قد يؤدي حذف هذا التصنيف إلي إلغاء التصنيفات التي تم إضافتها من قبلكم",
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
      },
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      add: "إضافة",
      edit: "تعديل",
      close: "غلق",
      change_risk_degree: "إرسال تقرير",
    },
    tooltip: {
      delete: "حذف",
      edit: "تعديل",
      view_details: "عرض",
      view_transactions_details: "عرض المعاملات",
      send_report: "إرسال تقرير",
      change_risk_degree: "تغيير درجة الخطورة",
    },
    table: {
      "#": "#",
      vendor_type: "نوع العميل",
      facility_professional_title: "اسم المنشأة/المسمي المهني",
      vendor_name_auth_name_to: "اسم العميل/ المفوض له",
      facility_name: "اسم المنشأة",
      doc_comm_num: "السجل التجاري / الوثيقة",
      activity: "النشاط",
      risk_ratio: "مؤشر الخطورة",
      given_risk_degree: "درجة الخطورة الممنوحة",
      risk_degree: "درجة الخطورة",
      transaction_number: "رقم المعاملة",
      transaction_amount: "قيمة المعاملة",
      branch: "الفرع",
      seller_name: "اسم البائع",
      seller_identity_number: "هوية البائع",
      seller_app_number: "رقم تطبيق البائع",
      date: "وقت / تاريخ إنشاء المعاملة",
      employee_name: "اسم الموظف",
      identity_number: "الهوية",
      job: "الوظيفة",
      actions: "العمليات",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    chips: {},
    fields: {},
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: " معطل",
      high_danger: "شديد الخطورة",
      mid_danger: "متوسط الخطورة",
      low_danger: "ضعيف الخطورة",
      commercial_record: "سجل تجاري",
      free_work_document: "وثيقة عمل حر",
      profession_practice_document: "وثيقة مزاولة مهنة",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
    },
  },
};
