<template>
  <ValidationProvider
    :name="name"
    ref="fileRef"
    v-bind="$attrs"
    v-on="$listeners"
    v-slot="{ errors }"
    :rules="!imageUrl['0'] ? rules : ''"
  >
    <!-- START:: INPUT FILE HIDDEN -->
    <input
      type="file"
      @change="onFileChange($event, 'target')"
      ref="fileInput"
      hidden
    />
    <!-- END:: INPUT FILE HIDDEN -->

    <div
      @dragover.prevent
      @drop.prevent="onDragLeave"
      :class="{
        'drag-area': drag ? 'dragenter' : 'dragleave',
        'validation-error':
          errors.length > 0 || (serverErrors && serverErrors[$attrs.errorName]),
      }"
    >
      <div @drop="onFileChange($event, 'dataTransfer')">
        <v-btn
          block
          type="button"
          icon
          :ripple="false"
          @click="openFile()"
          class="upload-btn"
        >
          <div
            v-if="image != null"
            class="image_preview"
            :class="{
              'drag-area': drag ? 'dragenter' : 'dragleave',
              'validation-error':
                errors.length > 0 ||
                (serverErrors && serverErrors[$attrs.errorName]),
            }"
          >
            <div class="image">
              <img
                :src="image"
                :alt="uploadText ? uploadText : $t(`dashboard.${$attrs.label}`)"
              />
            </div>
            <div class="edit-icon-btn">
              <v-icon>mdi-square-edit-outline</v-icon>
            </div>
          </div>
          <template v-else>
            <div class="image_add" :class="{ active: isActive }">
              <v-icon color="#45A9D5" large class="ml-3">
                mdi-folder-multiple-image
              </v-icon>
            </div>
          </template>
        </v-btn>
      </div>
    </div>

    <transition name="fade" mode="in-out">
      <v-row class="my-0" style="position: absolute">
        <v-col
          cols="12"
          class="error--text upload_file_error"
          v-if="errors.length > 0"
        >
          {{ $t(`${errors[0]}`) }}
        </v-col>
        <v-col
          cols="12"
          class="error--text upload_file_error"
          v-if="serverErrors"
        >
          {{ serverErrors && serverErrors[$attrs.errorName] }}
        </v-col>
      </v-row>
    </transition>
  </ValidationProvider>
</template>

<script>
export default {
  name: "DragFile",
  inheritAttrs: false,

  props: {
    form: {
      type: Object,
      default: () => {},
    },
    rules: {
      type: [Object, String],
      default: "",
    },
    imageUrl: {
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      image: null,
      drag: false,
      isActive: false,
      uploadText: "",
    };
  },

  methods: {
    openFile() {
      this.$refs.fileInput.click();
    },

    onDragEnter() {
      this.drag = true;
    },
    onDragLeave() {
      this.drag = false;
    },

    async onFileChange(e, fileType) {
      this.form.deleted_image = 0;

      const file = e[fileType]["files"][0];
      const { valid } = await this.$refs.fileRef.validate(file);
      if (valid) {
        this.isActive = true;
        this.uploadText = e.target.files[0].name;
        const fileUrl = URL.createObjectURL(file);
        this.image = fileUrl;
        this.$emit("update:imageUrl", file);
      }
    },
  },

  mounted() {
    this.image = this.imageUrl;
  },
};
</script>

<style lang="scss">
.profile_image {
  .upload-btn {
    border-radius: 0;
    width: 120px;
    height: 120px;

    .v-btn__content {
      width: 100%;
      display: block;
    }
  }
  .image_preview {
    width: 120px;
    height: 120px;
    position: relative;
    .edit-icon-btn {
      position: absolute;
      background-color: #e5eff7;
      border-radius: 4px;
      left: 7px;
      bottom: -2px;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        color: #0065b0;
      }
    }
    img {
      // max-width: 100%;
      // height: 100%;
      object-fit: cover;
      width: 100%;
      object-position: top;
      height: 100%;
    }
  }

  .image_add {
    i {
      margin: 0;
    }
    &.active {
      // width: calc(100% - 70px);
    }
    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .drag-area {
    border: 1px solid #e4e4e4;
    border-radius: 50%;
    position: relative;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    box-shadow: 0px 0px 8px #e4e4e4;
    .image {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .dragenter {
    border-color: #ef1e41;
    .img-preview {
      z-index: -1;
    }
  }

  .drageleave {
    color: #c0c0c0;
    .img-preview {
      z-index: 1;
    }
  }
  .img-preview {
    position: absolute;
    top: 1px;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    right: 1px;
    z-index: 1;
    overflow: hidden;
    border-radius: 10px;
    img {
      display: block;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .image-show {
    z-index: 2;
    left: 3px;
    top: 0;
    height: 100%;
    border-right: 1px solid #9e9e9e;
    position: absolute;
    .v-btn {
      margin-right: 5px;
    }
  }
  .upload_file_error {
    font-size: 13px;
    padding: 5px 20px;
  }
  .validation-error {
    border: 2px solid #ef1e41;
  }
}
</style>
