export const vendorBanksRoutes = [
  {
    path: "banks",
    to: "/vendors/banks",
    meta: {
      title: "banks",
      permission: "banks_index",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.sidebar.banks",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/banks/record"),
    allow_children: false
  },
  {
    path: "banks/add",
    meta: {
      title: "add_bank",
      permission: "banks_store",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },

        {
          text: "dashboard.sidebar.banks",
          exact: true,
          link: true,
          to: "/vendors/banks"
        },
        {
          text: "dashboard.sidebar.add",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/banks/crud"),
    allow_children: false
  }
];
