import request from "@/config/axios";

export function login({ username, password }) {
  const data = {
    username,
    password
  };
  return request({
    url: "login",
    method: "post",
    data
  });
}
export function logout() {
  return request({
    url: "logout",
    method: "post"
  });
}
export function userInfo() {
  return request({
    url: "profile/show",
    method: "get"
  });
}
