import { login, userInfo, logout } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
import Cookies from "js-cookie";
import router from "@/router";
import { $http } from "@/api";

function handlePrograms(programs) {
  return programs.reduce((acc, value) => {
    if (value.sub_programs) {
      acc[value.key] = value.sub_programs.reduce((initial, el) => {
        initial[el.key] = handlePrograms(el.sub_programs);
        return initial;
      }, {});
    }
    return acc;
  }, {});
}

const user = {
  state: {
    token: getToken(),
    language: Cookies.get("language") || "ar",
    user: "",
    permissions: [],
    abilities: [],
    programs: {}
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      setToken(token);
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
    SET_PROGRAMS(state, programs) {
      state.programs = programs;
    },
    SET_ABILITIES(state, abilities) {
      state.abilities = abilities;
    }
  },
  actions: {
    Login({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        login(payload)
          .then((res) => {
            const { data } = res.data;
            const { is_first_login, reset_token } = data;
            if (is_first_login) {
              return router.push(`/reset-password?token=${reset_token}`);
            } else {
              commit("SET_TOKEN", data.token);
              dispatch("GetUserInfo");

              // window.location.reload();
              resolve();
            }

            // const { data, login_code_required, phone } = response.data;
            // if (login_code_required) {
            //   return router.push(
            //     `/verify-code?token=${data["_token"]}&code_type=login_code&phone=${phone}`
            //   );
            // } else {
            //   commit("SET_TOKEN", data.token);
            //   dispatch("GetUserInfo");

            //   // window.location.reload();
            //   resolve();
            // }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    GetUserInfo({ commit, dispatch }) {
      // const data = { permissions: ["*"] };
      // dispatch("setPermissions", data);

      return new Promise((resolve, reject) => {
        userInfo()
          .then((res) => {
            const { data } = res.data;

            commit("SET_USER", data);
            resolve(data);
            dispatch("getPrograms");
            commit("SET_ABILITIES", data["permissions"]);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getPrograms({ commit, dispatch }) {
      const res = await $http.get({
        reqName: `my_programs`
      });
      const { data } = res.data;
      let programs = null;
      if (data.includes("*")) {
        programs = data;
      } else {
        programs = handlePrograms(data);
      }
      commit("SET_PROGRAMS", programs);
      dispatch("setPermissions", data);
    },
    setPermissions({ commit, dispatch }, payload) {
      let permissions = [];
      if (payload.includes("*")) {
        permissions = payload;
      } else {
        permissions = [...payload.map((el) => el.key)];
      }
      commit("SET_PERMISSIONS", permissions);
      dispatch("setAllowedRoutes", permissions);
    },

    async Logout({ commit }) {
      await logout();
      commit("SET_TOKEN", "");
      removeToken();
      Cookies.remove("permissions");
      router.push("/login");
    }
  },
  getters: {
    getPermissions(state) {
      return state.permissions;
    },
    getUser(state) {
      return state.user;
    },
    get_programs(state) {
      return state.programs;
    },
    get_abilities(state) {
      if (state.abilities.includes("*")) {
        return state.abilities;
      }
      return state.abilities.map((per) => per.key);
    }
  }
};

export default user;
