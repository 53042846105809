export default {
  branches: {
    fields: {
      branch_name: "اسم الفرع",
      vendor_name: "اسم العميل",
      status: "الحالة",
      phone: "رقم الجوال",
      email: "البريد الالكتروني",
      branchImage: "صورة الفرع",
      drag_image: "ارفق صورة",
      location: "الموقع",
      address: "العنوان بالتفصيل",
      lat: "خط طول",
      lng: "خط عرض",
    },
    validation: {
      required: "{field} مطلوب",
      required_f: "{field} مطلوبة",
      max: "{field} يجب ان لا يتجاوز {max} حرف ",
      min: "{field} يجب ان لا يقل عن  {min} حرف",
      validLocationInSaudia: "يجب ان يكون الموقع بداخل السعودية",
      // validPhoneNumber: "يرجى ادخال رقم جوال بصيغة صحيحة",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      mimes: "{field} يجب ان تكون  {mimes}",
      email: "{field} غير صحيح رجاء التأكد من الكتابة بطريقة صحيحة",
    },
    heading: {
      branches: "سجل الفروع",
      add_branch: "إضافة فرع",
      edit_branch: "تعديل فرع",
      view_branch: "عرض فرع",
      branch_details: "بيانات الفرع",
      location: "إحداثيات الموقع",
    },
    popup: {
      buttons: {
        save: "حفظ",
        cancel: "إلغاء",
        refuse: "غير موافق",
        accept: "موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        branch_has_employees: "لا يمكن حذف فرع له موظفين",
        branch_has_transactions: "لا يمكن حذف فرع له معاملات",
        branch_has_all: "لا يمكن حذف فرع له مظفين ومعاملات",
      },
    },
    table: {
      "#": "#",
      branch_name: "اسم الفرع",
      vendor_name: "اسم العميل",
      facility_name: "المنشاة/المسمى المهنى",
      status: "الحالة",
      phone: "رقم الجوال",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      actions: "العمليات",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      add_branch: "إضافة فرع",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
    },
    labels: {
      branch_name: "اسم الفرع",
      vendor_name: "اسم العميل",
      status: "الحالة",
      phone: "رقم الجوال",
      email: "البريد الالكتروني",
      branchImage: "صورة الفرع",
      drag_image: "ارفق صورة",
      location: "الموقع",
      address: "العنوان بالتفصيل",
      lat: "خط طول",
      lng: "خط عرض",
      employees_count: "عدد الموظفين",
      sellers_count: "عدد البائعين",
      branch_manager: "مدير الفرع",
      region: "المنطقة",
      city: "المدينة",
      district: "الحي",
      date: "تاريخ انشاء الفرع",
      street: "الشارع",
      facility_name: "المنشاة/المسمى المهنى",
    },
    placeholder: {
      enter_name: "ادخل الاسم",
      enter_branch_name: "ادخل اسم الفرع",
      select_vendor: "اختر العميل",
      enter_phone: "ادخل رقم الجوال (5xxxxxxxx)",
      select_status: "اختر الحالة",
      enter_email: "ادخل البريد الالكتروني",
      enter_address: "ادخل العنوان",
      enter_facility_name: "أدخل المنشأة/المسمي المهني",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      deactive: "معطل",
    },
    chips: {
      active: "مفعل",
      deactive: "معطل",
    },
  },
};
