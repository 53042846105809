export const SystemAdministratorApprovalRoutes = [
  {
    path: "SystemAdministratorApproval",
    to: "/vendors/SystemAdministratorApproval",
    meta: {
      title: "SystemAdministratorApprovalRecord",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.System_administrator_approval_record",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/vendors/SystemAdministratorApproval/record"),
    allow_children: false
  },
  {
    path: "SystemAdministratorApproval/:id/view",
    meta: {
      title: "view_SystemAdministratorApproval",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.changeCustomerData",
          disabled: false,
          to: "/vendors",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.cards.System_administrator_approval",
          to: "/vendors/SystemAdministratorApproval",
          link: true,
          exact: true
        },
        {
          text: "dashboard.vendors.heading.view",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/SystemAdministratorApproval/view"),
    allow_children: false
  }
];
