export default {
  paymentServices: {
    heading: {
      add_percentage: "اضافة نسبة ",
      edit_percentage: "تعديل نسبة",
    },
    fields: {},
    labels: {
      payment_type: "خدمة الدفع",
      percentage: "النسبة",
      min_amount: "الحد الأدني للعملية",
      max_amount: "الحد الأقصي للعملية",
      payment_name: "اسم خدمةالدفع",
      payment_gate_fees: "رسوم بوابة الدفع",
      period_from: "الفترة من",
      period_to: "الفترة إلي",
    },
    placeholder: {
      choose_payment_type: "اختر خدمة الدفع",
      add_percentage: "أضف النسبة",
      add_amount: "ادخل القيمة",
      choose_payment_name: "اختر اسم خدمةالدفع",
      enter_payment_gate_fees: "أدخل رسوم بوابة الدفع",
      enter_payment_name: "أدخل اسم خدمة الدفع",
      select_period_from: "اختر الفترة من",
      select_period_to: "اختر الفترة إلي",
    },

    validation: {
      required: " هذا الحقل مطلوب",
      max: "{field}   لا يجب ان يزيد عن {max}",
      min: "{field}   يجب ان يكون علي الاقل {min} من الحروف",
      min_value: "{field} يجب ان لا يقل عن  {min_value}",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        delete: "هل تريد إتمام عملية الحذف النهائي ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        disableJobTitle: "لا يمكن تعطيل وظيفة مرتبطة بموظفين",
      },
    },
    table: {
      "#": "#",
      payment_name: "اسم خدمةالدفع",
      percentage: "النسبة",
      min_amount: "الحد الأدني",
      max_amount: "الحد الأقصي",
      created_at: "التاريخ",
      actions: "العمليات",
      payment_gate_fees: "رسوم بوابة الدفع",
    },
    tooltip: {
      edit: "تعديل",
      view_details: "عرض",
      delete: "حذف",
    },
    buttons: {
      export: "تصدير",
      add_jobs: "إضافة وظيفة",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      add: "إضافة",
    },

    chips: {
      high_danger: "مرتفع الخطورة",
      mid_danger: "متوسط الخطورة",
      low_danger: "ضعيف الخطورة",
      active: "مفعل",
      in_active: "معطل",
    },
  },
};
