import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "@/lang";
Vue.use(VueGoogleMaps, {
  load: {
    language: "ar",
    key: "AIzaSyCOKqDO2ceTdJHjIDRuoOm1piPatGMuRzk",
    libraries: ["places"]
  }
});
