import axios from "axios";
import store from "@/store";
import { baseURL } from "@/config/baseUrl";
import { getToken, removeToken } from "@/utils/auth";
import router from "../router";
// create an axios instance
const service = axios.create({
  timeout: 60000, // request timeout
  baseURL: baseURL,
});

// request interceptor
service.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    if (store.getters.token) {
      config.headers["Authorization"] = `${"Bearer " + getToken()}`;
    }
    config.headers["Accept-Language"] = "ar";

    return config;
  },
  (error) => {
    // Do something with request error

    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    // global success response message
    store.dispatch("ClearServerErrors");
    let { method } = response.config;
    if (method === "post" || method === "put" || method === "delete") {
      if (response.data.message) {
        store.dispatch("ShowNotification", {
          text: response.data.message,
          color: "success",
        });
      }
    }
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
      removeToken();
      return router.push("/");
    }
    if (error.response.status === 422) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
      //  removeToken();
      //  return router.push("/");
    }
    if (error.response.status === 403) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
      return router.push("/no-permissions");
    }
    // if (error.response.status === 404) {
    //   return router.push("/not-found");
    // }
    // global error response message
    if (!getToken()) {
      store.dispatch("ShowNotification", {
        text: error.response.data.message,
        color: "red",
      });
    }
    const { errors } = error.response.data;
    store.dispatch("SetServerErrors", errors);
    return Promise.reject(error);
  }
);

export default service;
