import { paymentServicesRoutes } from "./paymentServices";
import { dataRecordingRoutes } from "./dataRecording";
import { paymentServicesRecordRoutes } from "./paymentServiceRecord";
import { ReceivablesTransferSettingsForTheIntermediaryBankRoutes } from "./ReceivablesTransferSettingsForTheIntermediaryBank";

export const commissionsTaxesRoutes = [
  {
    path: "commissionsTaxes",
    to: "/settings/commissionsTaxes",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "commissionsTaxes",
      bread_crumb: [
        {
          text: "dashboard.sidebar.settings",
          exact: true,
          link: true,
          to: "/settings"
        },
        {
          text: "dashboard.settings.cards.commissionsTaxes",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/settings/commissionsTaxes/parent"),
    allow_children: true,
    children: [
      {
        path: "/settings/commissionsTaxes",
        meta: {
          title: "commissionsTaxes",
          bread_crumb: [
            {
              text: "dashboard.sidebar.settings",
              exact: true,
              link: true,
              to: "/settings"
            },
            {
              text: "dashboard.settings.cards.commission_taxes_setting",
              disabled: true
            }
          ]
        },
        component: () => import("@/views/settings/commissionsTaxes")
      },
      ...paymentServicesRoutes,
      ...paymentServicesRecordRoutes,
      ...dataRecordingRoutes,
      ...ReceivablesTransferSettingsForTheIntermediaryBankRoutes
    ]
  }
];
