import { asyncRoutes } from "@/router/asyncRoutes";

export default {
  state: () => ({
    routes: []
  }),
  mutations: {
    SET_ROUTES(state, payload) {
      state.routes = payload;
    }
  },
  actions: {
    setAllowedRoutes({ commit }, permissions = ["*"]) {
      if (permissions.includes("*")) {
        commit("SET_ROUTES", asyncRoutes);
      } else {
        const allowedPermissions = new Set();
        asyncRoutes.forEach((route) => {
          if (
            route.meta &&
            permissions &&
            permissions.includes(route.meta.permission)
          ) {
            allowedPermissions.add(route);
          } else {
            return (
              route.children &&
              route.children.forEach((child, index) => {
                if (child.meta && child.meta.permission) {
                  if (permissions.includes(child.meta.permission)) {
                    allowedPermissions.add(route);
                  } else {
                    child.allowed = false;
                  }
                }
              })
            );
          }
        });

        commit("SET_ROUTES", allowedPermissions);
      }
    }
  },
  getters: {
    getRoutes(state) {
      return state.routes;
    }
  }
};
