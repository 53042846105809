<template>
  <lottie
    :options="defaultOptions"
    :height="CustomOptions.initHeight"
    :width="CustomOptions.initWidth"
    class="pb-5"
  />
</template>

<script>
import lottie from "vue-lottie/src/lottie.vue";
export default {
  name: "LottieAnimation",
  components: {
    lottie,
  },
  props: {
    CustomOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    defaultOptions: {
      get() {
        if (this.CustomOptions && this.CustomOptions.image) {
          return {
            animationData: require(`@/assets/loti/${this.CustomOptions.image}`),
          };
        } else {
          return { animationData: require("@/assets/loti/confirm.json") };
        }
      },
    },
  },
};
</script>
