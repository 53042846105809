export default {
  settlementsDurationRecord: {
    heading: {
      settlementsDurationRecord: "إعدادات المدة الزمنية للتسوية المالية",
      settlements_duration_edit: "تعديل مدة زمنية للتسوية",
      settlements_duration_view: "عرض",
      historical_movment: "الحركه التاريخية",
      settlements_duration_add: "إضافة مدة زمنية للتسوية"
    },
    labels: {
      duration: "المدة الزمنية كل",
      period: "الفترة الزمنية",
      date: "التاريخ",
      time: "المدة الزمنية",
      corresponding_day: "الموافق يوم",
      day: "يوم",
      month: "شهر",
      hour: "ساعة"
    },
    placeholder: {
      enter_duration: "أدخل المدة الزمنية",
      select_period: "اختر الفترة الزمنية",
      enter_corresponding_day: "أدخل الموافق يوم"
    },
    table: {
      "#": "#",
      date: "التاريخ",
      duration: "المدة الزمنية",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "وظيفة الموظف",
      activity_date: "تاريخ النشاط",
      activity_details: "تفاصيل النشاط",
      no_data: "لا توجد نتائج متاحة",
      activity_name: "النشاط",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      add: "إضافة مدة زمنية للتسوية"
    },
    tooltip: {
      view_details: "عرض",
      edit: "تعديل",
      delete: "حذف"
    },
    chips: {},
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟"
      }
    },

    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      in_active: "معطل",
      day: "يوم",
      month: "شهر",
      hour: "ساعه"
    }
  }
};
