import { departmentsRoutes } from "./departments";
import { jobsRoutes } from "./jobs";
import { employeesRoutes } from "./employees";
export const employeesManagementRoutes = [
  {
    path: "/employeesManagement",
    to: "/employeesManagement",
    icon: "mdi-account-tie",
    meta: {
      title: "employeesManagement",
      permission: "employee_management",
      bread_crumb: [
        {
          text: "dashboard.sidebar.employeesManagement",
          disabled: false
        }
      ]
    },
    component: () => import("@/views/employeesManagement/parent.vue"),
    allow_children: false,
    children: [
      {
        path: "/",
        meta: {
          title: "employeesManagement",
          permission: "employee_management",
          is_parent: true,
          bread_crumb: [
            {
              text: "dashboard.sidebar.employeesManagement",
              link: true,
              exact: true,
              to: "/employeesManagement"
            }
          ]
        },
        component: () => import("@/views/employeesManagement")
      },
      ...departmentsRoutes,
      ...jobsRoutes,
      ...employeesRoutes
    ]
  }
];
