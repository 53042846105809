export default {
  sidebar: {
    risk_vendors: "عملاء المخاطر",
    home: "الرئيسية",
    settings: "الإعدادات",
    statistics: "الإحصائيات",
    vendors: "إدارة العملاء",
    blackListVendors: "القائمة السوداء | سجل العملاء",
    transactions: "إدارة المعاملات والتسويات",
    complete_transactions: "عمليات مكتملة التسوية",
    view_complete_transactions: "عرض عملية مكتملة التسوية",
    ready_transactions: "عمليات جاهزة",
    unready_transactions: "عمليات غير جاهزة",
    failed_transactions: "معاملات غير مكتملة",
    failed_settlements: "تسويات فاشلة",
    transferred_transactions: "معاملات تم تحويلها للعميل",
    exceed_transactions: "معاملات تعدت الحد الاقصي",
    unpaid_pills: "فواتير لم تسدد",
    "settlements-time-record": "اعدادات المدة الزمنية للتسوية المالية",
    consumers_record: "سجل بيانات المستهلكين",
    profile: "الحساب الشخصي",
    employeesRecord: "سجل الموظفين",
    show_employeesRecord: "عرض سجل الموظف",
    sadad_record: "سجل اعدادات خدمة سداد",
    password_settings_payment: "اعدادات طلب كلمة السر لعملية الدفع",
    exceed_percentage_record: "سجل نسب الزيادة",
    subscription_fees_record: "سجل بيانات رسوم الإشتراك",
    vendor_transactions_record: "طلبات عرض معاملات العميل المرسلة",
    active_transactions: "معاملات مفعلة ولم يم تحويلها للعميل",
    inActive_transactions: "معاملات لم يتم تفعيلها",
    expired_pills: "فواتير لم تدفع منتهية",
    login: "تسجيل دخول",
    logout: "تسجيل خروج",
    areas: "إدارة الإحصاء والتتبع",
    marketing: "التسويق",
    departments: "الأقسام",
    add_department: "إضافة قسم",
    edit_department: "تعديل قسم",
    view_department: "عرض قسم",
    transactions_client_areas: "مناطق تواجد العملاء والمعاملات",
    view_clients_and_location: "خريطة عرض العملاء والمعاملات",
    registerVendor: "تسجيل عميل جديد",
    followUpRequest: "متابعة حالة الطلب",
    documentTypes: "سجل مهن الوثيقه",
    vipVendors: "إعدادات تصنيف كبار العملاء",
    authorizations: "سجل التفويضات",
    revisionRequest: "طلبات قيد المراجعة",
    refusedRequest: "طلبات مرفوضة / ملغاه للتكرار",
    pendingRequest: "طلبات في انتظار التفويض",
    view_pendingRequest: "عرض طلبات في انتظار التفويض ",
    recordingRequest: "تفويضات بنموذج",
    view_recordingRequest: "عرض تفويضات بنموذج",
    edit_recordingRequest: "تعديل تفويضات بنموذج",
    electronicDelegations: "تفويضات إلكترونية",
    electronicDelegations_view: "عرض",
    canceledDelegationsRequest: "طلبات ملغاه لتكرارها",
    canceledDelegationsRequest_view: "عرض",
    cancelledRequest: "طلبات ملغاه",
    commercialRecord: "سجل تجاري",
    Document: "وثيقة",
    approvedDevices: "الأجهزة المعتمدة",
    suspiciousDevices: "الأجهزة المشتبه بها",
    inprogress_transactions: "عمليات جاري تسوياتها",
    client_location: "مناطق تواجد العملاء",
    edit_commercial_record: "تعديل",
    banks: "البنوك",
    view_commercial_record: "عرض",
    edit_document: "تعديل",
    view_document: "عرض",
    editRevisionRequest: "عرض",
    add_bank: "اضافة بنك",
    edit_bank: "تعديل بنك",
    jobs: "الوظائف",
    add_job: "إضافة وظيفة",
    edit_job: "تعديل وظيفة",
    view_job: "عرض وظيفة",
    employeesManagement: "إدارة شئون الموظفين",
    follow_up: "إدارة المتابعة",
    blackList: "القائمة السوداء",
    reset_password: "اعادة تعين كلمة السر",
    clients: "عملاء",
    employees: "سجل الموظفين",
    add_employee: "إضافة موظف",
    edit_employee: "تعديل موظف",
    view_employee: "عرض موظف",
    bannedHiring: "ممنوعين من التوظيف",
    add_banned_hiring: "إضافة",
    suspects_list: "سجل قوائم المشتبهه بهم",
    add_suspects_list: "إضافة",
    staticPages: "الصفحات الثابتة",
    add_staticPages: "إضافة صفحة",
    edit_staticPages: "تعديل صفحة",
    view_staticPages: "عرض صفحة",
    countries: "الدول",
    add_countries: "إضافة دولة",
    edit_countries: "تعديل الدولة",
    links: "الروابط",
    translations: "الترجمات",
    commissionsTaxes: "العمولات والضرائب",
    add_exceptionalPedigreeRecord: "إضافه نسبه",
    edit_exceptionalPedigreeRecord: "تعديل نسبه",
    exceptionalPedigreeRecord: "سجل النسب الاستثنائية",
    support: "إدارة الدعم الفني",
    messagesTypes: "انواع الرسائل",
    document_types: "سجل مهن الوثيقه",
    edit_documentTypes: "تعديل الوثيقة",
    view_documentTypes: "عرض   الوثيقة",
    add_messagesTypes: "إضافة نوع الرسالة",
    edit_messagesTypes: "تعديل نوع الرسالة",
    view_messagesTypes: "عرض نوع الرسالة",
    messagesBox: "صندوق الرسائل",
    view_messagesBox: "عرض صندوق الرسائل",
    vendors_record: "سجل العملاء",
    financialSettlementTransferSettings: "اعدادات تحويل التسويات المالية",
    view_vendorsRecord: "عرض عميل",
    reply_messagesBox: "الرد علي الرسالة",
    legalAccreditation: "سجل الاعتماد القانونيه",
    moneyLaunderingApproval: "اعتماد إدارة المخاطر",
    moneyLaunderingApproval_record: "سجل اعتماد إدارة المخاطر",
    SystemAdministratorApproval: "اعتماد مدير النظام",
    SystemAdministratorApprovalRecord: "سجل اعتماد مدير النظام",
    approvedRequests: "طلبات معتمدة/ مرفوضة",
    delegatedRequests: "طلبات مرفوضه",
    view_legalAccreditation: "عرض الاعتماد القانونيه",
    view_moneyLaunderingApproval: "عرض اعتماد إدارة المخاطر",
    view_SystemAdministratorApproval: "عرض اعتماد مدير النظام",
    view_approvedRequests: "عرض طلبات معتمده",
    view_delegatedRequests: "عرض طلبات مرفوضه",
    update_bank_account_status: "مراجعة الحساب البنكى",
    edit_vendor: "تعديل عميل",
    branches: "سجل الفروع",
    add_branch: "إضافة فرع",
    edit_branch: "تعديل فرع",
    view_branch: "عرض فرع",
    authorizeReport: "تقرير التفويض",
    view_departments: "عرض قسم",
    verify_code: "إعادة تعين كلمة المرور",
    send_code: "إرسال كود التحقق",
    view_authorizations: "عرض التفويض",
    add_area: "إضافة منطقة جديدة",
    edit_area: "تعديل منطقة ",
    view_area: "عرض منطقة ",
    Page404: "صفحة غير موجودة",
    money_laundering: "مكافحة غسل الأموال( تمويل الإرهاب)",
    severity_settings: "إعدادات درجات الخطورة",
    severity_settings_add: "إضافة",
    severity_settings_edit: "تعديل",
    suspicious_indicator_settings: "اعدادات مؤشرات الاشتباه",
    suspicious_indicator_settings_add: "إضافة مؤشر",
    suspicious_indicator_settings_edit: "تعديل مؤشر",
    suspicious_indicator_settings_view: "عرض مؤشر",
    Projected_sales_activity_annually: "إعدادات المبيعات المتوقعة للنشاط سنويا",
    Projected_sales_activity_annually_add: "إضافة",
    Projected_sales_activity_annually_edit: "تعديل",
    severity_sttings_nationalities: "إعدادات درجات الخطورة للجنسيات",
    severity_sttings_nationalities_add: "إضافة درجة خطورة للجنسيات",
    severity_sttings_nationalities_edit: "تعديل درجة خطورة للجنسيات",
    severity_settings_areas: "إعدادات درجات الخطورة للمناطق",
    severity_settings_areas_add: "إضافة درجة خطورة لمنطقة",
    severity_settings_areas_edit: "تعديل درجة خطورة لمنطقة",
    dailyTransactions: "المعاملات اليومية للعملاء",
    money_laundering_head: "إدارة المخاطر",
    paymentServicesRecord: "سجل خدمات الدفع",
    serviceFeeSettings: "اعدادات رسوم خدمات",
    paymentServicesRecord_add: "سجل خدمات الدفع | اضافة",
    paymentServicesRecord_edit: "سجل خدمات الدفع | تعديل",
    setTheValueAddedPercentage: "اعدادت نسبة القيمة المضافة",
    intermediaryBankReceivablesSettings: "اعدادات مستحقات البنك الوسيط",
    intermediaryBankReceivablesSettings_add:
      "اعدادات مستحقات البنك الوسيط | اضافة",
    intermediaryBankReceivablesSettings_edit:
      "اعدادات مستحقات البنك الوسيط | تعديل",

    ReceivablesTransferSettingsForTheIntermediaryBank:
      "اعدادات مستحقات تحويل للبنك الوسيط",
    ReceivablesTransferSettingsForTheIntermediaryBank_add:
      "اعدادات مستحقات تحويل للبنك الوسيط | اضافة",
    ReceivablesTransferSettingsForTheIntermediaryBank_edit:
      "اعدادات مستحقات تحويل للبنك الوسيط | تعديل",
    paymentServices: "اعدادت نسبة خدمات الدفع",
    reports: {
      exceedTransactions: "طلبات مبيعات تعدت الحد الأقصي للنشاط سنويا",
      suspectedTransactions: "معاملات بها مؤشر اشتباه",
      suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
      suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
      exceedTransactions_view: "معاملات تعدت الحد الأقصي | عرض",
      suspectedTransactions_view: "معاملات بها مؤشر اشتباه | عرض",
      suspectedAccountsRequest_view: "طلبات فتح حساب مشتبه بها | عرض",
      suspectedEmployeesRequest_view: "طلبات لموظفين مشتبه بهم | عرض",
    },
    fixedRatioSettings: "إعدادات النسب الثابتة",
    dailyTransactions_view: "عرض  معاملات عميل ",
    dailyTransactions_view_details: "عرض",
    dailyTransactions_askview: "طلب عرض  معاملات عميل",
    dangerous_vendors: "موظفين ذوي خطورة",
    exceedTransactions_record: "معاملات تعدت الحد الأقصي",
    view_exceed_transactions: "عرض",
    add_exceed_transactions: "إضافة تقرير",
    suspectedTransactions_record: "معاملات بها مؤشر اشتباه",
    view_suspected_transactions: "عرض",
    add_suspected_transactions: "إضافة تقرير",
    suspectedAccountsRequest: "طلبات فتح حساب مشتبه بها",
    view_suspectedAccountsRequest: "عرض",
    add_suspectedAccountsRequest: "إضافة تقرير",
    suspectedEmployeesRequest: "طلبات لموظفين مشتبه بهم",
    view_suspectedEmployeesRequest: "عرض",
    add_suspectedEmployeesRequest: "إضافة تقرير",
    data_recording: "تسجيل البيانات",
    deductions: "المستقطعات",
    Jack_balance_dues: "مستحقات رصيد جاك",
    Jack_balance_fee: "رسوم رصيد جاك",
    added_tax: "الضريبة المضافة",
    Payment_services_dues: "مستحقات خدمات الدفع",
    Intermediary_bank_receivables_record: "سجل مستحقات البنك الوسيط",
    financialSettlementRecord: "سجل التسويات المالية",
    add_settlement_time: "إضافة مدة زمنية للتسوية",
    view_financialSettlement: "عرض التسوية المالية",
    serviceFeesRecord: "سجل بيانات رسوم خدمات",
    settlements_duration_edit: "تعديل مدة زمنية للتسوية",
    settlements_duration_add: "إضافة مدة زمنية للتسوية",
    settlements_duration_view: "عرض",
    successful_transactions_view: "عرض معاملة ناجحة",
    failedTransactions: "معاملات غير مكتملة",
    successfulTransactions: "معاملات ناجحة",
    successful_transactions_edit: "تعديل",
    failed_transactions_view: "عرض",
    rasid_jack_programs: "برنامج رصيد جاك",
    add_rasid_jack_program: "اضافة برنامج",
    edit_rasid_jack_program: "تعديل برنامج",
    view_rasid_jack_program: "عرض برنامج",
    exceed_fixed_percentage_record:
      "إعدادات نسب الزيادات الثابتة عن الحد الأقصي",
    exceed_fixed_percentage_add: "إضافة نسبة",
    exceed_fixed_percentage_edit: "تعديل نسبة",
    exceed_fixed_percentage_view: "عرض نسبة",
    exceed_exceptional_percentage_record:
      "إعدادات نسب الزيادات الإستثنائية عن الحد الأقصي",
    exceed_exceptional_percentage_add: "إضافة نسبة",
    exceed_exceptional_percentage_edit: "تعديل نسبة",
    exceed_exceptional_percentage_view: "عرض نسبة",
    view_sadadService: "عرض",
    edit_sadadService: "تعديل",
    add_sadadService: "إضافة",
    view_password_settings_payment: "عرض",
    edit_password_settings_payment: "تعديل",
    add_password_settings_payment: "إضافة",
    rasid_jack_vendors_programs: "برامج عملاء رصيد جاك",
    add_rasid_jack_vendors_program: "إضافة",
    edit_rasid_jack_vendors_program: "تعديل",
    view_rasid_jack_vendors_program: "عرض",
    severity_settings_nationalities: "إعدادات درجات الخطورة للجنسيات",
    severity_settings_nationalities_add: "إضافة درجة خطورة للجنسيات",
    severity_settings_nationalities_edit: "تعديل درجة خطورة للجنسيات",
    financialSettlement_view: "عرض",
    severity_settings_nationalities_view: "عرض",
    severity_settings_view: "عرض",
    severity_settings_areas_view: "عرض",
    Projected_sales_activity_annually_view: "عرض",
    risk_ratio_settings: "اعدادات مؤشر المخاطر",
    viewRevisionRequest: "عرض",
    activitySeveritySettings: "اعدادات درجة الخطورة للنشاط",
    add_activitySeveritySettings: "إضافة",
    edit_activitySeveritySettings: "تعديل",
    view_activitySeveritySettings: "عرض",
    activities_record: "النشاطات",
    activities_view: "عرض",
    activities_add: "إضافة",
    activities_edit: "تعديل",
    payment_gate_settings: "إعدادات نسب / رسوم خدمات الدفع",
    add_payment_gate_settings: "إضافة",
    edit_payment_gate_settings: "تعديل",
    add: "إضافة",
    noPermissions: "لا توجد لديك صلاحيه لدخول هذه الصفحه",
  },
};
