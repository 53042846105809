export const suspectedAccountsRequestRoutes = [
  {
    path: "danger-transactions/suspectedAccountsRequest",
    to: "/money-laundering/danger-transactions/suspectedAccountsRequest",
    meta: {
      title: "suspectedAccountsRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.suspectedAccountsRequest",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedAccountsRequest/record"
      ),
    allow_children: false
  },
  {
    path: "danger-transactions/suspectedAccountsRequest/:id/view",
    meta: {
      title: "view_suspectedAccountsRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/danger-transactions/suspectedAccountsRequest"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.view_suspectedAccountsRequest",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedAccountsRequest/view"
      ),
    allow_children: false
  },

  {
    path: "danger-transactions/suspectedAccountsRequest/:id/add",
    meta: {
      title: "add_suspectedAccountsRequest",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/danger-transactions/suspectedAccountsRequest"
        },
        {
          text: "dashboard.moneyLaundering.suspectedAccountsRequest.heading.add_suspectedAccountsRequest",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedAccountsRequest/crud"
      ),
    allow_children: false
  }
];
