export default {
  consumersData: {
    heading: {
      consumers_record: "سجل بيانات المستهلكين"
    },
    labels: {
      phone: "رقم الجوال"
    },
    placeholder: {
      enter_phone: "ادخل رقم الجوال (5xxxxxxxx)"
    },
    table: {
      "#": "#",
      pill_number: "رقم الفاتورة",
      payment_card: "رقم بطاقة الدفع",
      consumer_name: "اسم المستهلك",
      phone: "رقم الجوال",
      email: "البريد الإلكتروني",
      pill_value: "قيمة الفاتورة",
      message_status: "حالة الإرسال",
      creating_date: "تاريخ ووقت إنشاء المعاملة",
      actions: "العمليات",
      no_data: "لا توجد نتائج متاحة",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل"
    },
    tooltip: {
      view_details: "عرض",
      confirm: "تأكيد",
      refuse: "رفض",
      sendAgain: "إعادة ارسال"
    },
    chips: {
      send_done: "تم الإرسال",
      send_fail: "فشل الإرسال"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        confirmedVendorContent1: "هل متأكد من عرض معاملات العميل",
        refusedVendorContent1: "هل متأكد من رفض معاملات العميل",
        vendorContentBody: "لإدارة المخاطر؟",
        sucessSend: "تم الارسال بنجاح",
        sendReceiptTitle: "هل متأكد من إرسال الإيصال تلقائي؟"
      }
    },

    statusOptions: {
      all: "الجميع",
      confirmed: "مقبول",
      refused: "مرفوض"
    }
  }
};
