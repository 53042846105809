export const reportsSuspectedTransactionsRoutes = [
  {
    path: "reports/suspected-transactions",
    to: "/money-laundering/reports/suspected-transactions",
    meta: {
      title: "reports.suspectedTransactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.title",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.suspectedTransactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/suspectedTransactions/record"),
    allow_children: false
  },
  {
    path: "reports/suspected-transactions/:id/view",
    meta: {
      title: "reports.suspectedTransactions_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.title",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.reports.heading.suspectedTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/reports/suspected-transactions"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/suspectedTransactions/view"),
    allow_children: false
  }
];
