<template>
  <v-hover v-slot="{ hover }">
    <v-card
      min-height="100px"
      outlined
      :to="$attrs.to"
      class="text-center pa-sm-4 pa-2 mx-auto mx-sm-0 d-flex justify-space-between align-center card-home"
      :color="hover ? 'primary' : ''"
    >
      <!-- <div>
      </div> -->
      <v-btn
        v-if="$attrs.CardIcon && !image"
        fab
        :color="hover ? 'white' : 'grey lighten-4'"
      >
        <v-icon color="primary">
          {{ $attrs.CardIcon }}
        </v-icon>
      </v-btn>

      <v-card-title
        class="justify-center pa-0"
        :class="hover ? 'white--text' : 'black--text'"
      >
        <ShimmerLoader
          v-if="loading"
          type="heading"
          :loading="loading"
          style="width: 100px"
        >
        </ShimmerLoader>
        <template v-else>
          {{ $attrs.CardCount }}
        </template>

        <v-card-subtitle>{{
          $attrs.CardTitle ? $t(`dashboard.${$attrs.CardTitle}`) : ""
        }}</v-card-subtitle>
      </v-card-title>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "CardCategory",
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    image: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px;
  transition: background 0.2s ease-in-out;
}

.v-card__title {
  word-break: normal;
  font-size: 16px;
}
.v-btn {
  border-radius: 15px 0 15px 0;
}
</style>
