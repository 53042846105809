export default {
  vendorTransactions: {
    heading: {
      vendorTransactionsRecord: "سجل طلبات عرض معاملات عميل",
    },
    labels: {
      commercial_record: "السجل التجاري / رقم الوثيقة",
      status: "الحالة",
      vendor_dangerous: "درجه الخطورة",
      replay: "رد",
      admin_replay: "رأي مضيف التقرير",
      request_status: "حالة الطلب",
      report_status: "حالة التقرير",
    },
    placeholder: {
      enter_commercial_record: "ادخل السجل التجاري أو رقم الوثيقة",
      select_status: "اختر الحالة",
      select_request_status: "اختر حالة الطلب",
    },
    table: {
      "#": "#",
      facility_name: "اسم المنشأه",
      commercial_record: "السجل التجاري/الوثيقة",
      responsible_name: "اسم المسؤل المعتمد",
      responsible_identity: "هوية المسؤل المعتمد",
      request_reason: "سبب ارسال الطلب",
      request_sender: "مرسل الطلب",
      reason: "سبب",
      status: "الحالة",
      creating_date: "تاريخ ووقت ارسال الطلب",
      order_date: "تاريخ ووقت ارسال الطلب",
      actions: "العمليات",
      no_data: "لا توجد نتائج متاحة",
      report_host_opinion: "رأي مضيف التقرير",
      vendor_name: "اسم العميل",
      vendor_identity: "رقم الهوية",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
      request_status: "حالة الطلب",
      report_status: "حالة التقرير",
    },
    buttons: {
      export: "تصدير",
      save: "حفظ",
      back: "عودة",
      edit: "تعديل",
      accept_report: "قبول التقرير",
      refuse_report: "رفض التقرير",
    },
    tooltip: {
      view_details: "عرض",
      confirm: "تأكيد",
      refuse: "رفض",
      confirmed_req: "قبول الطلب",
      refused_req: "رفض الطلب",
      accept_report: "اعتماد التقرير",
      show_report: "عرض التقرير",
    },
    chips: {
      confirmed: "مقبول",
      confirmed_req: "مقبول",
      refused_req: "مرفوض",
      refused: "مرفوض",
      pending: "انتظار",
      no_report: "لا يوجد تقرير",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
        confirmedVendorContent1: "هل متأكد من عرض معاملات العميل",
        refusedVendorContent1: "هل متأكد من رفض معاملات العميل",
        vendorContentBody: "لإدارة المخاطر؟",
      },
    },

    statusOptions: {
      all: "الجميع",
      confirmed: "مقبول",
      refused: "مرفوض",
      pending: "انتظار",
    },
  },
};
