export const risk_ratio_settings_routes = [
  {
    path: "risk_ratio_settings",
    to: "/money-laundering/risk_ratio_settings",
    meta: {
      title: "risk_ratio_settings",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        // {
        //   text:
        //     "dashboard.moneyLaundering.severitySettingsAreas.heading.moneyLaunderingSetting"
        //   ,
        //   disabled: false,
        //   exact: true,
        //   link: true,
        //   to: "/money-laundering"
        // },
        {
          text: "dashboard.moneyLaundering.severitySettingsAreas.heading.ratio_settings",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/moneyLaundering/risk_ratio_settings"),
    allow_children: false
  }
];
