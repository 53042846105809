import Cookies from "js-cookie";
import { $http } from "@/api";

import i18n from "@/lang";
const locale = {
  state: {
    locale: Cookies.get("language") || "ar",
    trans: {}
  },
  mutations: {
    SET_TRANS(state, payload) {
      state.trans = payload;
    },
    SET_LOCALE(state, payload) {
      state.locale = payload;
    },
    RESET_LOCALE(state) {
      state.locale = Cookies.get("language") || "ar";
    }
  },
  actions: {
    setTrans({ commit }) {
      $http.get({ reqName: "get_all_trans" }).then((res) => {
        const { data } = res;
        // i18n.setLocaleMessage("ar", data);
        commit("SET_TRANS", data);
      });
    }
  }
  // getters: {
  //   getTrans: (state) => (transKey) => {
  //     return state.trans[transKey];
  //   }
  // }
};

export default locale;
