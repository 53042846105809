export const suspectedTransactionsRoutes = [
  {
    path: "danger-transactions/suspected-transactions",
    to: "/money-laundering/danger-transactions/suspected-transactions",
    meta: {
      title: "suspectedTransactions_record",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.suspectedTransactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedTransactions/record"
      ),
    allow_children: false
  },
  {
    path: "danger-transactions/suspected-transactions/:id/view",
    meta: {
      title: "view_suspected_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.suspectedTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/danger-transactions/suspected-transactions"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.view_suspected_transactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedTransactions/view"
      ),
    allow_children: false
  },

  {
    path: "danger-transactions/suspected-transactions/:id/add",
    meta: {
      title: "add_suspected_transactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.dangerTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.suspectedTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/danger-transactions/suspected-transactions"
        },
        {
          text: "dashboard.moneyLaundering.suspectedTransactions.heading.add_suspected_transactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import(
        "@/views/moneyLaundering/dangerTransactions/suspectedTransactions/crud"
      ),
    allow_children: false
  }
];
