import { vendorsBlacklistRoutes } from "./vendors";
import { suspectsListRoutes } from "./suspectsList";

export const blackListRoutes = [
  {
    path: "blackList",
    to: "/vendors/blackList",
    icon: "mdi-swap-horizontal",
    meta: {
      title: "blackList",
      bread_crumb: [
        {
          text: "dashboard.sidebar.vendors",
          exact: true,
          link: true,
          to: "/vendors"
        },
        {
          text: "dashboard.vendors.cards.blackList",
          disabled: true
        }
      ]
    },
    component: () => import("@/views/vendors/blackList/parent"),
    allow_children: true,
    children: [
      {
        path: "/vendors/blackList",
        meta: {
          title: "blackList",
          is_parent: true,

          bread_crumb: [
            {
              text: "dashboard.sidebar.vendors",
              exact: true,
              link: true,
              to: "/vendors"
            },
            {
              text: "dashboard.vendors.cards.blackList",
              disabled: true
            }
          ]
        },
        component: () => import("@/views/vendors/blackList")
      },
      ...vendorsBlacklistRoutes,
      ...suspectsListRoutes
    ]
  }
];
