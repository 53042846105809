export default {
  employees: {
    heading: {
      employees: "سجل الموظفين",
      add_employee: "إضافة موظف",
      edit_employee: "تعديل موظف",
      view_employee: "عرض موظف",
      historical_movement: "الحركة التاريخية",
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل",
    },
    buttons: {
      add_employee: "إضافة موظف",
      cancel: "إلغاء",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      search: "بحث",
      reset: "عرض الكل",
      edit: "تعديل",
    },
    placeholder: {
      enter_identity_num: "أدخل رقم الهوية",
      enter_phone_num: "أدخل رقم الجوال (5xxxxxxxx)",
      enter_first_name: "الأول",
      enter_second_name: "الثانى",
      enter_third_name: "الثالث",
      enter_fourth_name: "الرابع",
      select_job: "اختر الوظيفة",
      select_department: "اختر القسم",
      select_status: "اختر حالة",
      select_date: "يوم/شهر/سنة",
      select_nationality: "اختر الجنسية",
      select_type: "اختر النوع",
      select_permissions: "اختر الصلاحيات",
    },
    labels: {
      employee_name: "اسم الموظف",
      add_new_employee: "إضافة موظف جديد",
      identity_num: "رقم الهوية",
      phone: "الجوال",
      phone_num: "رقم الجوال",
      status: "الحالة",
      employeesImage: "صورة الموظف",
      drag_image: "اسحب أو ارفع صورة الموظف",
      nationality: "الجنسية",
      birthdate: "تاريخ الميلاد",
      type: "النوع",
      permissions: "الصلاحيات",
      department: "القسم",
      job: "الوظيفة",
      department_name: "اسم القسم",
      job_name: "اسم الوظيفة",
    },
    chips: {
      active: "مفعل",
      deactive: "معطل",
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق",
        approval: "اعتماد",
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف النهائي ؟",
      },
    },
    fields: {
      first_name: "الاسم الأول",
      second_name: "الاسم الثانى",
      third_name: "الاسم الثالث",
      fourth_name: "الاسم الرابع",
      identity_num: "رقم الهوية",
      phone: "الجوال",
      status: "الحالة",
      employeesImage: "صورة الموظف",
      nationality: "الجنسية",
      birthdate: "تاريخ الميلاد",
      type: "النوع",
      password: "كلمة المرور",
      confirm_password: "تأكيد كلمة المرور",
      permissions: "الصلاحيات",
      department: "القسم",
      job: "الوظيفة",
    },
    validation: {
      required: "{field} مطلوب",
      max: "{field}   لا يجب ان يزيد عن {max}",
      min: "{field}   يجب ان يكون علي الاقل {min} من الحروف",
      validPhoneNumber: "يجب ان يكون رقم الجوال 9 ارقام فقط ويجب ان يبدأ بـ 5",
      mimes: "{field} يجب ان تكون  {mimes}",
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعل",
      deactive: "معطل",
      accept_employee: "مقبول",
      reject_employee: "مرفوض",
      male: "ذكر",
      female: "أنثي",
    },
    table: {
      "#": "#",
      employee_name: "اسم الموظف",
      job: "الوظيفة",
      category: "القسم",
      create_date: "تاريخ الانشاء",
      identity_num: "رقم الهوية",
      status: "الحالة",
      department: "القسم",
      added_by: "تم بواسطة",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      actions: "العمليات",
      no_data: "لا توجد نتائج متاحة",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي",
      },
    },
  },
};
