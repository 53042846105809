export default {
  messagesTypes: {
    heading: {
      messagesTypes: "انواع الرسائل",
      add_messagesTypes: "إضافة نوع الرسالة",
      edit_messagesTypes: "تعديل نوع الرسالة",
      view_messagesTypes: "عرض نوع الرسالة",
      historical_movement: "الحركة التاريخية"
    },
    tooltip: {
      delete: "حذف",
      view_details: "عرض",
      edit: "تعديل"
    },
    buttons: {
      add_messagesTypes: "إضافة نوع رسالة",
      cancel: "إلغاء",
      save: "حفظ",
      back: "عودة",
      export: "تصدير",
      search: "بحث",
      reset: "عرض الكل",
      edit: "تعديل"
    },
    placeholder: {
      enter_message_type: "ادخل نوع الرسالة",
      select_message_type: "اختر نوع الرسالة",
      select_message_status: "اختر حالة الرسالة",
      select_date: "يوم/شهر/سنة",
      select_employee: "اختر الموظف المسئول",
      choose_status: "اختر الحالة",
      choose_message_types: "اختر نوع الرسالة"
    },
    labels: {
      message_type: "نوع الرسالة",
      message_status: "حالة الرسالة",
      message_date_from: "تاريخ الرسالة من",
      message_date_to: "تاريخ الرسالة إلي",
      employee: "الموظف المسئول",
      employee_name: "اسم الموظف المسئول",
      status: "الحالة",
      message_types: "نوع الرسالة"
    },
    chips: {
      active: "مفعلة",
      deactive: "معطلة",
      in_active: "معطلة"
    },
    popup: {
      buttons: {
        accept: "موافق",
        refuse: "غير موافق"
      },
      body: {
        confirm: "هل تريد إتمام عملية الحفظ ؟",
        confirmSubmit: "هل تريد إتمام عملية الحفظ ؟",
        back: "هل تريد العودة دون الحفظ ؟",
        deleteTitle: "هل تريد إتمام عملية الحذف؟",
        NodeleteTitle: "لا يمكن حذف نوع رسالة مرتبطة برسائل"
      }
    },
    fields: {
      message_type: "نوع الرسالة",
      employee: "الموظف المسئول"
    },
    validation: {
      required: "{field} مطلوب",
      max: "{field} هذا الحقل لا يجب ان يزيد عن {max}",
      min: "{field} هذا الحقل يجيب ان يكون علي الاقل {min} من الحروف"
    },
    statusOptions: {
      all: "الجميع",
      active: "مفعلة",
      deactive: "معطلة"
    },
    table: {
      "#": "#",
      message_type: "نوع الرسالة",
      message_date: "تاريخ الرسالة",
      message_status: "حالة الرسالة",
      message_employees: "عدد الموظفين المسئولين",
      created_at: "تاريخ الإنشاء",
      actions: "العمليات",
      added_by: "تم بواسطة",
      department: "القسم",
      activity_date: "تاريخ النشاط",
      activity_name: "النشاط",
      total: "الإجمالي",
      no_data: "لا توجد نتائج متاحة",
      employee_job: "وظيفة الموظف",
      pagination: {
        show: "عرض",
        to: "إلى",
        of: "من",
        result: "الإجمالي"
      }
    }
  }
};
