<template>
  <v-menu
    v-model="openMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        readonly
        outlined
        v-model="form[fieldName]"
        append-icon="mdi-calendar-month"
        v-bind="[attrs, $attrs]"
        v-on="on"
      ></v-text-field>
    </template>
    <!--for meladi date-->
    <v-date-picker
      v-model="form[fieldName]"
      @input="handleMenu()"
      v-on="$listeners"
      :locale="$i18n.locale"
      :min="$attrs.min ? $attrs.min : '1900-01-01'"
      :max="$attrs.max ? $attrs.max : '2100-01-01'"
      show-adjacent-months
      :allowed-dates="allowedDates"
      :first-day-of-week="6"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  inheritAttrs: false,
  data() {
    return {
      openMenu: false,
      show: true
    };
  },

  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    name: {
      type: String,
      default: null
    },
    fieldName: {
      type: String,
      default: ""
    },
    form: {
      type: Object,
      default: () => {}
    },
    allowedDates: {
      type: Function,
      default: (date) => {
        return date;
      }
    }
  },
  methods: {
    handleMenu() {
      if (this.$attrs.validateData) {
        this.$emit("handleValidate");
        this.openMenu = false;
      } else {
        this.openMenu = false;
      }
    },
    formatDay(day) {
      return this.$moment(day).locale(this.$i18n.locale).format("D");
    },
    formatYear(year) {
      return this.$moment(year).locale(this.$i18n.locale).format("YYYY");
    },
    headerDateFormat(year) {
      // return this.$moment(year).locale(this.$i18n.locale).format("YYYY");
    }
  }
};
</script>
