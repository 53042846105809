export const reportsExceedTransactionsRoutes = [
  {
    path: "reports/exceed-transactions",
    to: "/money-laundering/reports/exceed-transactions",
    meta: {
      title: "reports.exceedTransactions",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.cards.danger_suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.reports.cards.exceedTransactions",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/exceedTransactions/record"),
    allow_children: false
  },
  {
    path: "reports/exceed-transactions/:id/view",
    meta: {
      title: "reports.exceedTransactions_view",
      bread_crumb: [
        {
          text: "dashboard.sidebar.money_laundering",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },
        {
          text: "dashboard.moneyLaundering.cards.danger_suspectedAccountsRequest",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering"
        },

        {
          text: "dashboard.moneyLaundering.reports.cards.exceedTransactions",
          disabled: false,
          exact: true,
          link: true,
          to: "/money-laundering/reports/exceed-transactions"
        },
        {
          text: "dashboard.moneyLaundering.reports.heading.view",
          disabled: true
        }
      ]
    },
    component: () =>
      import("@/views/moneyLaundering/reports/exceedTransactions/view"),
    allow_children: false
  }
];
